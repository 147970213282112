/**
 *
 */
const style = () => ({
    create: {
        padding: ({ screenSize }) => screenSize.mobile && '0 10px',
        maxWidth: '900px',
        margin: '30px auto 0 auto',
        '& .payment-methods--content': {
            padding: '0 42px',
            marginTop: '30px'
        }
    }
})

export default style
