import React from 'react'
import { makeStyles } from '@material-ui/core'
import StepIcon from './StepIcon'
import StepText from './StepText'

const jss = () => ({
    step: {
        cursor: 'pointer',
        '&.step--left': {
            display: 'inline-flex',
            alignItems: 'center',
            '& .step--text': {
                marginLeft: '8px'
            }
        },
        '&.step--bottom': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            '& .step--text': {
                marginTop: '6px',
                display: 'block',
                whiteSpace: 'nowrap'
            }
        }
    }
})

const useStyles = makeStyles(jss)

type PropsInterface = {
    text?: string | number;
    title?: string;
    subtitle?: string
    completed?: boolean;
    error?: boolean;
    value?: string | number;
    active?: boolean;
    align?: 'left' | 'bottom';
    onClick?(v): any;
    className?: string;
    'data-testid'?: string;
}

const stringifyStatus = (completed, error) => {
    if (completed) return 'completed'
    if (error) return 'error'
    return 'neutral'
}

/**
 *
 */
const Step = ({
    text = '#',
    title,
    subtitle,
    completed,
    error,
    active,
    align = 'bottom',
    onClick,
    className,
    value,
    'data-testid': dataTestId
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    return <div className={`${styles.step} step--${align} ${className || ''}`} onClick={() => onClick && onClick(value)} data-testid={dataTestId}>
        <StepIcon
            status={stringifyStatus(completed, error)}
            active={active}
            text={text}
            data-testid={`${dataTestId}-icon`}
        />
        <StepText
            title={title}
            subtitle={subtitle}
            active={active}
            className='step--text'
            status={stringifyStatus(completed, error) }
            data-testid={`${dataTestId}-text`}
        />
    </div>
}

/**
 *
 */
export type StepType = PropsInterface

export default Step
