import React, { useState, useRef, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PDCMarkdown from 'pdc-markdown'
import { escapeRegExp } from './../../../utils/utils'
import { TextField, Typography, Icon } from 'foundations-library/components'
import { App } from '../../../utils/objects'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles(theme => ({
    root: {
        rowGap: '30px'
    },
    searchContainer: {
        margin: 'auto'
    },
    item: {
        padding: '15px 0px',
        alignItems: 'flex-start',
        gap: '10px',
        alignSelf: 'stretch',
        boxShadow: '0px -1px 0px 0px #F2F1F1 inset',
        '& .question-container': {
            cursor: 'pointer',
            '& .question': { flexBasis: '90%' }
        },
        '& .answer-container': {
            maxHeight: 0,
            overflow: 'hidden',
            transition: 'max-height 0.2s ease-out'
        }
    }
}))

const items = [
    {
        question: 'What is an eSIM?',
        answer: 'An eSIM (Embedded SIM) is a digital SIM card that is built into your device, allowing you to activate a cellular plan without the need for a physical SIM card.'
    },
    {
        question: 'How does an eSIM work?',
        answer: 'An eSIM stores your carrier’s information digitally, making it easier to switch between networks or add new plans without the need to insert or remove a physical SIM card. You can activate it via a QR code or an activation code.'
    },
    {
        question: 'Do I need a physical SIM card to use an eSIM?',
        answer: 'No. An eSIM eliminates the need for a physical SIM card. Your device will have a built-in eSIM chip to store your mobile network details.'
    },
    {
        question: 'Which devices support Phone.com\'s cellular service?',
        answer: 'Most newer smartphones, tablets, and smartwatches support Phone.com\'s cellular service.Popular devices include iPhone(XS and newer), Google Pixel(3 and newer), and Samsung Galaxy devices(S20 and newer).Be sure to check with your device\'s manufacturer for compatibility.'
    },
    {
        question: 'How do I activate my cellular service?',
        answer: 'To activate your eSIM, you will need to scan a QR code or input an activation code.'
    },
    {
        question: 'Can I use the cellular service internationally?',
        answer: 'Yes, you can use the cellular service internationally, but there are restrictions. International dialing must also be enabled on your Phone.com account. Please check our <a href=\'https://support.phone.com/how-do-i-enable-international-calling-on-my-account\'>International support page</a> for more information.'
    },
    {
        question: 'Can I have multiple eSIMs on my device?',
        answer: 'Yes, most devices support multiple eSIM profiles. This means you can have more than one eSIM stored on your device, such as one for personal use and one for work. Always double-check with your carrier to ensure your device supports multiple eSIMs.'
    },
    {
        question: 'How do I switch between Phone.com\'s cellular service and my primary carrier?',
        answer: 'You can switch between Phone.com\'s cellular service and your primary carrier from your dialer page or messaging app.'
    },
    {
        question: 'What happens if I lose my device?',
        answer: 'If you lose your device, you can deactivate the cellular service through your Phone.com account. Keep in mind that deleting the cellular service is permanent, and any message history will be lost.'
    },
    {
        question: 'Is the cellular service secure?',
        answer: 'Yes, the cellular service technology is secure. It uses encryption and secure storage to protect your data. However, it’s important to use passwords, lock screens, and other security features to protect your device from unauthorized access.'
    },
    {
        question: 'Can I still use my old physical SIM card if I switch to eSIM?',
        answer: 'Yes, many devices allow you to use both an eSIM and a physical SIM card at the same time (dual SIM functionality).'
    },
    {
        question: 'Will the cellular service work with any carrier?',
        answer: 'The cellular service is supported by many global carriers, but it\'s important to verify that your carrier supports eSIM technology before attempting to activate it.'
    },
    {
        question: 'Can I transfer my cellular service to a new device?',
        answer: 'No, Phone.com\'s cellular service does not support transferring an eSIM to a new device.You will need to terminate the original eSIM and activate a new one.'
    },
    {
        question: 'Why don\'t I see a phone number on the list of options for an eSIM?',
        answer: 'If you don’t see a phone number listed as an option for your eSIM, check the number assignment. The number must be configured with inbound text messaging, call routing, and voicemail assigned to the user.'
    },
    {
        question: 'Can I update my phone number later on?',
        answer: 'Yes! You can log in to your My.Phone.com account and update the number assigned to your eSIM.'
    },
    {
        question: 'How do I access voicemail?',
        answer: '<ul>\n<li>Call your Phone.com number from your Phone.com eSIM.</li>\n<li>Dial *8686 from your Phone.com eSIM.</li>\n<li>Check voicemail through your Phone.com account (voicemails are stored for 30 days).</li>\n<li>On iPhone, go to the Voicemail tab in the Phone app.</li>\n</ul>'
    },
    {
        question: 'How can I distinguish between my primary phone number\'s call logs and my Phone.com call logs?',
        answer: '<p><strong>Android:</strong> In the Phone app\'s Recents tab, you’ll see a small icon differentiating native calls from Phone.com eSIM calls.</p>\n<p><strong>iPhone:</strong> eSIM calls will have a small icon on the left side of the call log.</p> '
    },
    {
        question: 'Can I turn off my eSIM?',
        answer: '<p>Yes, you can toggle your eSIM on or off using your device’s settings.</p>\n  <p><strong>iPhone:</strong> Go to Settings > Cellular > Select your eSIM > Toggle Turn This Line On/Off.</p>\n  <p><strong>Android:</strong> Go to Settings > Connections > SIM manager > Toggle eSIM on/off.</p>'
    },
    {
        question: 'I\'m trying to scan the QR code, but it isn’t working.',
        answer: 'Try refreshing the QR code using the refresh option below it. If the QR code has been used before, it will no longer work.'
    },
    {
        question: 'Is WiFi Calling supported with the eSIM?',
        answer: '<p><strong>iPhone:</strong> Yes, WiFi Calling is supported.</p>\n  <p><strong>Android:</strong> No, WiFi Calling is not currently supported with the Phone.com eSIM.</p>'
    },
    {
        question: 'Is 5G supported on the eSIM?',
        answer: '<p><strong>iPhone:</strong> Yes, you can switch between LTE and 5G in Settings.</p>\n  <p><strong>Android:</strong> No, Android eSIM currently supports LTE only.</p>'
    }
]

const Question = ({ questionItem, classes, openedQuestion, setOpenedQuestion }: { questionItem: { question: string, answer: string }, classes: ClassNameMap, openedQuestion: string, setOpenedQuestion: React.Dispatch<React.SetStateAction<string>> }) => {
    const [open, setOpen] = useState(false)
    const answerRef = useRef<HTMLDivElement>(null)

    const handleToggleCollapse = (e: React.SyntheticEvent) => {
        toggleCollapse()
        setOpenedQuestion(!open ? questionItem.question : '')
    }

    const toggleCollapse = () => {
        setOpen(open => !open)
        performCollapseTransition()
    }

    const performCollapseTransition = () => {
        if (answerRef.current) {
            if (open) answerRef.current.style.maxHeight = '0'
            else answerRef.current.style.maxHeight = `${answerRef.current.scrollHeight}px`
        }
    }

    useEffect(() => {
        if (open && openedQuestion && openedQuestion !== questionItem.question) {
            setOpen(false)
            performCollapseTransition()
        }
    }, [openedQuestion])

    return (
        <Grid container xs={12} direction='column' className={classes.item}>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' wrap='nowrap' className='question-container' onClick={handleToggleCollapse}>
                <Typography variant='h7' className='question'>{questionItem.question}</Typography>
                <Icon name={!open ? 'menu-expanded' : 'menu-expanded-alt'} size='medium' color='neutral' />
            </Grid>
            <Grid container className='answer-container' ref={answerRef}>
                <PDCMarkdown>
                    {questionItem.answer}
                </PDCMarkdown>
            </Grid>
        </Grid>
    )
}

/**
 *
 */
const FAQTab = ({ app }: { app: App }) => {
    const classes = useStyles()
    const [openedQuestion, setOpenedQuestion] = useState('')
    const [FAQs, setFAQs] = useState(app.details.faq || items)

    const handleSearch = (searchText) => {
        const filteredFAQs = (app.details.faq || items).filter(FAQ =>
            searchText.trim() === '' || new RegExp(escapeRegExp(searchText), 'i').test(FAQ.question)
        )
        setFAQs(filteredFAQs)
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={8} lg={6} className={classes.searchContainer}>
                <TextField leadingIcon='search' placeholder='Type your question here...' className='search-input' onChange={handleSearch} />
            </Grid>
            <Grid container xs={12}>
                {
                    FAQs.map((questionItem) => {
                        return <Question key={questionItem.question} {...{ questionItem, classes, openedQuestion, setOpenedQuestion }} />
                    })
                }
            </Grid>
        </Grid>
    )
}

export default FAQTab
