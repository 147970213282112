import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import jss from './style'
import Step, { StepType } from './Step'
import { ScreenSizeContext } from 'providers'
import Button from '../Button/Button'

const useStyles = makeStyles(jss)

interface PropsInterface {
    items: Omit<StepType, 'active' | 'align' | 'onClick' | 'className'>[];
    variant?: 'horizontal'/* | 'vertical' */
    align?: 'left' | 'bottom';
    disabled?: boolean;
    onChange?(v): any;
    value?: string | number;
    style?: React.CSSProperties;
    width?: string | number;
    position?: 'center' | 'left' | 'right';
    narrow?: boolean;
    'data-testid'?: string;
}

const getInlineStyle = (variant, style: React.CSSProperties = {}) => {
    if (variant === 'horizontal') {
        return {
            width: style.width
        }
    }
    return {}
}

const getStepDataTestId = (dataTestId, i) => `${dataTestId}-step-${i}`

const renderRegularSteps = (items, activeIndex, onClick, align, testId) => items.map((x, i) => {
    const stepTestId = getStepDataTestId(testId, i)
    const els = [<Step
        {...x}
        text={i + 1}
        active={i === activeIndex}
        onClick={() => onClick(x.value, i)}
        align={align}
        className='stepper--step'
        key={stepTestId}
        data-testid={stepTestId}
    />]
    if (i < items.length - 1) {
        els.push(<div key={`stepper-step-line-${i}`} className='stepper-step--line' />)
    }

    return els
})

const renderDots = (items, activeIndex, onClick, disabled, className, dataTestId) => {
    let els: JSX.Element[] = []
    const hasMultipleElements = items.length > 1
    if (hasMultipleElements) {
        els.push(
            <Button
                variant='text'
                color='primary'
                icon='back-small'
                disabled={disabled || activeIndex === 0}
                onClick={() => onClick(items[activeIndex - 1].value, activeIndex - 1)}
                data-testid={`${dataTestId}-back-button`}
            >Back</Button>
        )
    }
    els = els.concat(
        items.map((x, i) => {
            const classes = ['stepper--step-dot', activeIndex === i ? 'stepper-dot--active' : ''].join(' ')
            const testId = getStepDataTestId(dataTestId, i)
            return <div
                className={classes}
                onClick={() => onClick(x.value, i)}
                key={testId}
                data-testid={testId}
            />
        })
    )
    const nextBtnIsDisabled = disabled || activeIndex === items.length - 1 || !items[activeIndex].completed
    if (hasMultipleElements) {
        els.push(
            <Button
                variant='text'
                color='primary'
                icon='forward-small'
                disabled={nextBtnIsDisabled}
                onClick={() => onClick(items[activeIndex + 1].value, activeIndex + 1)}
                data-testid={`${dataTestId}-next-button`}
            >Next</Button>)
    }
    return <div className='stepper--dots-wrapper'>
        {els}
    </div>
}

/**
 *
 */
const Stepper = ({
    items,
    variant = 'horizontal',
    align = 'bottom',
    disabled = false,
    onChange,
    value,
    style = {},
    width,
    narrow = false,
    position = 'center',
    'data-testid': dataTestId = 'stepper'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const screenSize = useContext(ScreenSizeContext)
    const handleOnClick = (v, i) => {
        !disabled && value !== v && onChange && onChange(v || i)
    }

    const activeItemIndex = useMemo(() => {
        if (value) {
            const index = items.findIndex((x) => Object.prototype.hasOwnProperty.call(x, 'value') && x.value === value)
            if (index > -1) return index
        }
        handleOnClick(items[0].value, 0)
        return 0
    }, [value, items])

    const inlineStyle = getInlineStyle(variant, { width })
    const renderAsDots = screenSize.mobile
    const classes = [styles.stepper, `stepper--${variant}`, `stepper--${align}`, narrow && 'stepper--narrow'].filter((x) => x).join(' ')
    const wrapperClasses = [styles.stepperWrapper, `stepper-wrapper--${position}`].filter((x) => x).join(' ')
    return <div className={wrapperClasses} data-testid={`${dataTestId}-wrapper`}>
        <div className={classes} style={{ ...inlineStyle, ...style }} data-testid={dataTestId}>
            {
                renderAsDots &&
                renderDots(
                    items,
                    activeItemIndex,
                    handleOnClick,
                    disabled,
                    styles.stepperDot,
                    dataTestId
                )
            }
            {
                !renderAsDots &&
                renderRegularSteps(
                    items,
                    activeItemIndex,
                    handleOnClick,
                    align,
                    dataTestId
                )
            }
        </div>
    </div>
}

export default Stepper
