import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'foundations-library/components'
import { getVerbalColors } from 'helpers'
import { getFirstLetters } from './../../utils'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            width: ({ letters }: { letters: string }) => `${20 * letters.length}px`,
            height: ({ letters }: { letters: string }) => `${20 * letters.length}px`,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: verbalColors['neutral-500']
        },
        letterTypography: {
            color: '#fff',
            lineHeight: 0
        }
    }
})

/**
 *
 */
const Avatar = ({ text }: { text: string }) => {
    const letters = getFirstLetters(text)
    const classes = useStyles({ letters })

    return (
        <div className={classes.root}>
            <Typography variant='avatarSmall' className={classes.letterTypography}>
                {letters}
            </Typography>
        </div>
    )
}

export default Avatar
