import { ProUser, PlusBasicUser, manageProUserTableDataAttr, managePlusBasicUserTableDataAttr, proUsers, plusBasicUsers } from './objects'

/**
 *
 */
export const arraysEqual = (arr1: number[], arr2: number[]) => {
    if (arr1.length !== arr2.length) {
        return false
    }

    const sortedArr1 = [...arr1].sort((a, b) => a - b)
    const sortedArr2 = [...arr2].sort((a, b) => a - b)

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false
        }
    }

    return true
}

/**
 *
 */
export const signInToZoho = (app: Record<string, any>) => {
    const link = app.url
    window.open(link, '_blank')
}

/**
 *
 */
export const makePromise = <T, >(toResolve: T) => new Promise((res) => res(toResolve))

/**
 *
 */
export const getFilteredUsers = (users: (ProUser|PlusBasicUser)[]) => {
    const proUsers = users.filter(user => user.plan.toLowerCase() === 'pro')
    const plusBasicUsers = users.filter(user => ['plus', 'basic'].includes(user.plan.toLowerCase()))
    return [proUsers, plusBasicUsers]
}

/**
 *
 */
export const getLastTableRow = (testId: string) => document.querySelector(`[data-test-id=${testId}] tr:last-child`)

/**
 *
 */
export const getTableTestId = (order: 'asc'|'desc', loadedUsers: Record<string, any>[]) => {
    let testId: string = managePlusBasicUserTableDataAttr
    const [proUsers, plusBasicUsers] = getFilteredUsers(loadedUsers)

    switch (true) {
            case Boolean(order === 'desc' && !plusBasicUsers.length):
                testId = manageProUserTableDataAttr
                break
            case Boolean(order === 'desc' && plusBasicUsers.length):
                testId = managePlusBasicUserTableDataAttr
                break
            case Boolean(order === 'asc' && !proUsers.length):
                testId = managePlusBasicUserTableDataAttr
                break
            case Boolean(order === 'asc' && proUsers.length):
                testId = manageProUserTableDataAttr
                break
            default:
                break
    }

    return testId
}

/**
 *
 */
export const makeUser = (currentUser: Record<string, any>) => {
    return {
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        plan: currentUser.plan,
        integrationActivated: currentUser.integrationActivated
    }
}

/**
 *
 */
export const hasUpgradeRequestSent = (user: Record<string, any>) => user?.upgradeRequestSent || false

/**
 *
 */
export const getPlusBasicUserIds = (users: Record<string, any>[]) => users.filter(user => ['plus', 'basic'].includes(user.plan.toLowerCase())).map(user => user.id)

/**
 *
 */
export const filteredPlusBasicUserIds = (users: Record<string, any>[], selectedUsers: number[]) => {
    const plusUserIds = users.filter(user => {
        if (user.plan.toLowerCase() === 'plus' && selectedUsers.includes(user.id)) return true
        return false
    }).map(user => user.id)

    const basicUserIds = users.filter(user => {
        if (user.plan.toLowerCase() === 'basic' && selectedUsers.includes(user.id)) return true
        return false
    }).map(user => user.id)

    return [plusUserIds, basicUserIds]
}

/**
 *
 */
export const shuffleArray = (arr: Record<string, any>[]) => {
    const shuffledArray: [number, Record<string, any>][] = []

    const generateRandomIndex = (arrLength: number) => {
        let toMultiply = '1'
        for (let i = 0; i < String(arrLength).length; i++) {
            toMultiply += '0'
        }
        let newIndex: number
        while (true) {
            const index = Math.floor(Math.random() * Number(toMultiply))
            if (index > arrLength) continue
            if (ifIndexExistsInNewArray(index)) continue
            newIndex = index
            break
        }
        return newIndex
    }

    const ifIndexExistsInNewArray = (index: number) => {
        const doesIndexExist = shuffledArray.some(shuffledArr => shuffledArr[0] === index)
        return doesIndexExist
    }

    arr.forEach(() => {
        const randomIndex = generateRandomIndex(arr.length - 1)
        shuffledArray.push([randomIndex, arr[randomIndex]])
    })

    const newArray = shuffledArray.map(shuffledArr => shuffledArr[1])
    return newArray
}

/**
 *
 */
export const getRandomUsers = () => shuffleArray(proUsers.concat(plusBasicUsers))

/**
 *
 */
export const getUsers = (offset: number, orderBy: string) => {
    if (offset !== 0 || /\.asc$/.test(orderBy)) return plusBasicUsers
    return proUsers
}

/**
 *
 */
export const getEmailCaption = (caption: string, firstName: string) => caption.replace('{user_name}', firstName)
