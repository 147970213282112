import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { processLink } from './../../../utils/utils'
import { items, Articles } from './howTo/articles/Index'
import { App } from './../../../utils/objects'

const useStyles = makeStyles(theme => ({
    articlesRoot: {
        rowGap: '.5rem'
    }
}))

/**
 *
 */
const HowToArticles = ({ app }: { app: App }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Grid container xs={12} direction='column' className={classes.articlesRoot}>
            <Typography variant='h6'>Top How-to-articles</Typography>
            <Articles articles={items.articles.slice(0, 3)} />
            <Button color='secondary' iconLeft={false} icon='go' variant='text' onClick={() => processLink('/how-to-articles', history, true)}>View all how-to-articles</Button>
        </Grid>
    )
}

export default HowToArticles
