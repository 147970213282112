/* eslint-disable @typescript-eslint/naming-convention, react/prop-types */
import React, { useState, useContext, useEffect, useRef } from 'react'
import TableMui from 'pdc-table-mui'
import Typography from 'typography'
import Box from '@material-ui/core/Box'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { useStyles } from './styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ZohoUserContext, ZohoUsersContext } from 'providers'
import gtmDataPush from 'gtm-events'
import { email_administrator, plus_users_upgrade, basic_users_upgrade, cancel_btn_pressed_activate_modal, cancel_btn_pressed_user_modal } from './utils/GTMEvents'
import { sendEmailIcon } from './icons/inlineIcons'
import { texts as textObj, manageUserTablesHead, manageProUserTableDataAttr, managePlusBasicUserTableDataAttr, proUserTableDataAttr, plusBasicUserTableDataAttr, UserModalInterface, ProUser, PlusBasicUser, Users, ModelType, TableType, plusBasicUserTablesHead } from './utils/objects'
import { StatusProvider, SelectUsersToUpgradeContext } from './utils/context'
import TableClass from './utils/table'
import { DialogActionButtons, Error, UpgradeUsersToProDialog, SkeletonLoader, DialogActionButtonsSkeleton } from './utils/components'
import Spinner from 'spinner'
import { signInToZoho, makePromise, getFilteredUsers, getLastTableRow, getTableTestId, makeUser, hasUpgradeRequestSent, filteredPlusBasicUserIds } from './utils/helpers'

const getTableObj = (users: Users, modalType: ModelType, usersCountObj: { proUsersCount: number, plusBasicUsersCount: number }) => {
    let proUsersTable = new TableClass<ProUser, TableType>([], 'proUser', usersCountObj.proUsersCount)
    let plusBasicUsersTable = new TableClass<PlusBasicUser, TableType>([], 'plusBasicUser', usersCountObj.plusBasicUsersCount)

    switch (true) {
            case modalType === 'manageUser': {
                proUsersTable = new TableClass<ProUser, TableType>(users.pro, 'manageProUser', usersCountObj.proUsersCount)
                plusBasicUsersTable = new TableClass<PlusBasicUser, TableType>(users.plusBasic, 'managePlusBasicUser', usersCountObj.plusBasicUsersCount)
                break
            }
            case modalType === 'proUser': {
                proUsersTable = new TableClass<ProUser, TableType>(users.pro, 'proUser', usersCountObj.proUsersCount)
                break
            }
            case modalType === 'plusBasicUser': {
                plusBasicUsersTable = new TableClass<PlusBasicUser, TableType>(users.plusBasic, 'plusBasicUser', usersCountObj.plusBasicUsersCount)
                break
            }
            default:
                break
    }

    return [proUsersTable, plusBasicUsersTable]
}

const pageSize = 5
const rootMargin = '125px'

/**
 *
 */
const UserModal = ({ open, handleClose, manageUsers, app }: UserModalInterface) => {
    const [isDataLoading, setIsDataLoading] = useState(true)
    const [users, setUsers] = useState<Users>({ pro: [], plusBasic: [] })
    const [modalType, setModalType] = useState<ModelType>('plusBasicUser')
    const [deactivatedUsersState, setDeactivatedUsersState] = useState<Record<string, any>[]>([])
    const [sentEmailUsersState, setSentEmailUsersState] = useState<Record<string, any>[]>([])
    const [error, setError] = useState('')
    const classes = useStyles({ isDataLoading, modalType }) as Record<string, any>
    const dialogElementRef = useRef<HTMLElement>(null)
    const observerRootElementRef = useRef<HTMLElement>(null)

    const { loading: isLoadingUsers, users: loadedUsers, loadMoreUsers, refreshUsers, order, upgradeUsersToProAndRefresh, isUpgradingUsers, hasMoreUsers, proUsersCount, plusBasicUsersCount } = useContext(ZohoUsersContext)
    const { loading: isLoading, currentUser, requestUpgradeFromAdmin } = useContext(ZohoUserContext)
    const [isUpgradingUsersInProcess, setIsUpgradingUsersInProcess] = useState(isUpgradingUsers)

    const [proUsersTable, plusBasicUsersTable] = getTableObj(users, modalType, { proUsersCount, plusBasicUsersCount })

    const ManageUserTablesHead = ({ columns, enableIconClick }: { columns: Record<string, any>[], enableIconClick: boolean }) => {
        return (
            <Box className={classes.manageUserTablesHeadContainer}>
                {
                    columns.map(column => {
                        const Icon = column.icon as React.ComponentType<Record<string, any>>
                        const showIcon = Boolean(Icon)
                        const handleSwitchPlan = () => {
                            setUsers({ pro: [], plusBasic: [] })
                            const newOrder = order === 'desc' ? 'asc' : 'desc'
                            refreshUsers(newOrder)
                        }

                        return (
                            <Box key={column.testId} data-testid={column.testId} onClick={enableIconClick && showIcon ? handleSwitchPlan : undefined} style={{ cursor: enableIconClick && showIcon ? 'pointer' : undefined }}>
                                {column.content}{showIcon && <Icon style={{ color: order === 'asc' ? '#2AAD93' : undefined }} />}
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const ManageUserTables = ({ proUsersTableProps, plusBasicUsersTableProps }: {proUsersTableProps: Record<string, any>, plusBasicUsersTableProps: Record<string, any>}) => {
        const proUsersRowsLength = proUsersTable.generateRows().length
        const plusBasicUsersRowsLength = plusBasicUsersTable.generateRows().length

        const renderProUserTable = () => (
            Boolean(proUsersRowsLength) &&
            <StatusProvider key='statusProvider' {...{ deactivatedUsersState, setDeactivatedUsersState, sentEmailUsersState, setSentEmailUsersState, app }}>
                <TableMui key='manageProUserTable' {...proUsersTableProps} />
            </StatusProvider>
        )

        const renderPlusBasicUserTable = () => (
            Boolean(plusBasicUsersRowsLength) &&
                <TableMui key='managePlusBasicUserTable' {...plusBasicUsersTableProps} />
        )

        const renderTables = () => {
            if (isLoadingUsers && !(loadedUsers.length >= pageSize)) return <></>

            return (
                order === 'desc'
                    ? <>
                        {renderProUserTable()}
                        {renderPlusBasicUserTable()}
                    </>
                    : <>
                        {renderPlusBasicUserTable()}
                        {renderProUserTable()}
                    </>
            )
        }

        return (
            <>
                <ManageUserTablesHead columns={manageUserTablesHead} enableIconClick={true} />
                {renderTables()}
            </>
        )
    }

    const renderTable = () => {
        let component = <></>
        const proUsersHeadColumns = proUsersTable.generateHeadColumns()
        const proUsersRows = proUsersTable.generateRows()
        const plusBasicUsersHeadColumns = plusBasicUsersTable.generateHeadColumns()
        const plusBasicUsersRows = plusBasicUsersTable.generateRows()

        const tableProps: Record<string, any> = {
            reverseScroll: false,
            listView: false,
            checkboxSelection: true
        }
        let proUsersTableProps: Record<string, any> = {
            ...tableProps,
            headColumns: proUsersHeadColumns,
            rows: proUsersRows
        }
        let plusBasicUsersTableProps: Record<string, any> = {
            ...tableProps,
            headColumns: plusBasicUsersHeadColumns,
            rows: plusBasicUsersRows
        }

        switch (true) {
                case modalType === 'manageUser': {
                    proUsersTableProps = {
                        ...proUsersTableProps,
                        classes: { table: 'manageUser-table' },
                        dataAttr: manageProUserTableDataAttr
                    }
                    plusBasicUsersTableProps = {
                        ...plusBasicUsersTableProps,
                        classes: { table: 'manageUser-table' },
                        dataAttr: managePlusBasicUserTableDataAttr
                    }
                    component = <ManageUserTables {...{ proUsersTableProps, plusBasicUsersTableProps }} />
                    break
                }
                case modalType === 'proUser': {
                    proUsersTableProps = {
                        ...proUsersTableProps,
                        dataAttr: proUserTableDataAttr
                    }
                    component = <TableMui {...proUsersTableProps} />
                    break
                }
                case modalType === 'plusBasicUser': {
                    plusBasicUsersTableProps = {
                        ...plusBasicUsersTableProps,
                        dataAttr: plusBasicUserTableDataAttr
                    }
                    component = <TableMui {...plusBasicUsersTableProps} />
                    break
                }
                default:
                    break
        }

        return component
    }

    const BottomComponent = () => {
        let component = <></>
        const { selectedUsersToUpgrade, removeAllUserIds } = useContext(SelectUsersToUpgradeContext)
        const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false)

        const handleUpgradeUsersToPro = async () => {
            try {
                if (!selectedUsersToUpgrade.length) return

                closeUpgradeUsersToProDialog()

                await upgradeUsersToProAndRefresh(selectedUsersToUpgrade)
            } catch (err) {
                setError((err as Error).message)
            }

            const [plusUserIds, basicUserIds] = filteredPlusBasicUserIds(loadedUsers, selectedUsersToUpgrade)

            if (plusUserIds.length) {
                gtmDataPush({
                    ...plus_users_upgrade,
                    PDC_Value: plusUserIds,
                    items: [{ item_name: app.title }]
                })
            }

            if (basicUserIds.length) {
                gtmDataPush({
                    ...basic_users_upgrade,
                    PDC_Value: basicUserIds,
                    items: [{ item_name: app.title }]
                })
            }
        }

        const openUpgradeUsersToProDialog = () => {
            if (!selectedUsersToUpgrade.length) return

            setOpenUpgradeDialog(true)
        }

        const closeUpgradeUsersToProDialog = () => {
            setOpenUpgradeDialog(false)
        }

        const handleSendEmail = async (user: PlusBasicUser) => {
            try {
                await requestUpgradeFromAdmin(user)
                user.upgradeRequestSent = true
                setUsers({ pro: [], plusBasic: [user] })
            } catch (err) {
                setError('Error sending email')
            }

            gtmDataPush({
                ...email_administrator,
                items: [{ item_name: app.title }]
            })
        }

        const processHandleClose = () => {
            handleClose()

            if (modalType === 'manageUser') {
                gtmDataPush({
                    ...cancel_btn_pressed_user_modal,
                    items: [{ item_name: app.title }]
                })
            } else {
                gtmDataPush({
                    ...cancel_btn_pressed_activate_modal,
                    items: [{ item_name: app.title }]
                })
            }
        }

        useEffect(() => {
            if (!isUpgradingUsers) removeAllUserIds()
        }, [isUpgradingUsers])

        switch (true) {
                case modalType === 'manageUser': {
                    const texts = textObj.managePlusBasicUser as Record<string, any>
                    component = (
                        <>
                            <DialogActionButtons disablePrimaryBtn={!selectedUsersToUpgrade.length || isUpgradingUsers} disableCancelBtn={isUpgradingUsers} handleCancelClick={processHandleClose} handlePrimaryClick={openUpgradeUsersToProDialog} cancelChildren={'Cancel'} primaryChildren={texts.upgradeToProBtn} />
                            <UpgradeUsersToProDialog open={openUpgradeDialog} handleClose={closeUpgradeUsersToProDialog} texts={texts} handleUpgradeUsersToPro={handleUpgradeUsersToPro} classes={classes} />
                        </>
                    )
                    break
                }
                case modalType === 'proUser': {
                    const texts = textObj[modalType]
                    component = <DialogActionButtons handleCancelClick={processHandleClose} handlePrimaryClick={signInToZoho.bind(this, app)} cancelChildren={'Cancel'} primaryChildren={<>{texts.signInBtn}<ArrowForwardIcon className={classes.rightArrowIcon} /></>} />
                    break
                }
                case modalType === 'plusBasicUser': {
                    const texts = textObj[modalType] as Record<string, any>
                    const user = users.plusBasic[0]

                    const upgradeRequestSentComponent = (
                        <>
                            <Typography className={classes.emailSent}>
                                <CheckCircleIcon className={classes.checkCircleIcon} />
                                {texts.emailSent}
                            </Typography>
                        </>
                    )
                    const sendEmailComponent = <DialogActionButtons disablePrimaryBtn={isLoading} handleCancelClick={processHandleClose} handlePrimaryClick={() => handleSendEmail(user)} cancelChildren={'Cancel'} primaryChildren={<>{texts.emailBtn}<img src={sendEmailIcon} /></>} />
                    component = user.upgradeRequestSent ? upgradeRequestSentComponent : sendEmailComponent
                    break
                }
                default:
                    break
        }
        return component
    }

    const renderSpinner = () => {
        if (modalType !== 'manageUser' || !isLoadingUsers) return <></>

        if (((!users.pro.length && !users.plusBasic.length) || !(loadedUsers.length >= pageSize)) && isLoadingUsers) return renderSkeleton(false)

        return (
            <Box className='loadMoreUsersSpinner' textAlign='center' padding='10px'>
                <Spinner />
            </Box>
        )
    }

    useEffect(() => {
        if (isLoading || isLoadingUsers) return

        const isAdmin = currentUser.isAdmin
        let newProUsers: ProUser[] = []
        let newPlusBasicUsers: PlusBasicUser[] = []

        const plan = currentUser.plan
        let user: Record<string, any> = makeUser(currentUser)

        switch (true) {
                case isAdmin && manageUsers: {
                    const [proUsers, plusBasicUsers] = getFilteredUsers(loadedUsers)
                    newProUsers = [
                        ...proUsers
                    ]
                    newPlusBasicUsers = [
                        ...plusBasicUsers
                    ]
                    setModalType('manageUser')
                    break
                }
                case plan.toLowerCase() === 'pro': {
                    newProUsers = [user]
                    setModalType('proUser')
                    break
                }
                case ['plus', 'basic'].includes(plan.toLowerCase()): {
                    user = {
                        ...user,
                        upgradeRequestSent: hasUpgradeRequestSent(users.plusBasic[0])
                    }
                    newPlusBasicUsers = [user]
                    setModalType('plusBasicUser')
                    break
                }
                default:
                    break
        }

        setUsers({ pro: newProUsers, plusBasic: newPlusBasicUsers })
        setIsDataLoading(false)
    }, [isLoading, isLoadingUsers])

    const loadUsers = async () => {
        await loadMoreUsers(pageSize)
    }

    useEffect(() => {
        if (modalType !== 'manageUser') return

        let lastTableRowObserver: IntersectionObserver

        const handleIntersectionObserver = (entries: IntersectionObserverEntry[]) => {
            const lastTableRow = entries[0]
            console.log('Observer isIntersecting', lastTableRow.isIntersecting)
            if (!lastTableRow.isIntersecting) return
            loadUsers()
        }

        if (hasMoreUsers && !isLoadingUsers) {
            lastTableRowObserver = new IntersectionObserver(handleIntersectionObserver,
                { root: observerRootElementRef?.current, rootMargin })

            makePromise<string>(getTableTestId(order, loadedUsers)).then(res => {
                const lastTableRow = getLastTableRow(res as string)
                if (lastTableRow) lastTableRowObserver.observe(lastTableRow)
            })
        }

        return () => {
            if (lastTableRowObserver) lastTableRowObserver.disconnect()
        }
    })

    useEffect(() => {
        if (!isUpgradingUsers) setIsUpgradingUsersInProcess(false)
    }, [isUpgradingUsers])

    const renderSkeleton = (showColumnHeading = true) => {
        const getCount = () => {
            const dialogElement = dialogElementRef.current
            const dialogElementHeight = dialogElement?.clientHeight
            const count = dialogElementHeight ? (dialogElementHeight >= 600 ? Math.floor(dialogElementHeight! / 125) : 3) : 0
            return count
        }

        return (
            <Box className={classes.renderSkeletonContainer}>
                {showColumnHeading && <ManageUserTablesHead columns={manageUsers ? manageUserTablesHead : plusBasicUserTablesHead} enableIconClick={false} />}
                {manageUsers &&
                    <Box className={classes.skeletonUsers}>
                        Users
                    </Box>
                }
                <SkeletonLoader className={classes.skeletonsContainer} count={manageUsers ? getCount() : 1} showTextAtStatus={order === 'desc'} />
                {
                    isDataLoading &&
                    <DialogActions className={`${classes.dialogActionsContainer} dialogActionsSkeletonContainer`}>
                        <DialogActionButtonsSkeleton />
                    </DialogActions>
                }
            </Box>
        )
    }

    console.log('Modal users', users)

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="zoho-user-dialog"
            className={classes.root}
            PaperProps={{ ref: dialogElementRef }}
        >
            <DialogTitle id="zoho-user-dialog-title" className={classes.dialogTitleContainer} >
                <Typography className={classes.dialogTitle}>{textObj.heading}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContentText>
                    <Typography className={classes.dialogSubTitle}>{textObj.subHeading}</Typography>
                </DialogContentText>
            </DialogTitle>
            {
                !isDataLoading && !isUpgradingUsersInProcess
                    ? <>
                        <DialogContent
                            ref={observerRootElementRef}
                        >
                            {renderTable()}
                            {renderSpinner()}
                        </DialogContent>
                        <DialogActions className={classes.dialogActionsContainer}>
                            <BottomComponent/>
                        </DialogActions>
                        <Error message={error} />
                    </>
                    : renderSkeleton()
            }
        </Dialog>
    )
}

export default UserModal
