import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'foundations-library/components'
import Texts from './Texts'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '80px',
        padding: '0px 10px 0px 6px',
        alignItems: 'center',
        gap: '8px'
    }
})

/**
 *
 */
const Device = ({ sim, id }: { sim: string, id: string }) => {
    const classes = useStyles()

    return (
        <div className={`${classes.root} device`}>
            <Icon name={'eSIM'} size='large' color='neutral-900' data-testid='eSIM-icon' />
            <Texts primary={sim} secondary={id} />
        </div>
    )
}

export default Device
