import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'foundations-library/components'
import TransitionSimDialog from './TransitionSimDialog'
import { details } from '../../../utils/objects'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0px',
        gap: '20px'
    }
})

/**
 *
 */
const TransitionSim = () => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false)
    const [terminate, setTerminate] = useState(false)

    const handleOpenDialog = () => setOpenDialog(true)
    const handleCloseDialog = () => setOpenDialog(false)

    const openTerminateDialog = () => {
        setTerminate(true)
        handleOpenDialog()
    }

    const openTransferDialog = () => {
        setTerminate(false)
        handleOpenDialog()
    }

    return (
        <div className={classes.root}>
            <Button variant='text' color='destructive' size='large' onClick={openTerminateDialog}>{details.terminateBtn}</Button>
            <Button variant='contained' color='primary' size='large' icon='go' iconLeft={false} onClick={openTransferDialog}>{details.transferBtn}</Button>
            <TransitionSimDialog {...{ terminate, openDialog, handleCloseDialog }} />
        </div>
    )
}

export default TransitionSim
