/* eslint-disable @typescript-eslint/naming-convention */

const shades = {
    contained: {
        inactive: 400,
        hover: 600,
        active: 700,
        disabled: 100
    },
    outlined: {
        inactive: 500,
        hover: 500,
        active: 600,
        disabled: 200
    },
    text: {
        inactive: 500,
        hover: 600,
        active: 700,
        disabled: 200
    }
}

const whiteShades = {
    inactive: '#ffffff',
    hover: '#fdfdfd',
    active: '#f8f8f8'
}

/**
 *
 */
const styles = (t) => {
    const { colors, typography } = t['foundations-library']
    const getShade = (color, shade) => colors[color][shade]
    const boxShadow = (color, borderSize = '1px') => ({ boxSizing: 'border-box', boxShadow: `inset 0 0 0 ${borderSize} ${color}` })

    const coloring = {
        '&.button--contained': { backgroundColor: colors.primary[shades.contained.inactive] },
        '&.button--outlined': {
            color: getShade('primary', shades.outlined.inactive),
            ...boxShadow(getShade('primary', shades.outlined.inactive), '1.5px'),
            background: 'white'
        },
        '&.button--text': {
            color: colors.primary[shades.text.inactive],
            backgroundColor: 'transparent',
            padding: '0 !important'
        }
    }

    for (const color of Object.keys(colors)) {
        const key = `&.button--${color}`
        // contained
        coloring['&.button--contained'][key] = {
            color: 'white',
            backgroundColor: getShade(color, shades.contained.inactive),
            '&:hover': { backgroundColor: getShade(color, shades.contained.hover) },
            '&.button--active': { backgroundColor: getShade(color, shades.contained.active) },
            '&:disabled': { backgroundColor: getShade('neutral', shades.contained.disabled) },
            '& .button__icon': { fill: 'white' }
        }

        // outlined
        coloring['&.button--outlined'][key] = {
            color: getShade(color, shades.outlined.inactive),
            ...boxShadow(getShade(color, shades.outlined.inactive), '1.5px'),
            background: 'white',
            '& .button__icon': { fill: getShade(color, shades.outlined.inactive) },
            '&:hover': {
                backgroundColor: getShade(color, shades.outlined.hover),
                borderBox: 'none',
                color: 'white',
                '& .button__icon': { fill: 'white' }
            },
            '&.button--active': {
                backgroundColor: getShade(color, shades.outlined.active),
                borderBox: 'none',
                color: 'white',
                '& .button__icon': { fill: 'white' }
            },
            '&:disabled': {
                color: getShade('neutral', shades.outlined.disabled),
                ...boxShadow(getShade('neutral', shades.outlined.disabled), '1.5px'),
                '& .button__icon': { fill: getShade('neutral', shades.outlined.disabled) }
            }
        }

        // text
        coloring['&.button--text'][key] = {
            color: colors[color][shades.text.inactive],
            '& .button__icon': { fill: colors[color][shades.text.inactive] },
            '&:hover': {
                color: colors[color][shades.text.hover],
                '& .button__icon': { fill: colors[color][shades.text.hover] }
            },
            '&.button--active': {
                color: colors[color][shades.text.active],
                '& .button__icon': { fill: colors[color][shades.text.active] }
            },
            '&:disabled': {
                color: getShade('neutral', shades.text.disabled),
                '& .button__icon': { fill: getShade('neutral', shades.text.disabled) }
            }
        }
    }
    const whiteButtonKey = '&.button--white'
    coloring['&.button--contained'][whiteButtonKey] = {
        color: 'transparent',
        backgroundColor: 'white',
        '&:hover': { backgroundColor: whiteShades.active },
        '&.button--active': { backgroundColor: whiteShades.active },
        '&:disabled': { backgroundColor: getShade('neutral', shades.contained.disabled) },
        '& .button__icon': { fill: 'transparent' }
    }

    coloring['&.button--outlined'][whiteButtonKey] = {
        color: whiteShades.inactive,
        ...boxShadow(whiteShades.inactive, '1.5px'),
        background: 'transparent',
        '& .button__icon': { fill: whiteShades.inactive },
        '&:hover': {
            ...boxShadow(whiteShades.hover, '1.5px'),
            color: whiteShades.hover,
            '& .button__icon': { fill: whiteShades.hover }
        },
        '&.button--active': {
            ...boxShadow(whiteShades.active, '1.5px'),
            color: whiteShades.active,
            '& .button__icon': { fill: whiteShades.active }
        },
        '&:disabled': {
            color: getShade('neutral', shades.outlined.disabled),
            ...boxShadow(getShade('neutral', shades.outlined.disabled), '1.5px'),
            '& .button__icon': { fill: getShade('neutral', shades.outlined.disabled) }
        }
    }
    coloring['&.button--text'][whiteButtonKey] = {
        color: whiteShades.inactive,
        '& .button__icon': { fill: whiteShades.inactive },
        '&:hover': {
            color: whiteShades.hover,
            '& .button__icon': { fill: whiteShades.hover }
        },
        '&.button--active': {
            color: whiteShades.active,
            '& .button__icon': { fill: whiteShades.active }
        },
        '&:disabled': {
            color: getShade('neutral', shades.text.disabled),
            '& .button__icon': { fill: getShade('neutral', shades.text.disabled) }
        }
    }
    return {
        button: {
            transition: 'background-color 50ms linear',
            borderRadius: '20px',
            display: 'inline-flex',
            alignItems: 'center',
            minWidth: '90px',
            justifyContent: 'center',
            '&.button--loading': {
                justifyContent: 'center'
            },
            '&:focus': {
                outline: 'none'
            },
            '& .button__icon': {
                marginLeft: '5px',
                '&.button__icon-left': {
                    marginLeft: 0,
                    marginRight: '5px'
                }
            },
            '&.button--small': {
                ...typography.buttonSmall,
                height: '26px',
                padding: '5px 12px 6px 12px',
                '&.button--has-icon-left': {
                    padding: '5px 12px 6px 6px'
                },
                '&.button--has-icon-right': {
                    padding: '5px 6px 6px 12px'
                }
            },
            '&.button--medium': {
                ...typography.buttonMedium,
                height: '32px',
                padding: '8px 14px 9px 14px',
                '&.button--has-icon-left': {
                    padding: '8px 14px 9px 8px'
                },
                '&.button--has-icon-right': {
                    padding: '8px 8px 9px 14px'
                }
            },
            '&.button--large': {
                ...typography.buttonLarge,
                height: '36px',
                padding: '9px 20px 9px 20px',
                '&.button--has-icon-left': {
                    padding: '8px 16px 8px 8px'
                },
                '&.button--has-icon-right': {
                    padding: '8px 8px 8px 16px'
                },
                '&.button--mobile': {
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '-0.1px',
                    textAlign: 'center',
                    padding: '9px 16px'
                }
            },
            ...coloring
        }
    }
}

export default styles
