import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from 'foundations-library/components'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        rowGap: '200px'
    },
    plans: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        marginBottom: '2rem'
    },
    plan: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        boxShadow: '0px 10px 14px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
        flexWrap: 'wrap'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '4px',
        flex: '1 0 0'
    },
    priceDetail: {
        display: 'flex',
        padding: '10px 0px',
        alignItems: 'flex-end',
        gap: '10px'
    },
    price: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '2px'
    },
    details: {
        // paddingBottom: '8px'
    },
    esimCountContainer: {
        boxShadow: '0px 10px 14px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)'
    }
}))

/**
 *
 */
const SelectPlanSkeleton = () => {
    const classes = useStyles()

    const numberOfPlans = () => {
        const count = 1
        /* const height = window.innerHeight
        const heightAbove500 = height - 500
        count += Math.floor(heightAbove500 / 250) */
        return count
    }

    const renderPlan = (index) => (
        <div key={index} className={classes.plan} data-testid='plan-skeleton'>
            <Skeleton variant='rect' width={160} height={160} />
            <div className={classes.info}>
                <Skeleton variant='text' width={'50%'} height={28} />
                <Skeleton variant='text' height={18} />
                <Skeleton variant='text' height={18} />
                <div className={classes.priceDetail}>
                    <div className={classes.price}>
                        <Skeleton variant='text' width={70} height={50} />
                    </div>
                    <div className={classes.details}>
                        <Skeleton variant='text' width={'100px'} height={18} />
                    </div>
                </div>
                <Skeleton variant='rect' width={70} height={30} />
            </div>
        </div>
    )

    return (
        <div className={classes.root}>
            <div className={classes.plans}>
                {
                    Array.from({ length: numberOfPlans() }).map((emptyValue, index) => renderPlan(index))
                }
            </div>
        </div>
    )
}

export default SelectPlanSkeleton
