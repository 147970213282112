import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import { EsimHelp } from 'widgets'
import { HowToArticle } from './../../../../../utils/objects'

/**
 *
 */
export const items = {
    articles: [
        {
            title: 'Activating your cellular service on iPhone'
        },
        {
            title: 'Activating your cellular service on Android'
        },
        {
            title: 'Terminating your cellular service'
        },
        {
            title: 'Configuring your phone number'
        },
        {
            title: 'Troubleshooting your cellular service'
        },
        {
            title: 'Troubleshooting your cellular service'
        },
        {
            title: 'Making a phone call on iPhone'
        },
        {
            title: 'Making a phone call on Android'
        },
        {
            title: 'Checking if your device is locked on iPhone'
        }
    ]
}

const useStyles = makeStyles(theme => ({
    articlesRoot: {
        rowGap: '.5rem'
    },
    title: { margin: 'auto', padding: '1.5rem 0 3rem' },
    articlesContainer: {},
    viewMoreBtn: { margin: '2rem auto 0' }
}))

/**
 *
 */
export const Articles = ({ articles }: { articles: HowToArticle[] }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.articlesContainer} spacing={3}>
            {
                articles.map(article => <EsimHelp key={article.title} article={article} />)
            }
        </Grid>
    )
}

/**
 *
 */
const Index = () => {
    const classes = useStyles()

    return (
        <Grid container xs={12} className={classes.articlesRoot}>
            <Typography variant='h2' className={classes.title}>How-to-articles</Typography>
            <Articles articles={items.articles} />
            <Button variant='outlined' size='large' color='secondary' className={classes.viewMoreBtn}>View more</Button>
        </Grid>
    )
}

Index.displayName = 'Articles'

export default Index
