import React from 'react'
import { texts as textObj, plusBasicUserTablesHead } from './objects'
import { User, Plan, Status } from './components'
import { SelectAll } from './components'

class Table <S extends Record<string, any>, T extends string> {
    /**
     *
     */
    constructor (public users: S[], public tableType: T, public usersCount: number) {} // eslint-disable-line no-useless-constructor

    public generateHeadColumns = () => {
        let headColumns: {content: string|JSX.Element, testId: string}[] = plusBasicUserTablesHead

        const tableType = this.tableType
        const texts = textObj[tableType as string]
        switch (tableType) {
                case 'manageProUser': {
                    headColumns = [
                        { content: `PRO users (${this.usersCount})`, testId: 'pro-user-col' },
                        { content: '', testId: '' },
                        { content: '', testId: '' }
                    ]
                    break
                }
                case 'managePlusBasicUser': {
                    headColumns = [
                        { content: `Basic & Plus users (${this.usersCount})`, testId: 'plus-basic-user-col' },
                        { content: '', testId: '' },
                        { content: <SelectAll checkAllTitle={texts.checkAll} />, testId: 'check-box-head-col' }
                    ]
                    break
                }
                case 'proUser': {
                    break
                }
                case 'plusBasicUser': {
                    break
                }
                default: {
                    break
                }
        }

        return headColumns
    }

    public generateRows = () => {
        const rows = this.users.map(user => {
            return this.generateRow(user)
        })
        return rows
    }

    public generateRow = (user: S) => {
        const columns = [
            { key: 0, content: this.renderNameColumn(user), primary: true, stringValue: `${user.firstName} ${user.lastName}`, testId: 'name' },
            { key: 1, content: this.renderPlanColumn(user), testId: 'plan' },
            { key: 2, content: this.renderStatusColumn(user), testId: 'status' }
        ]

        return { key: user.id, columns }
    }

    public renderNameColumn = (user: S) => {
        const tableType = this.tableType
        return <User {...{ user, tableType }} />
    }

    public renderPlanColumn = (user: S) => {
        const tableType = this.tableType
        return <Plan {...{ user, tableType }} />
    }

    public renderStatusColumn = (user: S) => {
        const tableType = this.tableType as string
        const texts = textObj[tableType as string]
        return <Status {...{ user, tableType, texts }} />
    }
}

export default Table
