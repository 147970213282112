import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import EsimStepperProvider from './../../../../contexts/EsimStepperContext'
import ProcessEsimStepper from './stepper/Index'
import OrderPlaced from './orderPlaced/Index'
import { EsimProvider } from 'providers'

/**
 *
 */
const Index = () => {
    const routeMatch = useRouteMatch()
    const url = routeMatch.url.replace(/\/$/, '')

    return (
        <EsimStepperProvider>
            <EsimProvider>
                <Switch>
                    <Route path={`${url}/order-placed`} render={() => {
                        return <OrderPlaced />
                    }} />
                    <Route path={'/'} render={() => {
                        return <ProcessEsimStepper />
                    }} />
                </Switch>
            </EsimProvider>
        </EsimStepperProvider>
    )
}

export default Index
