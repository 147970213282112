import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { imagePlaceholder } from '../utils/inlineIcons'
import useProgressiveImg from '../hooks/useProgressiveImg'
import { handleGTM, GTM_APP_DETAILS_NAME } from '../utils/utils'
import { App } from '../utils/objects'

const usePictureStyles = makeStyles(theme => ({
    picture: {
        position: 'relative',
        '&::before': {
            content: ({ imgLoading }: { imgLoading: boolean }) => imgLoading ? '""' : 'none',
            position: 'absolute',
            inset: 0,
            animation: '$pulse 2s infinite',
            zIndex: 1
        }
    },
    '@keyframes pulse': {
        '0%': {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        '50%': {
            backgroundColor: 'rgba(255, 255, 255, .2)'
        },
        '100%': {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        }
    }
}))

/**
 *
 */
const Picture = ({ sources, thumbnail, alt, attributes }: { sources: Record<string, any>[], thumbnail?: string, alt?: string, attributes?: React.ImgHTMLAttributes<HTMLImageElement> }): JSX.Element => {
    const imgSrc = sources.filter(source => window.matchMedia(source.media).matches)[0]?.src || sources[0].src
    const [src, { imgLoading }] = useProgressiveImg(thumbnail || imagePlaceholder, imgSrc)
    const classes = usePictureStyles({ imgLoading })

    return (
        <picture className={classes.picture} {...attributes}>
            {sources.map((source, index) => <source key={index} {...source} srcSet={imgLoading ? src : source.src}></source>)}
            <img src={thumbnail || imagePlaceholder} alt={alt || 'image'} />
        </picture>
    )
}

/**
 *
 */
const Video = ({ sources, attributes, app }: { sources: Record<string, any>[], app: App, attributes?: React.VideoHTMLAttributes<HTMLVideoElement> }): JSX.Element => {
    const videoOnPlay = (ev: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const videoSrc = (ev.currentTarget as HTMLVideoElement).currentSrc
        const appTitleWithUnderscores = app.title.toLowerCase().replace(/\s+/g, '_')
        handleGTM({
            action: GTM_APP_DETAILS_NAME,
            label: 'video_played',
            event: `PDC_${appTitleWithUnderscores}_video_played`,
            value: videoSrc,
            other: {
                items: [{ item_name: app.title }]
            }
        })
        if (attributes && attributes.onPlay) attributes.onPlay(ev)
    }

    return (
        <video controls preload='metadata' {...attributes} onPlay={videoOnPlay}>
            {sources.map((source, index) => <source key={index} {...source} src={source.src}></source>)}
            Your browser does not support the video tag.
        </video>
    )
}

/**
 *
 */
export { Picture, Video }
