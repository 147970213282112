/* eslint-disable react/prop-types */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PDCMarkdown from 'pdc-markdown'

const useStyles = makeStyles(theme => ({
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        '& td': {
            padding: '5px'
        }
    }
}))

/**
 *
 */
const TabMarkdown = (props) => {
    const classes = useStyles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} className={classes.topContainer}>
                <PDCMarkdown>
                    {props.children}
                </PDCMarkdown>
            </Grid>
        </Grid>
    )
}

export default TabMarkdown
