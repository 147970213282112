import React, { useEffect, useState } from 'react'
// import PDCOpenConnection from 'pdc-open-connection'
import { MyPhoneContactContext } from 'providers'
import API from 'api'

// import Button from 'button'
// import API from '../util/api_v5'
// import Spinner from 'spinner'

interface Props {
    /**
     * The link uuid value from the call log corisponding with the recoridng
     */
    linkedUuid: string

}

type Data = Record<string, any>;

/**
 *
 */
export default function CallTranscript (props: Props): JSX.Element {
    const [transcription, setTranscription] = useState(null)
    // const [loadingTranscription, setloadingTranscription] = useState(false)
    const [showFullTranscript, setShowFullTranscript] = useState(false)
    const [foundNumbers, setFoundNumbers] = useState({})
    const contactContext = React.useContext(MyPhoneContactContext)

    useEffect(() => {
        // const storedTranscript = localStorage.getItem(`transcriptSummary-${props.linkedUuid}`)
        // if (storedTranscript) setTranscription(JSON.parse(storedTranscript))

        // PDCOpenConnection.on('call_transcription', (extensionId: number, inTranscr: any) => {
        //     console.log('transcript: ', inTranscr, extensionId)

        //     Object.keys(inTranscr).forEach((linkedUuid) => {
        //         localStorage.setItem(`transcriptSummary-${linkedUuid}`, JSON.stringify(inTranscr))
        //         setTranscription(inTranscr[linkedUuid]) // could cause the wrong transcript to show if on a different page when it comes in
        //         // setloadingTranscription(false)
        //     })
        // })
    }, [])

    useEffect(() => {
        setTranscription(null)
        setShowFullTranscript(false)
        API.getCallTranscription(props.linkedUuid).then(responseData => {
            setTranscription(JSON.parse(responseData.data))
        })
    }, [props.linkedUuid])

    useEffect(() => {
        if (transcription) {
            const numbers = findE164Numbers(transcription)
            console.log({ numbers })
            const numbersToConactMap: Record<string, string> = {}
            numbers.forEach(foundNumber => {
                contactContext.getDisplayName(foundNumber).then(
                    contextDisplayName => {
                        numbersToConactMap[foundNumber] = contextDisplayName
                    }
                ).catch(err => console.error(err))
            })
            setFoundNumbers(numbersToConactMap)
        }
    }, [transcription])

    const findE164Numbers = (data: Data): string[] => {
        const regex = /\+[\d]{10,15}/g
        const numbers: string[] = []

        // Recursively search for E.164 numbers in all string values of the object
        const searchNumbers = (obj: any) => {
            if (typeof obj === 'string') {
                const matches = obj.match(regex)
                if (matches) {
                    numbers.push(...matches)
                }
            } else if (typeof obj === 'object' && obj !== null) {
                Object.values(obj).forEach(searchNumbers)
            }
        }
        searchNumbers(data)
        return Array.from(new Set(numbers)) // todo probably a better way to handle this, prior to here.
    }

    // const handleInitCallTranscriptSummary = () => {
    //     setloadingTranscription(true)
    //     API.initCallTranscriptSummary(props.linkedUuid)
    // }
    const handleShowFullTranscript = () => {
        setShowFullTranscript(true)
    }

    const replaceAll = (inputString: string, find: string, replaceWith: string) => { // jest did not support string.replaceAll
        return inputString?.split(find).join(replaceWith) || inputString
    }
    // replaces number with contact name if it exists. We might want to do display name component here or something.
    const replaceNumberWithName = (value: string) => {
        let replaceNumberWithNameValue = value || ''

        Object.keys(foundNumbers).map(foundNumber => {
            replaceNumberWithNameValue = replaceAll(replaceNumberWithNameValue, foundNumber, `${foundNumbers[foundNumber]} (${foundNumber})`)
            return replaceNumberWithNameValue
        }
        )

        return replaceNumberWithNameValue
    }
    return (<>
        { (transcription)
            ? <>
                {Object.keys(transcription.summary).map((transcriptionKeys): JSX.Element => {
                    return (<p data-testid={`transcriptionSummaryItem-${transcriptionKeys}`} style={{ textTransform: 'capitalize', whiteSpace: 'pre-wrap' }} key={transcriptionKeys}><h2>{transcriptionKeys.replace('abstract_summary', 'summary').replace('_', ' ')}</h2>
                        test {replaceNumberWithName(transcription.summary[transcriptionKeys])}
                        <br/>
                        <br/>
                    </p>)
                })
                }
                {
                    (showFullTranscript)
                        ? <p data-testid={'transcriptionSummaryItemFullSummary'} style={{ whiteSpace: 'pre-wrap' }}>
                            <h4>Full Transcript</h4>
                            {replaceNumberWithName(replaceAll(transcription?.transcription, '+', '\n\n+')) }
                        </p>
                        : <div data-testid={'showFullTranscriptBtn'} style={{ color: 'green', margin: 'auto', cursor: 'pointer' }} onClick={handleShowFullTranscript}> View full transcript</div>
                }
            </>
            : <></>
            // commented out the below because we went event based if you have the feature enabled. left incase we mant to re-add support in teesting.
            // (loadingTranscription)
            //     ? <Spinner style={{ margin: 'auto' }}/>
            //     : <Button onClick={handleInitCallTranscriptSummary}> Generate Transcript</Button>
        }

    </>)
}
