import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { App } from '../../../utils/objects'
import { useAppContext } from '../../../contexts/AppContext'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import ContentTabs from './ContentTabs'
import Carousel from '../../../components/Carousel'

const useStyles = makeStyles(theme => ({
    descriptionContainer: {
        margin: '2rem 0'
    },
    mediaContainer: {
        flexDirection: 'column',
        display: 'flex',
        rowGap: '1rem',
        margin: '2rem 0',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        },
        '& video': {
            width: '100%',
            height: '100%'
        }
    },
    tabsContainer: {

    }
}))

/**
 *
 */
const Content = (): JSX.Element => {
    const classes = useStyles()
    const appContext = useAppContext()
    const app = appContext.app as App

    // eslint-disable-next-line no-unused-vars
    const renderDescription = () => (
        <Typography variant='body1'>
            {app.details.overview.general}
        </Typography>
    )

    // eslint-disable-next-line no-unused-vars
    const renderMedia = () => (
        <>
            {
                Boolean(app.details.images?.length) &&
      <Grid container spacing={2}>
          {
              app.details.images?.map((image, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                      <img src={image} alt={`${app.title} UI image`} loading='lazy' />
                  </Grid>
              )) || <></>
          }
      </Grid>
            }
            {
                Boolean(app.details.videos?.length) &&
      <Grid container spacing={2}>
          {
              app.details.videos?.map((video, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                      <video src={video} controls preload='metadata' data-testid='videoElement' />
                  </Grid>
              )) || <></>
          }
      </Grid>
            }
        </>
    )

    return (
        <Box>
            {/* <Box className={classes.descriptionContainer}>
                {renderDescription()}
            </Box> */}
            <Box className={classes.mediaContainer}>
                {/* {renderMedia()} */}
                <Carousel app={app} />
            </Box>
            <Box className={classes.tabsContainer}>
                <ContentTabs app={app} />
            </Box>
        </Box>
    )
}

export default Content
