import { VerbalColorType } from 'foundations-library/types'

/**
 *
 */
export const makeFirstLetterUppercase = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

/**
 *
 */
export const getFirstLetters = (text: string) => text.split(' ').map(word => word.charAt(0)).join('')

/**
 *
 */
export const makeURL = (endpoint: string): RegExp => new RegExp(`^https?://.*/${endpoint}$`, 'i')

/**
 *
 */
export const getStatus = (sim: Record<string, any>, assignedToMe = false) => {
    let statusText = ''
    let statusCaption = ''
    let statusTextColor: VerbalColorType = 'neutral-900'

    const status = sim.status.toLowerCase()
    switch (status) {
            case 'ordered':
            case 'backordered': {
                statusText = 'In progress'
                statusCaption = 'Your eSIM order is in progress.'
                statusTextColor = 'destructive-500'
                break
            }
            case 'deleted': {
                statusText = 'Terminated'
                break
            }
            case 'provisioned': {
                if (!sim.user) {
                    statusText = 'Available'
                    statusCaption = 'Assign a user to activate.'
                } else {
                    statusText = 'Ready'
                    statusCaption = !assignedToMe ? 'Ready for activation.' : 'An eSIM has been assigned to you.'
                    statusTextColor = 'destructive-500'
                }
                break
            }
            case 'activated': {
                let formattedDate = ''
                if (sim.activated_at) {
                    const dateObj = new Date(sim.activated_at)
                    formattedDate = dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                }
                statusText = 'Activated'
                statusCaption = `Activated ${formattedDate}.`
                statusTextColor = 'secondary-600'
                break
            }
    }

    return [statusText, statusCaption, statusTextColor]
}

/**
 *
 */
export const makePromise = <T>(arg: T) => new Promise(resolve => resolve(arg))
