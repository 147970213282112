import React, { useState, useEffect, useContext } from 'react'
import GoBack from '../../components/GoBack'
import { Typography, IconButton, BasicTable, Alert } from 'foundations-library/components'
import { VerbalColorType } from 'foundations-library/types'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { EsimContext } from 'providers'
import { getPhoneCom } from 'phonecom'
import { formatPhoneNumber } from 'phone-numbers'
import ListSkeleton from './components/ListSkeleton'
import { getStatus } from './../../utils/index'
import { listing as listTexts } from './../../utils/objects'
import { makeStyles } from '@material-ui/core/styles'
import AddButton from './components/AddButton'
import Device from './../components/Device'
import Texts from './../components/Texts'
import NoEsimAssigned from './components/NoEsimAssigned'
import Help from './../components/Help'

const useStyles = makeStyles({
    root: {
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        },
        margin: 'auto',
        maxWidth: '900px'
    },
    contentContainer: {
        margin: '50px 10px 0',
        '& .table-container': {
            marginBottom: '20px',
            '& th:first-child': {
                minWidth: '300px',
                '@media (max-width: 600px)': {
                    minWidth: '150px'
                }
            }
        }
    },
    helpWidgetsContainer: {
        marginTop: '40px'
    }
})

/**
 *
 */
const Index = ({ myEsims = false }: { myEsims?: boolean }) => {
    const classes = useStyles()
    const [data, setData] = useState<React.ReactNode[][]>([])
    const history = useHistory()
    const { app, esims, fetchEsims, fetchPlans } = useContext(EsimContext)
    let { path } = useRouteMatch()
    path = path.replace(/\/$/, '')

    useEffect(() => {
        const setEsimsData = async (esims, plans) => {
            const phoneCom = await getPhoneCom()

            let rows: React.ReactNode[][] = []
            if (!myEsims) {
                rows = esims.map(esim => {
                    const [statusText, statusCaption, statusTextColor] = getStatus(esim)

                    const device = esim.esim ? <Device sim={esim.esim.device_name || `eSIM ${esim.service_id}`} id={`ICCID: ${esim.esim.iccid}`} /> : <Device sim={`eSIM ${esim.service_id}`} id='ICCID: NOT ASSIGNED' />
                    const assignedTo = esim.user ? <Texts primary={`${esim.user.extension?.name || `${esim.user.first_name} ${esim.user.last_name}`}`} secondary='' /> : null
                    const number = esim.user ? <Texts primary={formatPhoneNumber(esim.user.did)} secondary='' /> : null
                    const status = <Texts primary={statusText} secondary={statusCaption} primaryColor={statusTextColor as VerbalColorType} />
                    const action = esim.esim && <IconButton name='go' variant='icon' color='neutral' onMouseUp={() => history.push(`${path}/${esim.service_id}`)} />

                    return [device, assignedTo, number, status, action]
                })
            } else {
                const esimsAssignedToMe = esims.filter(esim => Number(esim.user?.voip_phone_id) === Number(phoneCom.voip_phone_id))
                rows = esimsAssignedToMe.map(esim => {
                    const [statusText, statusCaption, statusTextColor] = getStatus(esim, true)
                    const esimPlan = plans.find(plan => plan.billing_code === esim?.billing_code)

                    const device = <Device sim={esim.esim.device_name || `eSIM ${esim.service_id}`} id={`ICCID: ${esim.esim.iccid}`} />
                    const plan = <Texts primary={esimPlan.name} secondary={esim.status === 'activated' ? statusCaption : 'Awaiting activation'} primaryColor={'neutral-900'} />
                    const number = <Typography variant='subtitle2' color='neutral-900'>{formatPhoneNumber(esim.user.did)}</Typography>
                    const status = <Texts primary={statusText} secondary={statusCaption} primaryColor={statusTextColor as VerbalColorType} />
                    const action = <IconButton name='go' variant='icon' color='neutral' onMouseUp={() => history.push(`${path}/${esim.service_id}`)} />

                    return [device, plan, number, status, action]
                })
            }

            setData(rows)
        }

        (async () => {
            const [esims, plans] = await Promise.all([fetchEsims(myEsims), fetchPlans()])

            await setEsimsData(esims, plans)
        })()
    }, [JSON.stringify(esims.esims)])

    const columns = !myEsims ? listTexts.admin : listTexts.user

    console.log('eSIMs:', esims)

    const renderContent = (content: JSX.Element) => (
        <>
            {!myEsims && <GoBack title={app?.title ? app.title : 'eSIMs'} />}
            <div className={classes.root}>
                {content}
            </div>
        </>
    )

    if (esims.isLoading) return renderContent(<ListSkeleton />)
    if (esims.error) return renderContent(<Alert level='error' icon={true}>{esims.error}</Alert>)
    if (!esims.esims.length) return renderContent(<Typography variant='h6'><NoEsimAssigned myEsims={myEsims} /></Typography>)
    if (!data.length) return renderContent(<ListSkeleton />)

    return renderContent(
        <>
            <div className={classes.contentContainer}>
                <BasicTable columns={columns} rows={data} />
                {!myEsims && <AddButton />}
                <div className={classes.helpWidgetsContainer}>
                    <Help />
                </div>
            </div>
        </>
    )
}

export default Index
