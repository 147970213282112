import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { Typography, Button } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { useEsimStepperContext } from '../../../../../../contexts/EsimStepperContext'

interface PlanInterface {
    icon: string
    title: string
    subTitle?: string
    price: number
    priceText: string
    id: number
    isSelected: boolean
}

const useStyles = makeStyles((theme) => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            padding: '20px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            boxShadow: '0px 10px 14px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            flexWrap: 'wrap',
            border: ({ isSelected }: { isSelected: boolean }) => isSelected ? `1px solid ${verbalColors['neutral-500']}` : 'none',
            '& .esim-icon': {
                width: '160px'
            }
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
            flex: '1 0 0'
        },
        priceDetail: {
            display: 'flex',
            padding: '10px 0px',
            alignItems: 'flex-end',
            gap: '10px'
        },
        price: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: '2px'
        },
        details: {
            paddingBottom: '8px'
        }
    }
})

/**
 *
 */
const Plan = ({ icon, title, subTitle, price, priceText, id, isSelected }: PlanInterface) => {
    const { setSelectedPlan } = useEsimStepperContext()
    const { plans: { plans } } = useContext(EsimContext)
    const classes = useStyles({ isSelected: plans.length !== 1 ? isSelected : false })

    return (
        <div className={classes.root} data-testid='plan'>
            <img src={icon} alt='esim-icon' className='esim-icon' />
            <div className={classes.info}>
                <Typography variant='h6' color='neutral-900'>{title}</Typography>
                <Typography variant='body3' color='neutral-700'>{subTitle}</Typography>
                <div className={classes.priceDetail}>
                    <div className={classes.price}>
                        <Typography variant='h4' color='neutral-900'>$</Typography>
                        <Typography variant='h2' color='neutral-900'>{price}</Typography>
                    </div>
                    <div className={classes.details}>
                        <Typography variant='subtitle2' color='neutral-900'>{priceText}</Typography>
                    </div>
                </div>
                {plans.length !== 1 && <Button size='medium' color={!isSelected ? 'primary' : 'neutral'} variant={!isSelected ? 'outlined' : 'contained'} iconLeft={isSelected} onClick={() => setSelectedPlan({ title, price, id })} icon={isSelected ? 'check' : undefined}>Select</Button>}
            </div>
        </div>
    )
}

export default Plan
