/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable jsdoc/require-description */
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { Teammate } from 'models'
import { formatPhoneNumber } from 'phone-numbers'

const REACT_APP_USER_API_URL = process.env.REACT_APP_USER_API_URL || 'http://default-url-for-testing'
const PAGE_LIMIT = 100
// const DEFAULT_CURSOR = 'default'

/**
 *
 */
export class Teammates {
    items: Map<number, Teammate> = new Map()
    hasMore = false
    total = 0
    cursor: string = Teammates.encodeCursor(PAGE_LIMIT, 0, '', false)

    /**
     * @param {Teammates | null} t
     */
    constructor (t: Teammates | null = null) {
        if (t != null) this.update(t)
    }

    /**
     * @param cursor
     */
    static load = async (cursor: string) : Promise<Teammates> => {
        let { page_size, offset, filter, hasMore } = Teammates.decodeCursor(cursor)
        const teammates = new Teammates()
        const account_id: number = PhoneComUser.getAPIAccountId()

        const api_base = REACT_APP_USER_API_URL || ''
        if (filter.length > 0) {
            filter = filter.replace(' ', '%20')
        }
        let requestUrl = `${api_base}/voip/${account_id}/users?page_size=${page_size}&offset=${offset}&order_by=status_asc_first_name_asc.asc&include=extension,plan,devices,numbers`
        if (filter.length > 0) requestUrl += `&name=${filter}`
        const response: any = await ajax.get(requestUrl)
        if (response.message === 'Network Error') {
            console.log('teammates error: ', filter, response)
        } else {
            const items = response?.data?.data
            if (!items) return teammates
            items.forEach(t => {
                t = Teammate.fromJson(t)
                teammates.items.set(t.id, t)
            })
            teammates.total = teammates.items.size
            teammates.hasMore = teammates.total === page_size
            teammates.cursor = Teammates.encodeCursor(page_size, offset + page_size, filter, hasMore)
        }
        return teammates
    }

    /**
     * @param {number} page_size
     * @param {number} offset
     * @param {string} filter
     * @param {boolean} hasMore
     */
    static encodeCursor = (page_size: number, offset: number, filter: string, hasMore: boolean): string => {
        return page_size.toString() + '@' + offset.toString() + '@' + (filter + ' ') + '@' + hasMore
    }

    /**
     * @param {string} cursor
     */
    static decodeCursor = (cursor: string): any => {
        const s = cursor.split('@')
        if (s.length !== 4) return { page_size: 0, offset: 0, filter: '', hasMore: false }
        return {
            page_size: Number.parseInt(s[0]),
            offset: Number.parseInt(s[1]),
            filter: s[2].trim(),
            hasMore: s[3] === 'true'
        }
    }

    /**
     * @param updated
     */
    update (updated: Teammates) : Teammates {
        if (updated != null) return this
        const combined = new Teammates()
        combined.items = this.items
        updated.items.forEach((v, k) => {
            this.items.set(k, v)
        })
        combined.hasMore = updated.hasMore
        combined.cursor = updated.cursor
        combined.total = combined.items.size
        return combined
    }

    /**
     * @param t
     */
    add (t: Teammate) : void {
        this.items.set(t.id, t)
        this.total += 1
    }

    /**
     * @param {number} t_id
     */
    remove (t_id: number) : void {
        this.items.delete(t_id)
        this.total -= 1
    }

    /**
     * @param {string} value
     */
    filter = (value: string): Teammates => {
        const filtered = new Teammates()
        this.items.forEach((t, id) => {
            t.displayName()
            value = value.toString()
            const name: string = t.displayName().toLowerCase()
            const lowerCaseValue: string = value.toLowerCase()
            if (t.extension.number.toString().includes(value) ||
            t.numbers.has(formatPhoneNumber(value)) ||
            name.includes(lowerCaseValue)) { filtered.add(t) }
        })
        filtered.total = filtered.items.size
        filtered.cursor = Teammates.encodeCursor(PAGE_LIMIT, 0, value, true)
        return filtered
    }

    contains = (ext: number): boolean => {
        let found = false
        this.items.forEach(t => {
            if (t.extension.extension_number === ext) found = true
        })
        return found
    }

    find = (extId: number): (Teammate|undefined) => {
        let item
        this.items.forEach(t => {
            if (t.extension.id === extId) item = t
        })
        return item
    }
}
