/**
 *
 */
const style = () => ({
    list: {
        maxWidth: '900px',
        margin: '30px auto 0 auto',
        '& .payment-methods--content': {
            padding: ({ screenSize }) => screenSize.mobile ? '0 10px' : '0 42px',
            marginTop: ({ screenSize }) => screenSize.mobile ? '20px' : '30px'
        }
    }
})

export default style
