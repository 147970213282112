import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Typography, BasicTable } from 'foundations-library/components'
import { EsimContext } from 'providers'
import Title from './../Title'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& table': {
            '& th:nth-child(3)': {
                textAlign: 'right'
            },
            '& tr:last-child': {
                borderBottom: 'none !important'
            }
        }
    },
    device: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    }
}))

const columns = ['Item', 'Qty', 'Charges']

/**
 *
 */
const Index = () => {
    const classes = useStyles()
    const [data, setData] = useState<React.ReactNode[][]>([])
    const { orderEsims } = useContext(EsimContext)
    const amountToFee = (amount: number) => {
        const fee = "$" + String(amount / 100)
        if (fee.indexOf('.') === fee.length - 2) {
            return fee + "0"
        }
        return fee
    }
    useEffect(() => {
        const { data: orderedEsims } = orderEsims
        const billingData = orderedEsims.billing
        const dataForRows: Record<string, any>[] = []
        billingData.transactions.forEach(transaction => {
            const esim = {
                item: billingData.labels[transaction.code],
                qty: 1,
                charges: amountToFee(transaction.amount)
            }
            dataForRows.push(esim)
        })
        billingData.tax_transactions.forEach(tax_transaction => {
            const tax = {
                item: tax_transaction.description,
                qty: '',
                charges: amountToFee(tax_transaction.amount)
            }
            dataForRows.push(tax)
        })
        const total = {
            item: 'Total',
            qty: '',
            charges: billingData.total_fees_as_string
        }
        dataForRows.push(total)

        const rows = dataForRows.map((row, index) => {
            const isLastRow = dataForRows.length === (index + 1)

            const item = row.qty
                ? <div className={classes.device}>
                    <Icon name={'eSIM'} size='large' color='neutral-900' />
                    <Typography variant='subtitle3' color='neutral-900'>{row.item}</Typography>
                </div>
                : <Typography variant={!isLastRow ? 'body3' : 'body3heavy'} color='neutral-900'>{row.item}</Typography>
            const qty = row.qty ? <Typography variant='body3' color='neutral-900'>{row.qty}</Typography> : ''
            const charges = <Typography variant={!isLastRow ? 'body3' : 'body3heavy'} align='right' color='neutral-900'>{row.charges}</Typography>

            return [item, qty, charges]
        })

        setData(rows)
    }, [])

    return (
        <div className={classes.root}>
            <Title title='Review the following details before placing your order' />
            <BasicTable columns={columns} rows={data} />
        </div>
    )
}

export default Index
