/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from 'react'
import { TextField } from 'foundations-library/components'
import Address from './Address'
import { AccountContactType } from 'interfaces'
import { emptyAddress } from 'helpers'
import { makeStyles } from '@material-ui/core'
import { ScreenSizeContext } from 'providers'

const style = () => ({
    contact: {
        '& .contact--name': {
            display: 'flex',
            flexDirection: ({ screenSize }) => screenSize.mobile ? 'column' : 'row',
            '& .contact--first-name': {
                marginRight: ({ screenSize }) => screenSize.mobile ? '0' : '10px',
                marginTop: ({ screenSize }) => screenSize.mobile ? '10px' : '0'
            },
            '& .contact--last-name': {
                marginLeft: ({ screenSize }) => screenSize.mobile ? '0' : '10px',
                marginTop: ({ screenSize }) => screenSize.mobile ? '10px' : '0'
            }
        }
    }
})

const useStyles = makeStyles(style)
interface PropsInterface {
    value: AccountContactType,
    // value: any,
    onChange(v): any;
    isValid?(v): any;
    disabled: boolean;
}

/**
 *
 */
function contact ({ value, onChange, isValid, disabled }: PropsInterface): JSX.Element {
    const [item, setItem] = useState(value ? { ...value } : { first_name: '', last_name: '', address: emptyAddress() })
    const [addressIsValid, setAddressIsValid] = useState(false)
    const screenSize = useContext(ScreenSizeContext)
    const styles = useStyles({ screenSize })

    const onInputChange = (prop, cleared = false) => {
        return (e) => {
            let newValue
            if (cleared) {
                newValue = ''
            } else if (e.target) {
                newValue = e.target.value
            } else {
                newValue = e
            }
            item[prop] = newValue
            setItem({ ...item })
        }
    }

    const formIsValid = () => addressIsValid && item.first_name && item.last_name

    useEffect(() => {
        onChange(item)
        if (isValid) {
            isValid(formIsValid())
        }
    }, [item])

    return (
        <div className={styles.contact}>
            <div>
                <div className="contact--name">
                    <TextField
                        placeholder='First name'
                        value={item.first_name}
                        onChange={onInputChange('first_name')}
                        onClear={onInputChange('first_name', true)}
                        disabled={disabled}
                        fullWidth={true}
                        className="contact--first-name"
                        data-testid="first-name-input"
                    />
                    <TextField
                        placeholder='Last name'
                        value={item.last_name}
                        onChange={onInputChange('last_name')}
                        onClear={onInputChange('last_name', true)}
                        disabled={disabled}
                        fullWidth={true}
                        className="contact--last-name"
                        data-testid="last-name-input"
                    />
                </div>
                <Address
                    value={item.address}
                    onChange={onInputChange('address')}
                    isValid={setAddressIsValid}
                    disabled={disabled}
                    data-testid="address-input"
                />
            </div>
        </div>
    )
}

export default contact
