import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoEsimAssignedImage from './images/NoEsimsAssignedIllustration.png'
import { Typography } from 'foundations-library/components'
import { details } from './../../../utils/objects'
import AddButton from './AddButton'
import { getPhoneCom } from 'phonecom'
const useStyles = makeStyles({
    root: {
        margin: '0 10px',
        /* Frame 1410125932 */

        /* Auto layout */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        gap: '10px',
        // position: 'absolute',
        justifyContent: 'center',
        width: '90%', // not sure why but 100% makes it too big and 90% makes it just right, figure out why later
        height: '443px'

    },
    image: { width: '430px', height: '272px' }

})

/**
 *
 */
const NoEsimAssigned = ({ myEsims = false }: { myEsims?: boolean }) => {
    const classes = useStyles()
    const [userRole, setUserRole] = useState('')
    const { NoEsimAssigned } = details

    useEffect(() => {
        // get account role to check if admin or user from getPhoneCom
        getPhoneCom().then((phoneCom: Record<string, any>) => {
            setUserRole(phoneCom?.role)
        })
    }, [])
    return (
        <div className={classes.root}>
            <img className={classes.image} src={NoEsimAssignedImage} alt="NoEsimAssignedImage" />
            <Typography variant='h5' color='neutral-900'>{NoEsimAssigned.title}</Typography>
            {userRole === 'account'
                ? <>
                    <AddButton variant="contained" size="large" icon="go">{NoEsimAssigned.admin}</AddButton>
                </>
                : <Typography variant='body3' color='neutral-700'>{NoEsimAssigned.nonAdmin}</Typography>}

        </div>
    )
}

export default NoEsimAssigned
