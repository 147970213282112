import { makeStyles } from '@material-ui/core/styles'

/**
 * @param {object} theme
 */
export const useStyles = makeStyles(theme => ({
    root: {
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        },
        '& .error': {
            color: '#f00',
            textAlign: 'center'
        },
        '& .MuiDialog-paper': {
            height: '80vh',
            padding: '20px 0 40px 0',
            '& .MuiDialogTitle-root': {
                padding: '0 40px'
            },
            '& .MuiDialogContent-root': {
                '& .loadMoreUsersSpinner': {
                    display: 'block'
                },
                '& .MuiTableContainer-root': {
                    // maxHeight: '205px',
                    height: 'inherit',
                    borderRadius: 0
                },
                '& .MuiTableContainer-root .MuiTable-root': {
                    '& th:nth-child(1)': {
                        padding: '14.5px 8px 12.5px 40px'
                    },
                    '& td:nth-child(1)': {
                        padding: '18px 8px 18px 40px'
                    },
                    '& .selectAllContainer': {
                        display: 'inline-flex',
                        alignItems: 'center'
                    },
                    '& .checkBoxContainer .MuiCheckbox-root.Mui-checked svg': {
                        color: theme.palette.primary.main
                    },
                    '& .check-all-typo': {
                        color: '#595959',
                        fontSize: '12px!important',
                        fontWeight: '500!important',
                        lineHeight: '18px!important'
                    },
                    '& .MuiTableHead-root': {
                        '& .MuiTableRow-head': {
                            '& th': {
                                backgroundColor: '#D3EBE6',
                                color: '#00614E',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '18px',
                                textTransform: 'capitalize',
                                borderBottom: 0,
                                '&[data-test-id="check-box-head-col"]': {
                                    textAlign: 'right'
                                }
                            },
                            '& th:nth-child(1)': { width: '35%' },
                            '& th:nth-child(2)': { width: '15%' }
                        }
                    },
                    '&.manageUser-table .MuiTableHead-root': {
                        '& .MuiTableRow-head': {
                            '& th': {
                                backgroundColor: '#F2F1F1',
                                color: '#404040',
                                fontSize: '12px',
                                fontWeight: 600,
                                lineHeight: '20px',
                                borderBottom: 'none'
                            }
                        }
                    },
                    '& .MuiTableBody-root': {
                        '& .MuiTableRow-root': {
                            '& .userNameContainer': {
                                display: 'flex',
                                alignItems: 'center',
                                '& img, & .MuiCheckbox-root': {
                                    marginRight: '5px'
                                },
                                '& .user-name, & .admin': {
                                    color: theme.palette.grey[900],
                                    fontSize: '14px!important',
                                    fontWeight: '600!important',
                                    lineHeight: '22px!important'
                                },
                                '& .admin': {
                                    color: theme.palette.grey[500],
                                    marginLeft: '4px'
                                }
                            },
                            '& .MuiChip-root': {
                                boxShadow: 'none',
                                '&.chip-plan': {
                                    backgroundColor: '#D3EBE6',
                                    '& .MuiChip-label': {
                                        color: '#00614E',
                                        fontSize: '11px',
                                        fontWeight: 600,
                                        lineHeight: '16px'
                                    },
                                    '&.pro-user': {
                                        backgroundColor: '#2AAD93',
                                        '& .MuiChip-label': {
                                            color: '#fff'
                                        }
                                    }
                                },
                                '&.chip-status': {
                                    backgroundColor: 'rgba(217, 216, 216, 0.50)',
                                    marginRight: '6px',
                                    paddingLeft: '1rem',
                                    paddingRight: '1rem',
                                    '&.active': {
                                        backgroundColor: '#8C8C8C',
                                        '& .MuiChip-label': {
                                            color: '#fff'
                                        }
                                    },
                                    '&.active, &.not-active': {
                                        width: '95px'
                                    },
                                    '& .MuiChip-label': {
                                        color: '#595959',
                                        fontSize: '11px',
                                        fontWeight: 600,
                                        lineHeight: '16px'
                                    }
                                }
                            },
                            '& .statusContainer': {
                                display: 'flex',
                                alignItems: 'center',
                                '& .loading': {
                                    fontSize: '12px!important',
                                    color: '#8C8C8C!important',
                                    fontWeight: '500!important',
                                    lineHeight: '18px!important',
                                    marginLeft: '6px'
                                },
                                '& .statusTextContainer': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    '& > *': {
                                        padding: '0 5px'
                                    },
                                    '& .status-text': {
                                        color: '#737373',
                                        fontSize: '10.5px!important',
                                        fontWeight: '500!important',
                                        lineHeight: '16px!important'
                                    },
                                    '& .activate-zoho, & .deactivate-zoho, & .email-user': {
                                        fontSize: '12px!important',
                                        fontWeight: '600!important',
                                        lineHeight: '16px!important',
                                        height: '25px',
                                        '&:disabled': {
                                            cursor: 'not-allowed'
                                        }
                                    },
                                    '& .right-arrow-icon': {
                                        fontSize: '1rem'
                                    },
                                    '& .api-response-icon': {
                                        fontSize: '1rem',
                                        marginRight: '2px'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    dialogTitleContainer: {
        marginBottom: '5px'
    },
    dialogTitle: {
        fontSize: '20px!important',
        fontWeight: '600!important',
        lineHeight: '28px!important',
        letterSpacing: '-0.2px',
        color: theme.palette.grey[900]
    },
    dialogSubTitle: {
        fontSize: '14px!important',
        fontWeight: '400!important',
        lineHeight: '22px!important',
        letterSpacing: '-0.1px',
        color: theme.palette.grey[700]
    },
    dialogActionsContainer: {
        gap: '12px',
        '& .MuiButton-root': {
            lineHeight: '1rem'
        },
        '& .leftBtn, & .rightBtn': {
            borderRadius: '1rem'
        },
        '&.dialogActionsSkeletonContainer': {
            position: 'absolute',
            width: '100%',
            bottom: '40px'
        }
    },
    upgradeUserDialog: {
        '& .MuiPaper-root': {
            padding: '30px 40px 50px'
        },
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        }
    },
    upgradeUserDialogTitle: {
        color: '#1A1A1A',
        fontSize: '24px',
        fontStyle: 'normal',
        textAlign: 'center',
        fontWeight: 600,
        lineHeight: '34px',
        letterSpacing: '-0.2px',
        marginBottom: '10px'
    },
    upgradeUserDialogContentText: {
        color: '#1A1A1A',
        fontSize: '15px!important',
        fontStyle: 'normal!important',
        textAlign: 'center',
        fontWeight: '400!important',
        lineHeight: '23px!important',
        marginBottom: 0
    },
    checkCircleIcon: {
        color: '#088068',
        marginRight: '5px'
    },
    emailSent: {
        color: '#088068',
        fontSize: '14px!important',
        fontWeight: '600!important',
        lineHeight: '18px!important',
        letterSpacing: '-0.1px'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        padding: '.5rem'
    },
    rightArrowIcon: {
        fontSize: '1rem'
    },
    manageUserTablesHeadContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#D3EBE6',
        position: 'sticky',
        height: '45px',
        top: 0,
        zIndex: 101,
        '& svg': {
            fontSize: '1rem'
        },
        '& > :nth-child(1)': { width: '35%', padding: '12px 8px 12px 40px' },
        '& > :nth-child(2)': { width: '15%' },
        '& > div': {
            fontSize: '12px',
            padding: '12px 8px',
            color: '#00614E',
            fontWeight: 500,
            lineHeight: '18px'
        }
    },
    renderSkeletonContainer: {
        padding: '0!important'
    },
    skeletonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.5rem',
        marginTop: '.5rem',
        '& .skeleton': {
            position: 'relative',
            '& .skeletons': {
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                width: '100%',
                height: '100%',
                '& > .user': {
                    padding: '18px 8px 18px 40px',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '.5rem',
                    width: '35%',
                    '& > .userName': {
                        alignSelf: 'normal'
                    }
                },
                '& > .plan': {
                    width: '15%',
                    padding: '18px 8px',
                    '& .planBtn': {
                        borderRadius: '1rem'
                    }
                },
                '& > .status': {
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '.75rem',
                    padding: '18px 8px',
                    '& .statusBtn': {
                        borderRadius: '1rem'
                    },
                    '& .text1': {
                        marginBottom: '-.25rem'
                    }
                }
            }
        }
    },
    skeletonUsers: {
        padding: '14.5px 8px 12.5px 40px',
        backgroundColor: '#F2F1F1',
        color: '#404040',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left'
    }
}))
