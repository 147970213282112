
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from 'foundations-library/components'
import { useHistory } from 'react-router-dom'
import { getVerbalColors } from 'helpers'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            backgroundColor: verbalColors['neutral-50'],
            height: '54px',
            alignItems: 'center',
            paddingLeft: '10px',
            gap: '12px',
            '& .delimiter': {
                width: '1px',
                height: '24px',
                backgroundColor: verbalColors['neutral-100']
            }
        }
    }
})

/**
 *
 */
const GoBack = ({ title }: { title: string }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className={classes.root}>
            <IconButton name='back' variant='icon' color='neutral' size='small' onMouseUp={() => history.goBack()} data-testid='back-btn' />
            <div className='delimiter' />
            <Typography variant='h8' color='neutral-900'>{title}</Typography>
        </div>
    )
}

export default GoBack
