/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React from 'react'
import { Button } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import CardDetails from './CardDetails'
import { PaymentMethodType } from 'interfaces'

interface CardDetailsProps {
    primaryCard: PaymentMethodType,
    routeProps?: any
    setActiveTab(v): any;
}

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            margin: 'auto',
            width: '440px',
            height: '185px',
            borderRadius: '6px',
            padding: '20px',
            border: `1px solid ${colors.neutral[100]}`,
            '@media (max-width: 600px)': {
                marginTop: '15px',
                width: '100%'
            },
            '& .actionButtons': {
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                alignItems: 'flex-end',
                borderTop: `1px solid ${colors.neutral[100]}`,
                paddingTop: '16px'
            }
        }
    }
}
const useStyles = makeStyles(style)

/**
 *
 */
const PaymentMethodCard = ({
    primaryCard,
    routeProps,
    setActiveTab
}: CardDetailsProps): JSX.Element => {
    const classes = useStyles()
    const pushRoute = newTab => routeProps.history.push(`${routeProps.match.path}/${newTab}`)

    return (
        <div className={classes.container}>
            <CardDetails primaryCard={primaryCard}/>
            <div className={'actionButtons'}>
                <Button
                    onClick={() => { pushRoute('payment-methods'); setActiveTab && setActiveTab('payment-methods') }}
                    color={'primary'}
                    size={'small'}
                    variant={'outlined'}
                    icon={'go'}
                    data-testid='add-new-card-button'
                >
                Add new card</Button>
            </div>
        </div>
    )
}

/**
 *
 */
export { style }

export default PaymentMethodCard
