import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'
import { DialogType, FormButtonsType } from 'functional-foundations/types'
import { Dialog, FormButtons } from 'functional-foundations'

const jss = (theme) => {
    const { colors } = theme['foundations-library']

    return {
        title: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '-0.4px',
            marginBottom: '15px',
            color: colors['neutral-900'],
            textAlign: ({ centerTitle }) => centerTitle && 'center'
        },
        checkbox: {
            marginBottom: '4px'
        }
    }
}

const useStyles = makeStyles(jss)

type PropsInterface = {
    values?: Record<string, any>,
    onConfirm?(): any,
    centerTitle?: boolean
} & DialogType & FormButtonsType

/**
 *
 */
const CommonDialog = ({
    children,
    values,
    isOpen = false,
    onClose,
    onConfirm,
    confirmProps,
    centerTitle,
    disableConfirm,
    'data-testid': dataTestId = 'common-dialog'
}: PropsWithChildren<PropsInterface>): JSX.Element => {
    const styles = useStyles({ centerTitle })
    return <Dialog width={520} isOpen={isOpen} data-testid={dataTestId}>
        {values && (
            <div className={styles.title} data-testid={`${dataTestId}-title`}>
                {values.title}
            </div>
        )
        }

        {children}

        <FormButtons
            onCancel={onClose}
            onConfirm={onConfirm}
            confirmText={values?.confirm_text}
            cancelText={values?.cancel_text}
            confirmProps={confirmProps}
            disableConfirm={disableConfirm}
        />

    </Dialog>
}

export default CommonDialog
