import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Select, Button, Alert } from 'foundations-library/components'
import api from 'api'
import Spinner from 'spinner'
import { users as propUsers } from './../../../utils/objects'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import TerminateSimBtn from './TerminateSimBtn'
import { formatPhoneNumber } from 'phone-numbers'
import { makePromise } from './../../../utils/index'
import { details } from '../../../utils/objects'
import { getVerbalColors } from 'helpers'
import DropdownWithInfoTip from './DropdownWithInfoTip'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: '1rem',
            border: `1px solid ${verbalColors['neutral-200']}`,
            borderTop: 'none',
            borderRadius: '0 0 10px 10px',
            padding: '30px 30px 52px 20px',
            '& .select-dropdown-container': {
                display: 'flex',
                columnGap: '.5rem'
            },
            '& .btn-container': {
                display: 'flex',
                columnGap: '1rem'
            },
            '& .user-dropdown': {
                '& [data-testid="select-select-toggle"] .ext-number': {
                    display: 'none'
                },
                '& .select-menu': {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '.5rem'
                }
            }
        }
    }
})

/**
 *
 */
const SelectAssignee = () => {
    const classes = useStyles()
    const [isLoadingExtensions, setIsLoadingExtensions] = useState(false)
    const { selectedUser, setSelectedUser, selectedPhone, setSelectedPhone, phones, setPhones, handleOpenAssigneeDialog, teammates } = useEsimOperationsContext()

    const [users, setUsers] = useState<Record<string, any>[]>(propUsers)
    const [hasMoreUsers, setHasMoreUsers] = useState(true)

    let lastUserObserver: IntersectionObserver

    const loadUsers = async () => {
        const limit = 20
        const offset = users.length
        const newUsers = await api.loadUsers(limit, offset)
        setUsers([...users, ...newUsers])
        setHasMoreUsers(newUsers.length === limit)
    }

    const userDropdownOpened = () => {
        if (hasMoreUsers) {
            lastUserObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                const lastUser = entries[0]
                if (!lastUser.isIntersecting) return
                loadUsers()
            }, {
                root: document.querySelector('.select-menu'),
                rootMargin: '150px'
            })
            makePromise<string>('.select-menu > div:last-child').then(selector => {
                const lastUser = document.querySelector(selector as string)
                if (lastUser) lastUserObserver.observe(lastUser)
            })
        }
    }

    const loadPhoneNumbers = async (extension_id) => {
        setIsLoadingExtensions(true)
        const { esim: phoneNumbers } = await api.getUserPhoneNumbersForEsim(extension_id)
        setPhones(phoneNumbers)
        setIsLoadingExtensions(false)
    }

    const handleSelectUser = async (user: Record<string, any>) => {
        setSelectedUser(user)
        setSelectedPhone('')
        // handleOpenCompabilityDialog()
        await loadPhoneNumbers(user.extension.id)
        if (lastUserObserver) lastUserObserver.disconnect()
    }

    const renderSelectOption = (ext: Record<string, any>) => (
        <div>
            <Typography variant='body2' className='ext-name'>{ext.name}</Typography>
            <Typography variant='body2' className='ext-number'>Ext {ext.number}</Typography>
        </div>
    )

    const handleSelectPhone = (phone: string) => setSelectedPhone(phone)

    return (
        <div className={classes.root}>
            <Typography variant='subtitle1' color='neutral-900'>{details.assignee}</Typography>
            {/* <Select onOpen={userDropdownOpened} onChange={handleSelectUser} returnObject={true} options={[...users.map(user => ({ ...user, value: user.id, text: `Ext ${user.voip_phone_id}: ${user.first_name} ${user.last_name}` })), { value: 'spinner', text: <Spinner /> }]} title={details.selectUser} /> */}
            <DropdownWithInfoTip className='user-dropdown' onOpen={userDropdownOpened} onChange={handleSelectUser} returnObject={true} options={[...Array.from(teammates.items, ([key, value]) => ({ ...value })).map(user => ({ ...user, value: user.id, text: renderSelectOption(user.extension) }))]} title={details.selectUser} />
            {/* <Select disabled={!selectedUser} onChange={handleSelectPhone} options={selectedUser?.phones?.map((phone: string) => ({ value: phone, text: phone }))} title={details.selectPhone} /> */}
            <div className='select-dropdown-container'>
                <Select value={selectedPhone} disabled={!selectedUser || (selectedUser && !(phones.filter((extension: Record<string, any>) => extension.assigned !== null).length)) || isLoadingExtensions} onChange={handleSelectPhone} options={phones.filter((extension: Record<string, any>) => extension.assigned !== null).map((extension: Record<string, any>) => ({ value: extension.number, text: formatPhoneNumber(extension.number) }))} title={details.selectPhone} />
                {isLoadingExtensions && <Spinner color='#8C8C8C' />}
            </div>
            {(selectedUser && !isLoadingExtensions && !phones.length) && <Alert level='error' icon={true}>No Phone Numbers are available for selected extension</Alert>}
            <div className='btn-container'>
                <Button size='medium' color='primary' variant='contained' onClick={handleOpenAssigneeDialog} disabled={!selectedUser || !selectedPhone}>{details.assignButton}</Button>
                <TerminateSimBtn size='medium' />
            </div>
        </div>
    )
}

export default SelectAssignee
