/* eslint-disable jsdoc/require-description */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable react/prop-types */
import React from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

/**
 *
 */
export default function PDCMarkdown (props) {
    return (
        <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
            {props.children}
        </Markdown>
    )
}
