import React, { useContext } from 'react'
import { ZohoUsersContext } from 'providers'
import { SelectUsersToUpgradeProvider } from './src/utils/context'
import { UserModalInterface } from './src/utils/objects'
import { getPlusBasicUserIds } from './src/utils/helpers'
import UserModal from './src/UserModal'

/**
 *
 */
const Index = (props: UserModalInterface) => {
    const { users } = useContext(ZohoUsersContext)

    return (
        <SelectUsersToUpgradeProvider key='selectUsersToUpgradeProvider' allUserIds={getPlusBasicUserIds(users)}>
            <UserModal {...props} />
        </SelectUsersToUpgradeProvider>
    )
}

export default Index
