import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import PDCMarkdown from 'pdc-markdown'
import MuiDialog from '@material-ui/core/Dialog'
import { useAppContext } from './../../../../../contexts/AppContext'
import { Picture } from './../../../../../components/Media'
import { HowToVideo } from './../../../../../utils/objects'
import { Video } from './../../../../../components/Media'
import howToImage1 from './../../../../../images/howToImage1.jpg'
import howToImage2 from './../../../../../images/howToImage2.jpg'
import howToImage3 from './../../../../../images/howToImage3.jpg'
import howToImage4 from './../../../../../images/howToImage4.jpg'
import howToImage5 from './../../../../../images/howToImage5.jpg'
import howToImage6 from './../../../../../images/howToImage6.jpg'

/**
 *
 */
export const items = {
    videos: [
        {
            sources: [
                {
                    src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    type: 'video/mp4'
                }
            ],
            thumbnail: howToImage1,
            info: '##### **Setting Phone.com as your cellular line on iPhone**\nLorem ipsum et dolor sit emet. Lorem ipsume et dolor sit emet. Lorem ipsum et dolor sit emet'
        },
        {
            sources: [
                {
                    src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    type: 'video/mp4'
                }
            ],
            thumbnail: howToImage2,
            info: '##### **Activate your cellular service on iPhone**\nLorem ipsum et dolor sit emet. Lorem ipsume et dolor sit emet. Lorem ipsum et dolor sit emet'
        },
        {
            sources: [
                {
                    src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    type: 'video/mp4'
                }
            ],
            thumbnail: howToImage3,
            info: '##### **Assigning cellular service to a user in MyPDC**\nLorem ipsum et dolor sit emet. Lorem ipsume et dolor sit emet. Lorem ipsum et dolor sit emet'
        },
        {
            sources: [
                {
                    src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    type: 'video/mp4'
                }
            ],
            thumbnail: howToImage4,
            info: '##### **Troubleshooting your cellular service on iPhone**\nLorem ipsum et dolor sit emet. Lorem ipsume et dolor sit emet. Lorem ipsum et dolor sit emet'
        },
        {
            sources: [
                {
                    src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    type: 'video/mp4'
                }
            ],
            thumbnail: howToImage5,
            info: '##### **Setting Phone.com as your cellular line on iPhone**\nLorem ipsum et dolor sit emet. Lorem ipsume et dolor sit emet. Lorem ipsum et dolor sit emet'
        },
        {
            sources: [
                {
                    src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    type: 'video/mp4'
                }
            ],
            thumbnail: howToImage6,
            info: '##### **Check phone compatibility for eSIM**\nLorem ipsum et dolor sit emet. Lorem ipsume et dolor sit emet. Lorem ipsum et dolor sit emet'
        }
    ]
}

const useStyles = makeStyles(theme => ({
    videosRoot: {
        rowGap: '.5rem'
    },
    title: { margin: 'auto', padding: '1.5rem 0 3rem' },
    videosContainer: {
        '& .video-container': {
            rowGap: '25px',
            '& img': {
                cursor: 'pointer',
                width: '100%',
                objectFit: 'contain',
                borderRadius: '20px',
                boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.16)'
            }
        }
    },
    videoDialog: {
        '& [role=dialog]': {
            padding: 0,
            '& video': {
                borderRadius: '20px'
            }
        }
    },
    viewMoreBtn: { margin: '1rem auto 0' }
}))

const VideoItem = ({ video }: { video: HowToVideo }) => {
    const classes = useStyles()
    const [openVideo, setOpenVideo] = useState(false)
    const { app } = useAppContext()

    return (
        <Grid item xs={12} sm={6} md={4} container direction='column' wrap='nowrap' className='video-container'>
            <Picture sources={[{ src: video.thumbnail }]} attributes={{ onClick: () => setOpenVideo(true) }} />
            <Box>
                <PDCMarkdown>
                    {video.info}
                </PDCMarkdown>
            </Box>
            <MuiDialog
                open={openVideo}
                onClose={() => setOpenVideo(false)}
                aria-labelledby="video-dialog"
                className={classes.videoDialog}
            >
                <Video sources={video.sources} attributes={{ autoPlay: true }} app={app} />
            </MuiDialog>
        </Grid>
    )
}

/**
 *
 */
export const Videos = ({ videos }: { videos: HowToVideo[] }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.videosContainer} spacing={3}>
            {
                videos.map(item => <VideoItem key={item.thumbnail} video={item} />)
            }
        </Grid>
    )
}

/**
 *
 */
const Index = () => {
    const classes = useStyles()

    return (
        <Grid container xs={12} className={classes.videosRoot}>
            <Typography variant='h2' className={classes.title}>How-to-videos</Typography>
            <Videos videos={items.videos} />
            <Button variant='outlined' size='large' color='secondary' className={classes.viewMoreBtn}>View more</Button>
        </Grid>
    )
}

Index.displayName = 'Videos'

export default Index
