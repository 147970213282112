export default /**
eeeeeeeeeeeeeee *
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee */
(t) => {
    const { colors, typography } = t['foundations-library']

    return {
        textarea: {
            height: '44px',
            resize: 'none',
            overflow: 'hidden',
            lineHeight: '22px',
            // position: 'absolute',
            left: 0,
            right: 0,
            '&::placeholder': {
                color: colors.neutral[300]
            }
        },
        wrapper: {
            '&.input-wrapper ': {
                height: 'auto !important'
            }
        },
        maxLength: {
            position: 'absolute',
            right: '14px',
            bottom: '-13px',
            backgroundColor: 'white',
            paddingLeft: '4px',
            paddingRight: '4px',
            ...typography.label,
            color: colors.neutral[500],
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.1px'
        },
        maxLengthValue: {
            color: colors.neutral[500]
        },
        maxLengthLimit: {
            color: colors.neutral[300]
        }
    }
}
