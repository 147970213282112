import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { App } from '../../../utils/objects'
import { Tabs, Tab } from 'tabs'
import TabMarkdown from './TabMarkdown'
import OverviewTab from './OverviewTab'
import FAQTab from './FAQTab'
import HowTo from './HowTo'

const useStyles = makeStyles(theme => ({
    tabsRoot: {
        width: '100%',
        backgroundColor: 'rgba(170, 219, 160, 0.50)',
        borderRadius: '30px',
        minHeight: '40px',
        height: '46px',
        border: 'none',
        padding: '4px 5px',
        "& [role='tablist']": {
            overflowX: 'auto',
            columnGap: '1rem',
            height: '100%'
        },
        '& .Mui-selected': {
            color: '#fff',
            backgroundColor: '#1C9980',
            borderRadius: '30px'
        }
    },
    tabRoot: {
        minHeight: '30px',
        height: '100%',
        color: '#30991C',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '1rem'
    },
    tabDetailsContainer: {
        padding: '2rem 1rem',
        '& :is(h1, h2, h3, h4, h5, h6)': {
            fontWeight: 'initial'
        },
        '& strong': {
            fontWeight: '600!important'
        }
    }
}))

/**
 *
 */
const ContentTabs = ({ app }: { app: App }) => {
    const classes = useStyles()
    const [tab, setTab] = useState(0)
    const [tabsList, setTabsList] = useState([])
    const [tabsListContent, setTabsListContent] = useState([])
    const getContent = (tab) => {
        const type = tab.type || 'markdown'
        const content = tab.content
        switch (type) {
                case 'markdown':
                    return <TabMarkdown>{content}</TabMarkdown>
                case 'component':
                    return getComponent(tab.content.componentName)
                default:
                    return <></>
        }
    }

    const getComponent = (componentName) => {
        switch (componentName) {
                case 'overview':
                    return <OverviewTab app={app} />
                case 'faq':
                    return <FAQTab app={app} />
                case 'howTo':
                    return <HowTo app={app} />
                default:
                    return <></>
        }
    }

    const setTabs = (tabs) => {
        const tabNames = []
        const tabContents = []
        tabs.map((tab, index) => {
            tabNames.push(tab.name)

            tabContents.push(getContent(tab))
            return null
        }
        )
        setTabsList(tabNames)
        setTabsListContent(tabContents)
    }

    useEffect(() => {
        // loop through tabs and set name and content
        app.details.tabs && setTabs(app.details.tabs)
    }, [])

    const renderTab = () => {
        const content = tabsListContent[tab]
        const component = content || <></>
        return component
    }

    return (
        (tabsList.length > 0)
            ? <>
                <Tabs
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                    classes={{ root: classes.tabsRoot }}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    variant="fullWidth"
                >
                    {tabsList.map((tabName, index) => (
                        <Tab classes={{ root: classes.tabRoot }} label={tabName} key={index} />
                    ))}
                </Tabs>
                <Box className={classes.tabDetailsContainer}>
                    {renderTab()}
                </Box>
            </>
            : <></>
    )
}

export default ContentTabs
