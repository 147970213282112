import React, { useContext, useEffect, useMemo, useState } from 'react'
import { EsimContext } from 'providers'
import { getValue } from 'firebase-utils'
import { makeStyles } from '@material-ui/core'
import { Dialog, FormButtons } from 'functional-foundations'
import { Typography, Checkbox, Textarea, Loader } from 'foundations-library/components'
import CommonDialog from './CommonDialog'
import UserNameAndNumber from './UserNameAndNumber'
import { useHistory, useParams } from 'react-router-dom'
import { useEsimOperationsContext } from '../../../contexts/EsimOperationsContext'
import { formatPhoneNumber } from 'phone-numbers'
import { getPhoneCom } from 'phonecom'
import ErrorDialog from '../ErrorDialog'

const jss = () => {
    return {
        finalActionWarning: {
            marginTop: '20px',
            marginBottom: '10px'
        },
        loader: {
            marginTop: '80px',
            marginBottom: '40px'
        },
        loadingText: {
            marginBottom: '96px',
            textAlign: 'center'
        },
        '.input-wrapper': {
            width: '100%'
        },
        mb20: {
            marginBottom: '20px'
        },
        mb15: {
            marginBottom: '15px'
        },
        mb10: {
            marginBottom: '10px'
        },
        '.full-width, .full-width .input-wrapper': {
            width: '100% !important'
        },
        textCenter: {
            textAlign: 'center'
        }
    }
}
const useStyles = makeStyles(jss)

const getValues = (keys: string[]) => {
    const defaultValues = {
        confirm_text: 'Proceed',
        cancel_text: 'Cancel'
    }
    const data: Record<string, any>[] = []
    let values = getValue('terminate_esim')

    if (typeof values === 'string') {
        values = JSON.parse(values)
    }

    for (const key of keys) {
        data.push({
            ...defaultValues,
            ...values[key]
        })
    }
    return data
}

const BoldDynamicRemoteConfigText = ({
    text,
    nidle,
    bold,
    'data-testid': dataTestId,
    ...props
}: {
    text: string,
    nidle: string,
    bold: string,
    'data-testid': string
}) => {
    const parts = text.split(nidle)
    if (parts.length > 1) {
        const restOfTheText = parts.slice(1).join('')
        return <Typography {...props} data-testid={dataTestId}>
            {parts[0]}<Typography variant='body2heavy' tag='span'>{bold}</Typography>{restOfTheText}
        </Typography>
    }

    return <Typography {...props} data-testid={dataTestId}>{text}</Typography>
}

interface PropsInterface {
    start: boolean
    forceQuit: boolean,
    onClose: () => any
}
type ReasonType = {text: string, checked: string}
type DataType = { reasons: ReasonType[], improvementsNote: string, confirmed: boolean }

/**
 *
 */
const TermianteSimProcess = ({
    start = false,
    forceQuit = false,
    onClose
}: PropsInterface): JSX.Element => {
    const styles = useStyles()

    const { fetchEsims, terminateEsimHandler, terminateEsim, reset } = useContext(EsimContext)
    const { esimId } = useParams<{ esimId: string }>()
    const history = useHistory()

    const [reasonsDialogIsOpen, setReasonsDialogIsOpen] = useState(false)
    const [improvementsDialogIsOpen, setImprovementsDialogIsOpen] = useState(false)
    const [finalActionDialogIsOpen, setFinalActionDialogIsOpen] = useState(false)
    const [submittedDialogIsOpen, setSubmittedDialogIsOpen] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [data, setData] = useState<DataType>({
        reasons: [],
        improvementsNote: '',
        confirmed: false
    })

    const { selectedUser, selectedPhone } = useEsimOperationsContext()

    const { userName, userPhoneNumber } = useMemo(() => {
        let userName
        if (selectedUser?.extension) {
            userName = `Ext ${selectedUser.extension.number}: ${selectedUser.extension.name}`
        } else if (selectedUser?.first_name || selectedUser?.last_name) {
            userName = [selectedUser?.first_name, selectedUser?.last_name].filter((x) => x).join(' ')
        }

        return {
            userName,
            userPhoneNumber: formatPhoneNumber(selectedPhone)
        }
    }, [selectedUser])

    const closeAllDialogs = () => {
        setReasonsDialogIsOpen(false)
        setImprovementsDialogIsOpen(false)
        setFinalActionDialogIsOpen(false)
        setSubmittedDialogIsOpen(false)
        onClose && onClose()
    }

    useEffect(() => {
        getPhoneCom().then((res) => setUserEmail(res.email))
    }, [])

    useEffect(() => {
        if (terminateEsim.error) closeAllDialogs()
    }, [terminateEsim.error])

    useEffect(() => {
        if (start) setReasonsDialogIsOpen(true)
        setData({
            ...data,
            confirmed: false
        })
    }, [start])

    useEffect(() => {
        if (forceQuit) closeAllDialogs()
    }, [forceQuit])

    const reasonsDialogOnConfirm = () => {
        setReasonsDialogIsOpen(false)
        setImprovementsDialogIsOpen(true)
    }

    const improvementsDialogOnConfirm = () => {
        setImprovementsDialogIsOpen(false)
        setFinalActionDialogIsOpen(true)
    }

    const finalActionDialogOnConfirm = async () => {
        const reasons = data.reasons.filter((x) => x.checked).map((x) => x.text)
        const res = await terminateEsimHandler(esimId, reasons, data.improvementsNote)
        if (res && !terminateEsim.error) {
            fetchEsims(false, true)
        }
        setFinalActionDialogIsOpen(false)
        setSubmittedDialogIsOpen(true)
    }

    const lastDayOfTheBillingCycle = useMemo(() => {
        return 'THIS IS FAKE DATE'
        // const currentDate = new Date()
        // const lastDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

        // const options = { year: 'numeric', month: 'short', day: '2-digit' }
        // return lastDayDate.toLocaleDateString('en-US', options)
    }, [])

    const [reasonsValues, improvementsValues, finalActionValues, submittedDialog] = useMemo(() => {
        const values = getValues([
            'reasons_dialog',
            'improvements_dialog',
            'final_action_dialog',
            'submitted_dialog'
        ])
        const defaultReasons = [...values[0].reasons]
        setData({
            ...data,
            reasons: defaultReasons
        })
        return values
    }, []
    )

    const toggleReason = (text) => {
        setData({
            ...data,
            reasons: data.reasons.map((x) => {
                if (x.text === text) {
                    x.checked = !x.checked
                }

                return x
            })
        })
    }

    const closeAndGoBack = () => {
        closeAllDialogs()
        setTimeout(() => {
            history.goBack()
        }, 500)
    }

    const handleOnErrorDialogClose = () => {
        reset('TERMINATE_ESIM')
        onClose && onClose()
        setTimeout(() => {
            history.goBack()
        }, 100)
    }

    return <>
        {/* reasons */}
        <CommonDialog
            values={reasonsValues}
            isOpen={reasonsDialogIsOpen}
            onConfirm={reasonsDialogOnConfirm}
            onClose={closeAllDialogs}
            data-testid='reasons-dialog'
        >
            <Typography variant='body2' className={styles.mb15} data-testid='reasons-text'>
                {reasonsValues.text}
            </Typography>
            <Typography variant='subtitle2' color='neutral-700' className={styles.mb10} data-testid='reasons-checkboxes-title'>
                {reasonsValues.checkboxesTitle}
            </Typography>
            {
                Array.isArray(reasonsValues.reasons) &&
                reasonsValues.reasons.map(
                    ({ text, checked }, i) => (
                        <Checkbox
                            value={checked}
                            onChange={() => toggleReason(text)}
                            className={styles.mb10}
                            checked={checked}
                            label={text}
                            key={`${text}-${checked}`}
                            data-testid={`reason-${i}`}
                        />
                    )
                )
            }
        </CommonDialog>

        {/* improvements */}
        <CommonDialog
            values={improvementsValues}
            isOpen={improvementsDialogIsOpen}
            onConfirm={improvementsDialogOnConfirm}
            onClose={closeAllDialogs}
            data-testid='improvements-dialog'
        >
            <Typography variant='body2' color='neutral-700' className={styles.mb15} data-testid='improvements-text'>
                {improvementsValues.text}
            </Typography>
            <Textarea
                className='full-width'
                rows={4}
                maxLength={200}
                placeholder={improvementsValues.placeholder}
                value={data.improvementsNote}
                onChange={(v) => setData({ ...data, improvementsNote: v })}
                data-testid='improvements-textarea'
            />
        </CommonDialog>

        {/* final action */}
        <CommonDialog
            values={terminateEsim.isLoading ? undefined : finalActionValues}
            isOpen={finalActionDialogIsOpen}
            onConfirm={terminateEsim.isLoading ? undefined : finalActionDialogOnConfirm}
            onClose={terminateEsim.isLoading ? undefined : closeAllDialogs}
            confirmProps={{ color: 'destructive', loading: terminateEsim.isLoading }}
            cancelProps={{ disabled: terminateEsim.isLoading }}
            disableConfirm={!data.confirmed || terminateEsim.isLoading}
            centerTitle
            key={`${terminateEsim.isLoading}-${data.confirmed}`}
            data-testid='final-action-dialog'
        >
            {terminateEsim.isLoading && <div>
                <Loader size={80} className={styles.loader} inlineBlock/>
                <div className={styles.loadingText}>
                    <Typography variant='h6' color='neutral-900' className={styles.mb10}>
                        {finalActionValues.loading_text}
                    </Typography>
                    <Typography variant='body1' color='neutral-900'>{finalActionValues.loading_small_text}</Typography>
                </div>
            </div>}
            {!terminateEsim.isLoading && <div>
                <BoldDynamicRemoteConfigText text={finalActionValues.text} nidle='__TIME__' bold={lastDayOfTheBillingCycle} data-testid='final-text'/>
                <Typography variant='body2' color='destructive-500' align='center' className={styles.finalActionWarning} data-testid='warning-text'>
                    {finalActionValues.warning_text}
                </Typography>
                <UserNameAndNumber name={userName} number={userPhoneNumber}/>
                <Checkbox
                    checked={data.confirmed}
                    onChange={(v) => setData({ ...data, confirmed: v })}
                    className={styles.mb20}
                    disabled={terminateEsim.isLoading}
                    data-testid='final-confirm'
                >
                    <BoldDynamicRemoteConfigText
                        text={finalActionValues.confirm_checkbox_text}
                        bold={finalActionValues.confirm_checkbox_text_bold}
                        nidle={finalActionValues.confirm_checkbox_text_bold}
                        data-testid='final-confirm-text'
                    /></Checkbox>
            </div>
            }
        </CommonDialog>

        {/* confirmed */}
        <Dialog
            isOpen={submittedDialogIsOpen}
            title={submittedDialog.title}
            titleAlign='center'
            closeOnClickOutside={!terminateEsim.isLoading}
            data-testid='submitted-successfully-dialog'
        >
            <div className={styles.textCenter}>
                <BoldDynamicRemoteConfigText
                    text={submittedDialog.text}
                    bold={lastDayOfTheBillingCycle}
                    nidle={'__TIME__'}
                />
                <UserNameAndNumber name={userName} number={userPhoneNumber} />
                <Typography>{submittedDialog.confirmation_mail_sent_to_text}</Typography>
                <Typography
                    variant='subtitle2'
                    color='secondary-500'
                    className={styles.mb20}
                >
                    {userEmail}
                </Typography>
                <FormButtons
                    onCancel={closeAndGoBack}
                    cancelText={submittedDialog.cancel_text}
                    disableConfirm={terminateEsim.isLoading}
                />
            </div>
        </Dialog>
        <ErrorDialog isOpen={!!terminateEsim.error} onClose={handleOnErrorDialogClose}>{terminateEsim.error}</ErrorDialog>
    </>
}

export default TermianteSimProcess
