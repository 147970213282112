/* eslint-disable @typescript-eslint/naming-convention */
import SwapVertIcon from '@material-ui/icons/SwapVert'

/**
 *
 */
export const activeStatus = 'Active'
/**
 *
 */
export const notActiveStatus = 'Not active'
/**
 *
 */
export const texts = {
    heading: 'Zoho & Phone.com User Access',
    subHeading: 'Users on Pro plans can integrate Zoho and Phone.com accounts instantly.',
    proUser: {
        activeStatus: activeStatus,
        notActiveStatus: notActiveStatus,
        statusNotActiveText: 'Sign in to Zoho CRM to get started',
        signInBtn: 'Sign in to Zoho to get started'
    },
    plusBasicUser: {
        status: 'Upgrade required',
        statusUpgrade: 'Only users on the Phone.com PRO plan can integrate with Zoho CRM',
        statusEmail: 'You emailed your admin requesting an upgrade to your plan',
        emailBtn: 'Email Administrator',
        emailSent: 'Email notification sent'
    },
    manageProUser: {
        activeStatus: activeStatus,
        notActiveStatus: notActiveStatus,
        deactivateZohoCaption: 'Users and admins can deactivate access',
        deactivateZoho: 'Deactivate Zoho',
        notifyEmailCaption: 'Notify {user_name} to activate Zoho CRM',
        notifyEmail: 'Email user',
        activateZoho: 'Activate Zoho',
        deactivationSucceed: 'User deactivated successfully!',
        deactivationFailed: 'User failed to deactivate',
        emailSucceed: 'Email sent successfully!',
        emailFailed: 'Email failed to send',
        emailNotAvailable: 'No email associated with this user'
    },
    managePlusBasicUser: {
        checkAll: 'Select all',
        statusUpgrade: 'Upgrade required',
        statusUpgradeLoading: 'Upgrading',
        statusUpgradeFinishing: 'Finishing',
        upgradeToProBtn: 'Upgrade selected users to PRO',
        shouldUpgradeUsersText: 'Continue upgrading selected users to PRO?',
        upgradeUsersBillText: ' Additional charges will apply and be reflected on your next bill.',
        upgradeUsersBtn: 'Upgrade selected users'
    }
}

/**
 *
 */
export const manageUserTablesHead = [
    { content: 'All users', testId: 'allUsers-col' },
    { content: 'Plan', icon: SwapVertIcon, testId: 'plan-col' },
    { content: 'Status', testId: 'status-col' }
]

/**
 *
 */
export const plusBasicUserTablesHead = [
    { content: 'Name', testId: 'name-col' },
    { content: 'Plan', testId: 'plan-col' },
    { content: 'Status', testId: 'status-col' }
]

/**
 *
 */
export const proUsers: Record<string, any>[] = [
    {
        id: 1,
        first_name: 'First',
        last_name: 'User',
        // plan: 'Pro',
        // integrationActivated: true,
        is_admin: false,
        user_plan_id: 3,
        email: 'firstUser@phone.com',
        relationships: {
            extension: { data: { id: 11 } },
            plan: { data: { id: 3, plan_description: 'Pro' } }
        }
    },
    {
        id: 2,
        first_name: 'Second',
        last_name: 'User',
        // plan: 'Pro',
        // integrationActivated: true,
        is_admin: false,
        user_plan_id: 3,
        email: 'secondUser@phone.com',
        relationships: {
            extension: { data: { id: 12 } },
            plan: { data: { id: 3, plan_description: 'Pro' } }
        }
    },
    {
        id: 3,
        first_name: 'Third',
        last_name: 'User',
        // plan: 'Pro',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 3,
        email: 'thirdUser@phone.com',
        relationships: {
            extension: { data: { id: 13 } },
            plan: { data: { id: 3, plan_description: 'Pro' } }
        }
    },
    {
        id: 4,
        first_name: 'Fourth',
        last_name: 'User',
        // plan: 'Pro',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 3,
        email: 'fourthUser@phone.com',
        relationships: {
            extension: { data: { id: 14 } },
            plan: { data: { id: 3, plan_description: 'Pro' } }
        }
    },
    {
        id: 5,
        first_name: 'Fifth',
        last_name: 'User',
        // plan: 'Pro',
        // integrationActivated: false,
        is_admin: true,
        user_plan_id: 3,
        email: 'fifthUser@phone.com',
        relationships: {
            extension: { data: { id: 15 } },
            plan: { data: { id: 3, plan_description: 'Pro' } }
        }
    }
]

/**
 *
 */
export const plusBasicUsers: Record<string, any>[] = [
    {
        id: 6,
        first_name: 'Sixth',
        last_name: 'User',
        // plan: 'Basic',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 1,
        email: 'sixthUser@phone.com',
        relationships: {
            extension: { data: { id: 16 } },
            plan: { data: { id: 1, plan_description: 'Basic' } }
        }
    },
    {
        id: 7,
        first_name: 'Seventh',
        last_name: 'User',
        // plan: 'Basic',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 1,
        email: 'seventhUser@phone.com',
        relationships: {
            extension: { data: { id: 17 } },
            plan: { data: { id: 1, plan_description: 'Basic' } }
        }
    },
    {
        id: 8,
        first_name: 'Eighth',
        last_name: 'User',
        // plan: 'Plus',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 2,
        email: 'eighthUser@phone.com',
        relationships: {
            extension: { data: { id: 18 } },
            plan: { data: { id: 2, plan_description: 'Plus' } }
        }
    },
    {
        id: 9,
        first_name: 'Ninth',
        last_name: 'User',
        // plan: 'Plus',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 2,
        email: 'ninthUser@phone.com',
        relationships: {
            extension: { data: { id: 19 } },
            plan: { data: { id: 2, plan_description: 'Plus' } }
        }
    },
    {
        id: 10,
        first_name: 'Tenth',
        last_name: 'User',
        // plan: 'Plus',
        // integrationActivated: false,
        is_admin: false,
        user_plan_id: 2,
        email: 'tenthUser@phone.com',
        relationships: {
            extension: { data: { id: 20 } },
            plan: { data: { id: 2, plan_description: 'Plus' } }
        }
    }
]

/**
 *
 */
export const basicUserInfo = {
    stage: 'PROD',
    v4ApiRoot: 'https://api.phone.com/v4',
    v5ApiRoot: 'https://preprod.my.phone.com/api/services',
    v5ToolsRoot: 'https://preprod.my.phone.com/api/tools',
    cp_token: 'abcdef',
    ac_token: 'abcdefgh',
    company: 'United People',
    extension: 601,
    features: {},
    extensions: [],
    true_account_id: 1857254,
    user_default_extension_id: 4027332,
    voip_id: '5464474',
    voip_phone_id: 4027553
}

/**
 *
 */
export const proUser = {
    user_id: 1,
    first_name: 'Pro',
    last_name: 'User',
    email: 'proUser@phone.com',
    role: 'account',
    user_account_type: 'pro',
    ...basicUserInfo
}

/**
 *
 */
export const plusUser = {
    user_id: 2,
    first_name: 'Plus',
    last_name: 'User',
    email: 'plusUser@phone.com',
    role: 'extension',
    user_account_type: 'plus',
    ...basicUserInfo
}

/**
 *
 */
export const manageProUserTableDataAttr = 'zoho-manage-proUser-access-table'
/**
 *
 */
export const managePlusBasicUserTableDataAttr = 'zoho-manage-plusBasicUser-access-table'
/**
 *
 */
export const proUserTableDataAttr = 'zoho-proUser-access-table'
/**
 *
 */
export const plusBasicUserTableDataAttr = 'zoho-plusBasicUser-access-table'

/**
 *
 */
export interface UserModalInterface {
    open: boolean,
    handleClose: () => void,
    manageUsers: boolean,
    app: Record<string, any>
}
/**
 *
 */
export type ProUser = Record<string, any>
/**
 *
 */
export type PlusBasicUser = Record<string, any>
/**
 *
 */
export interface Users {
    pro: ProUser[], plusBasic: PlusBasicUser[]
}
/**
 *
 */
export type ModelType = 'manageUser' | 'proUser' | 'plusBasicUser'
/**
 *
 */
export type TableType = 'manageProUser' | 'managePlusBasicUser' | 'proUser' | 'plusBasicUser'
