import React, { useState, createContext, useContext, useEffect } from 'react'
import { isUserAllowed } from './../utils/utils'
import { App, apps as appsObj } from '../utils/objects'
import { getValue } from 'remote-config-value'
import { getPhoneCom } from 'phonecom'
import { useHistory, useLocation } from 'react-router-dom'

interface AppsContextInterface {
    apps: App[],
    isLoadingApps: boolean,
    phoneCom: Record<string, any>
}

/**
 *
 */
export const AppsContext = createContext<AppsContextInterface>({
    apps: [],
    isLoadingApps: false,
    phoneCom: {}
})

/**
 *
 */
export const useAppsContext = () => useContext(AppsContext)

/**
 *
 */
const AppsContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [isLoadingApps, setIsLoadingApps] = useState(true)
    const [apps, setApps] = useState<App[]>([])
    const [phoneCom, setPhoneCom] = useState<Record<string, any>>([])
    const history = useHistory()
    const location = useLocation()

    const setAppsState = (phoneCom: Record<string, any>, appsJson: string) => {
        let apps: App[] = []
        if (typeof appsJson === 'string') {
            const parsedAppsJson = JSON.parse(appsJson)
            apps = getFilteredApps(parsedAppsJson, phoneCom)
            setApps(apps)
        }
        setIsLoadingApps(false)
    }

    const getFilteredApps = (parsedAppsJson: App[], phoneCom: Record<string, any>) => {
        if (!(parsedAppsJson instanceof Array)) {
            return []
        }
        const filteredApps = parsedAppsJson.filter(app => isUserAllowed(phoneCom, app?.userTypes))

        return filteredApps
    }

    const setAppsForTestEnv = () => {
        if (process.env.NODE_ENV === 'test') {
            setApps(appsObj)
            setIsLoadingApps(false)
        }
    }

    useEffect(() => {
        getPhoneCom().then(phoneCom => {
            const appsJson: string | undefined = getValue('apps_marketplace_json', setAppsState.bind(this, phoneCom), false)

            if (appsJson) {
                setAppsState(phoneCom, appsJson)
            }

            setPhoneCom(phoneCom)
        })
        setAppsForTestEnv()
    }, [])

    useEffect(() => {
        if (isLoadingApps) return

        const navigateToApp = (appName: string, apps: App[]) => {
            const app = apps.find(app => new RegExp(`${appName}`, 'i').test(app.title))
            if (app) {
                const basePath = location.pathname
                const queryString = location.search
                const appURI = app.title.toLowerCase()
                const link = !queryString ? `${basePath}/${appURI}` : `${basePath}/${appURI}${queryString}`
                history.replace(link)
            }
        }

        const urlParams = new URLSearchParams(location.search)
        const appName = urlParams.get('app')
        if (appName && apps.length) navigateToApp(appName, apps)
    }, [location.search, isLoadingApps])

    return (
        <AppsContext.Provider value={{
            apps,
            isLoadingApps,
            phoneCom
        }} >
            {children}
        </AppsContext.Provider>
    )
}

export default AppsContextProvider
