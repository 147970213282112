/* eslint-disable @typescript-eslint/naming-convention */

/**
 *
 */
export const email_administrator = {
    PDC_Action: 'zoho_crm_activate_modal',
    PDC_Label: 'zoho_crm_clicked_email_sdministrator_btn',
    PDC_Value: null,
    event: 'PDC_zoho_crm_clicked_email_sdministrator_btn'
}

/**
 *
 */
export const plus_users_upgrade = {
    PDC_Action: 'zoho_crm_user_modal',
    PDC_Label: 'zoho_crm_upgrade_plus_users',
    PDC_Value: null,
    event: 'PDC_zoho_crm_upgrade_plus_users'
}

/**
 *
 */
export const basic_users_upgrade = {
    PDC_Action: 'zoho_crm_user_modal',
    PDC_Label: 'zoho_crm_upgrade_basic_users',
    PDC_Value: null,
    event: 'PDC_zoho_crm_upgrade_basic_users'
}

/**
 *
 */
export const send_email_pressed = {
    PDC_Action: 'zoho_crm_user_modal',
    PDC_Label: 'zoho_crm_send_email_pressed',
    PDC_Value: null,
    event: 'PDC_zoho_crm_send_email_pressed'
}

/**
 *
 */
export const deactivate_zoho_pressed = {
    PDC_Action: 'zoho_crm_user_modal',
    PDC_Label: 'zoho_crm_deactivate_zoho_pressed',
    PDC_Value: null,
    event: 'PDC_zoho_crm_deactivate_zoho_pressed'
}

/**
 *
 */
export const cancel_btn_pressed_activate_modal = {
    PDC_Action: 'zoho_crm_activate_modal',
    PDC_Label: 'zoho_crm_cancel_btn_pressed',
    PDC_Value: null,
    event: 'PDC_zoho_crm_cancel_btn_pressed'
}

/**
 *
 */
export const cancel_btn_pressed_user_modal = {
    PDC_Action: 'zoho_crm_user_modal',
    PDC_Label: 'zoho_crm_cancel_btn_pressed',
    PDC_Value: null,
    event: 'PDC_zoho_crm_cancel_btn_pressed'
}
