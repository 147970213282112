import React, { useContext, useEffect, useState } from 'react'
import { Alert } from 'foundations-library/components'
import TabsSubmenu from '../../elements/TabsSubmenu'
import ItemsList from '../../elements/List'
import { ConfirmationDialog } from 'functional-foundations'
import { makeStyles } from '@material-ui/core'
import style from './style'
import PMLoader from '../../elements/PMLoader'
import { ScreenSizeContext, usePaymentMethodContext } from 'providers'
import { useAlert } from 'hooks'

const useStyle = makeStyles(style)

interface PropsInterface {
    onClickAddPaymentMethod(): void;
    className?: string;
}

/**
 *
 */
const index = ({ onClickAddPaymentMethod, className }: PropsInterface): JSX.Element => {
    const {
        items,
        remove,
        primary,
        setStatus,
        getData
    } = usePaymentMethodContext()
    const screenSize = useContext(ScreenSizeContext)

    const styles = useStyle({ screenSize })

    const [loading, setLoading] = useState(false)
    const [newPrimaryMethodId, setNewPrimaryMethodId] = useState(null)
    const [loadingId, setLoadingIid] = useState(null)
    const [deletePaymentMethodId, setDeletePaymentMethodId] = useState(null)
    const [alert, setAlert, genericAlert] = useAlert()
    const [disableForm, setDisableForm] = useState(false)

    const onClickSetPrimary = async (id) => {
        if (primary && primary.id === id) {
            return true
        }

        setNewPrimaryMethodId(id)
    }

    const loadPaymentMethods = async () => {
        setLoading(true)
        try {
            await getData()
        } catch (err) {
            setAlert(err, 'error')
        }
        setLoading(false)
    }

    useEffect(() => {
        loadPaymentMethods()
    }, [])

    const closeNewPrimaryMethodIdModal = () => setNewPrimaryMethodId(null)
    const closeDeletePaymentMethodId = () => setDeletePaymentMethodId(null)

    const saveNewPrimaryMethodId = async () => {
        setDisableForm(true)
        const id = newPrimaryMethodId
        setNewPrimaryMethodId(null)
        try {
            if (primary && primary.id === id) return true

            setLoadingIid(id)
            await setStatus(id, 'primary')
            genericAlert.successfulUpdate()
        } catch (err) {
            setAlert(err, 'error')
        }
        setDisableForm(false)
        setLoadingIid(null)
    }

    const deletePaymentMethod = async () => {
        setDisableForm(true)
        const id = deletePaymentMethodId
        setDeletePaymentMethodId(null)
        setLoadingIid(id)

        try {
            await remove(id)

            genericAlert.successfulDeletion()
        } catch (err) {
            setAlert(err, 'error')
        }

        setDisableForm(false)
        setDeletePaymentMethodId(null)
        setLoadingIid(null)
    }

    const Tabs = (): JSX.Element => {
        if (items && items.length) {
            return <TabsSubmenu value='list' onChange={(v) => v === 'create' && onClickAddPaymentMethod()} data-testid='payment-methods-list-tabs'/>
        }
        return <></>
    }

    return (
        <div className={`${styles.list} ${className}`} data-testid='payment-methods-list'>
            {loading && <PMLoader fillContent />}
            {!loading && <>
                {/* <Alert level='error' data-testid='payment-methods-list-autopay-alert'>
                    You are currently using Autopay. Please update your payment information as your previous payment has failed.
                </Alert> */}
                { alert && <Alert {...alert} data-testid='payment-methods-list-alert'/> }
                {!loading && (
                    <div className='payment-methods--content'>
                        <Tabs />
                        {
                            items &&
                            <ItemsList
                                items={items}
                                onClickSetPrimary={onClickSetPrimary}
                                onClickRemove={setDeletePaymentMethodId}
                                disabled={disableForm}
                                loadingItem={loadingId}
                                withOptions
                                onClickAddNew={onClickAddPaymentMethod}
                                data-testid='payment-methods-items-list'
                            />
                        }
                    </div>
                )}
            </>}
            <ConfirmationDialog
                isOpen={!!newPrimaryMethodId}
                onClose={closeNewPrimaryMethodIdModal}
                onConfirm={saveNewPrimaryMethodId}
            >
                You&apos;re changing your primary payment method. Would you like to continue?
            </ConfirmationDialog>
            <ConfirmationDialog
                isOpen={!!deletePaymentMethodId}
                onClose={closeDeletePaymentMethodId}
                onConfirm={deletePaymentMethod}
                confirmText='Delete'
                destructive
            >
                Are you sure you&apos;d like to delete this card we have on file?
            </ConfirmationDialog>
        </div>
    )
}

export default index
