/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useContext, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from 'typography'
import Chip from '@material-ui/core/Chip'
import { userIcon, userAdminIcon } from '../icons/inlineIcons'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Checkbox from '@material-ui/core/Checkbox'
import { ZohoUsersContext } from 'providers'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import Spinner from 'spinner'
import gtmDataPush from 'gtm-events'
import { send_email_pressed, deactivate_zoho_pressed } from './GTMEvents'
import { SelectUsersToUpgradeContext, StatusContext } from './context'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
// import Skeleton from '@material-ui/lab/Skeleton'
import { DefaultTooltip } from 'tooltips'
import InfoIcon from '@material-ui/icons/Info'
import { activeStatus, notActiveStatus } from './objects'
import { signInToZoho, getEmailCaption } from './helpers'

/**
 *
 */
export const User = ({ user, tableType }: {user: Record<string, any>, tableType: string}): JSX.Element => {
    const isAdmin = user.isAdmin
    const icon = isAdmin ? userAdminIcon : userIcon
    const showIcon = tableType !== 'managePlusBasicUser'
    const showCheckbox = tableType === 'managePlusBasicUser'

    return (
        <Box className='userNameContainer'>
            {showIcon && <img src={icon} />}
            {showCheckbox && <CheckBoxComponent id={user.id} />}
            <Typography className='user-name'>{`${user.firstName} ${user.lastName}`}</Typography>
            {isAdmin && <Typography className='admin'>(Admin)</Typography>}
        </Box>
    )
}

/**
 *
 */
export const Plan = ({ user, tableType }: {user: Record<string, any>, tableType: string}): JSX.Element => {
    const isProUser = ['manageProUser', 'proUser'].includes(tableType)
    const plan = user.plan

    return (
        <Box>
            <Chip label={plan} className={`chip-plan ${isProUser ? 'pro-user' : undefined}`} />
        </Box>
    )
}

/**
 *
 */
export const Status = ({ user, tableType, texts }: { user: Record<string, any>, tableType: string, texts: Record<string, any> }): JSX.Element => {
    const isAdmin = user.isAdmin
    const zohoActivated = user.integrationActivated
    const email = user.email
    const emailNotAvailable = texts.emailNotAvailable

    const { selectedUsersToUpgrade } = useContext(SelectUsersToUpgradeContext)
    const userIds = selectedUsersToUpgrade
    const { isUpgradingUsers: isLoading, inviteUserToIntegration, deactivateIntegrationForUser } = useContext(ZohoUsersContext)

    const { deactivatedUsersState, setDeactivatedUsersState, sentEmailUsersState, setSentEmailUsersState, app } = useContext(StatusContext)
    const deactivedUserState = deactivatedUsersState.find(deactivedUser => deactivedUser.id === user.id)
    const sentEmailUserState = sentEmailUsersState.find(sentEmailUser => sentEmailUser.id === user.id)
    const apiResponse = zohoActivated ? deactivedUserState : (!isAdmin ? sentEmailUserState : {})
    const inProcess = apiResponse?.inProcess

    let status = notActiveStatus
    let statusText = ''
    let btnComponent = <></>

    const handleApiResponse = (setApiResponses: React.Dispatch<React.SetStateAction<Record<string, any>[]>>, respObj: { success: boolean, message: string, inProcess: boolean }, res?: Record<string, any>) => {
        setApiResponses((prevResponses) => {
            const apiResponseIndex = prevResponses.findIndex(prevResponse => prevResponse.id === user.id)
            if (apiResponseIndex !== -1) prevResponses.splice(apiResponseIndex, 1)
            return [...prevResponses, { id: user.id, ...respObj }]
        })
    }

    const deactivateZoho = async () => {
        handleApiResponse(setDeactivatedUsersState, { success: false, message: '', inProcess: true })
        await deactivateIntegrationForUser(user)
            .then(handleApiResponse.bind(this, setDeactivatedUsersState, { success: true, message: texts.deactivationSucceed, inProcess: false }))
            .catch(handleApiResponse.bind(this, setDeactivatedUsersState, { success: false, message: texts.deactivationFailed, inProcess: false }))

        gtmDataPush({
            ...deactivate_zoho_pressed,
            PDC_Value: user.id,
            items: [{ item_name: app.title }]
        })
    }

    const emailUser = async () => {
        handleApiResponse(setSentEmailUsersState, { success: false, message: '', inProcess: true })
        await inviteUserToIntegration(user)
            .then(handleApiResponse.bind(this, setSentEmailUsersState, { success: true, message: texts.emailSucceed, inProcess: false }))
            .catch(handleApiResponse.bind(this, setSentEmailUsersState, { success: false, message: texts.emailFailed, inProcess: false }))

        gtmDataPush({
            ...send_email_pressed,
            PDC_Value: user.id,
            items: [{ item_name: app.title }]
        })
    }

    const ButtonAtStatus = ({ className, onClick, text, color, icon, disabled, style }: {className: string, onClick: () => void, text: string, color?: string, icon?: JSX.Element, disabled: boolean, style?: Record<string, any>}) => {
        return (
            <Box display='flex' alignItems='center'>
                <Button className={className} variant='text' size='small' onClick={onClick} color={color} disabled={disabled} style={style} >
                    {text}{icon}
                    {inProcess && <Spinner color='#8C8C8C' size='small' />}
                </Button>
                {!email && <DefaultTooltip title={emailNotAvailable} placement='top'><InfoIcon fontSize='small' /></DefaultTooltip>}
            </Box>
        )
    }

    const getBtnComponent = () => {
        if (apiResponse?.success) return <></>

        return (
            zohoActivated
                ? <ButtonAtStatus className='deactivate-zoho' disabled={inProcess} onClick={deactivateZoho} text={texts.deactivateZoho} style={{ color: !inProcess ? '#FD6C3F' : undefined }} />
                : (
                    isAdmin
                        ? <ButtonAtStatus className='activate-zoho' disabled={inProcess} onClick={signInToZoho.bind(this, app)} text={texts.activateZoho} color='primary' icon={<ArrowForwardIcon color='primary' className='right-arrow-icon' />} />
                        : <ButtonAtStatus className='email-user' disabled={inProcess || !email} onClick={emailUser} text={texts.notifyEmail} color='primary' icon={<ArrowForwardIcon color={!inProcess && email ? 'primary' : '#8C8C8C'} className='right-arrow-icon' />} />
                )
        )
    }

    const getLoadingComponent = () => {
        return (
            <>
                <Spinner color='#8C8C8C' size='small' />
                <Typography className='loading'>Upgrading</Typography>
            </>
        )
    }

    const renderApiResponse = () => {
        return (
            apiResponse?.message &&
            <Typography variant='caption' color={apiResponse?.success ? 'primary' : 'error'}>
                {apiResponse?.success ? <CheckCircleIcon className='api-response-icon' color='primary' /> : <ErrorIcon className='api-response-icon' color='error' />}
                {apiResponse?.message}
            </Typography>
        )
    }

    switch (true) {
            case tableType === 'manageProUser': {
                status = zohoActivated ? texts.activeStatus : texts.notActiveStatus
                statusText = zohoActivated ? texts.deactivateZohoCaption : (!isAdmin ? getEmailCaption(texts.notifyEmailCaption, user.firstName) : '')
                btnComponent = getBtnComponent()
                break
            }
            case tableType === 'managePlusBasicUser': {
                status = texts.statusUpgrade
                break
            }
            case tableType === 'proUser': {
                status = zohoActivated ? texts.activeStatus : texts.notActiveStatus
                statusText = zohoActivated ? '' : texts.statusNotActiveText
                break
            }
            case tableType === 'plusBasicUser': {
                status = texts.status
                statusText = user.upgradeRequestSent ? texts.statusEmail : texts.statusUpgrade
                break
            }
            default:
                break
    }
    const isActiveNotActiveStatus = [activeStatus, notActiveStatus].includes(status)

    return (
        <Box className='statusContainer'>
            {
                !(tableType === 'managePlusBasicUser' && isLoading &&
                 userIds.includes(user.id)
                )
                    ? <>
                        <Chip label={status} className={`chip-status ${isActiveNotActiveStatus ? (status === activeStatus ? 'active' : 'not-active') : ''}`} />
                        <Box className='statusTextContainer'>
                            <Typography className='status-text'>{statusText}</Typography>
                            {btnComponent}
                            {renderApiResponse()}
                        </Box>
                    </>
                    : getLoadingComponent()
            }
        </Box>
    )
}

/**
 *
 */
export const SelectAll = ({ checkAllTitle }: {checkAllTitle: string}): JSX.Element => {
    return (
        <Box className='selectAllContainer'>
            <CheckBoxComponent id={0} selectAll={true} />
            <Typography className='check-all-typo'>{checkAllTitle}</Typography>
        </Box>
    )
}

/**
 *
 */
export const CheckBoxComponent = ({ id, selectAll }: { id: number, selectAll?: boolean }): JSX.Element => {
    const { selectedUsersToUpgrade, addUserId, removeUserId, addAllUserIds, removeAllUserIds, allUserIdsSelected } = useContext(SelectUsersToUpgradeContext)
    const [checked, setChecked] = useState(false)

    const updateContext = (isChecked: boolean) => {
        if (!selectAll) {
            if (isChecked) addUserId(id); else removeUserId(id)
        } else {
            if (isChecked) addAllUserIds(); else removeAllUserIds()
        }
    }
    const handleChange = (ev: React.ChangeEvent) => {
        const targetElement = (ev.target as HTMLFormElement)
        const isChecked = targetElement.checked
        setChecked(isChecked)
        updateContext(isChecked)
    }

    useEffect(() => {
        if (selectAll) setChecked(allUserIdsSelected)
        else setChecked(selectedUsersToUpgrade.includes(id))
    }, [selectAll, allUserIdsSelected, selectedUsersToUpgrade])

    return (
        <Box className='checkBoxContainer'>
            <Checkbox color="primary" classes={{ root: 'basic-plus-user-checkbox-root', checked: 'basic-plus-user-checkbox-checked' }} value={id} checked={checked} onChange={handleChange} />
        </Box>
    )
}

/**
 *
 */
export const LargeBtn = ({ children, handleClick, color, disabled }: { children: React.ReactNode, handleClick: () => void, color: 'primary' | 'inherit' | 'default' | 'secondary' | undefined, disabled: boolean }) => {
    return (
        <Button variant='contained' size='large' onClick={handleClick} color={color} disabled={disabled}>
            {children}
        </Button>
    )
}

/**
 *
 */
export const DialogActionButtons = ({ handleCancelClick, handlePrimaryClick, cancelChildren, primaryChildren, disablePrimaryBtn, disableCancelBtn }: { handleCancelClick: () => void, handlePrimaryClick: () => void, cancelChildren: React.ReactNode, primaryChildren: React.ReactNode, disablePrimaryBtn?: boolean, disableCancelBtn?: boolean }) => {
    return (
        <>
            <LargeBtn disabled={Boolean(disableCancelBtn)} handleClick={handleCancelClick} color="secondary">{cancelChildren}</LargeBtn>
            <LargeBtn disabled={Boolean(disablePrimaryBtn)} handleClick={handlePrimaryClick} color="primary">{primaryChildren}</LargeBtn>
        </>
    )
}

/**
 *
 */
export const DialogActionButtonsSkeleton = () => {
    return (
        <>
            {/* <Skeleton className='leftBtn' animation={false} variant={'rect'} width={90} height={35} />
            <Skeleton className='rightBtn' animation={false} variant={'rect'} width={200} height={35} /> */}
        </>
    )
}

/**
 *
 */
export const Error = ({ message }: { message: string }) => {
    return (
        message
            ? <Typography className='error' data-testid='errorElement'>
                {
                    <>
                        <ErrorIcon data-testId='error' color='error'/>
                        {message}
                    </>
                }
            </Typography>
            : <></>
    )
}

/**
 *
 */
export const UpgradeUsersToProDialog = ({ open, handleClose, texts, handleUpgradeUsersToPro, classes }: { open: boolean, handleClose: () => void, texts: Record<string, any>, handleUpgradeUsersToPro: () => void, classes: Record<string, any>}) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="zoho-upgrade-user-dialog"
            className={classes.upgradeUserDialog}
        >
            <DialogTitle disableTypography={true} className={classes.upgradeUserDialogTitle}>
                {texts.shouldUpgradeUsersText}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.upgradeUserDialogContentText}>
                    {texts.upgradeUsersBillText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActionsContainer}>
                <DialogActionButtons disabled={false} handleCancelClick={handleClose} handlePrimaryClick={handleUpgradeUsersToPro} cancelChildren={'Cancel'} primaryChildren={texts.upgradeUsersBtn} />
            </DialogActions>
        </Dialog>
    )
}

/**
 *
 */
export const SkeletonLoader = ({ className, count, showTextAtStatus }: { className: string, count: number, showTextAtStatus: boolean }) => {
    return (
        <Box className={className}>
            {Array.from({ length: count }).map((val, index) => {
                return (
                    <Box key={index} className='skeleton'>
                        {/* <Skeleton animation={false} variant={'rect'} height={60} /> */}
                        <Box className='skeletons'>
                            <Box className='user'>
                                {/* <Skeleton className='userIcon' animation={false} variant='circle' width={20} height={20} /> */}
                                {/* <Skeleton className='userName' animation='wave' variant='text' width={75} /> */}
                            </Box>
                            <Box className='plan'>
                                {/* <Skeleton className='planBtn' animation={false} variant='rect' width={50} height='100%' /> */}
                            </Box>
                            <Box className='status'>
                                {/* <Skeleton className='statusBtn' animation={false} variant='rect' width={90} height='100%' /> */}
                                {
                                    showTextAtStatus && <Box>
                                        {/* <Skeleton component='div' className='text1' animation='wave' variant='text' width={120} /> */}
                                        {/* <Skeleton component='div' className='text2' animation='wave' variant='text' width={60} /> */}
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}
