import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, Infotip, Icon } from 'foundations-library/components'
import { details } from '../../../utils/objects'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        columnGap: '.5rem',
        '& [data-testid=phone-infotip]': {
            position: 'relative',
            top: '-8px'
        }
    }
}))

/**
 *
 */
const DropdownWithInfoTip = (props: Record<string, any>) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Select {...props} />
            <Infotip value={details.phoneInfoTip} position={'left'} data-testid='phone-infotip'><Icon name='notification-alert' size='small' color='#000' /></Infotip>
        </div>
    )
}

export default DropdownWithInfoTip
