export default /**
eeeeeeeeeeeeeee *
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee */
(y) => ({
    radioGroup: {
        display: 'flex',
        justifyContent: 'start'
    },
    title: {
        marginBottom: '10px'
    },
    radioVertical: {
        flexDirection: 'column',
        '& .radio-group-item': {
            marginBottom: '15px',
            '&:last-child': {
                marginBottom: '0px'
            }
        }
    },
    radioHorizontal: {
        flexDirection: 'row',
        /**
         *
         */
        width: (p) => p.align !== 'start' ? '100%' : undefined,
        '& .radio-group-item': {
            marginRight: '15px',
            '&:last-child': {
                marginRight: '0px'
            }
        },
        '&.radio-group--align-end': {
            justifyContent: 'end'
        },
        '&.radio-group--align-center': {
            justifyContent: 'center'
        }
    }
})
