
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'foundations-library/components'
import style from './style'

const useStyles = makeStyles(style)

/**
 *
 */
const BasicTable = ({ columns, rows }: { columns: string[], rows: React.ReactNode[][] }) => {
    const classes = useStyles()

    const columnWidthNotHavingHeading = 4
    const columnsHavingHeading = columns.filter(column => Boolean(column)).length
    const columnsNotHavingHeading = columns.length - columnsHavingHeading
    const columnWidthHavingHeading = (100 - (columnsNotHavingHeading * columnWidthNotHavingHeading)) / columnsHavingHeading

    return (
        <div className={`${classes.tableContainer} table-container`}>
            <table data-testid='table'>
                <tr>
                    {
                        columns.map((column, index) => {
                            const columnHavingHeading = Boolean(column)

                            return (
                                <th key={index} style={{ width: `${columnHavingHeading ? columnWidthHavingHeading : columnWidthNotHavingHeading}%` }}>
                                    <Typography variant='body3' color='secondary-700' >{column}</Typography>
                                </th>
                            )
                        })
                    }
                </tr>
                {
                    rows.map((thisRow, index) => (
                        <tr key={index}>
                            {
                                thisRow.map((data, index) => <td key={index}>{data}</td>)
                            }
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}

export default BasicTable
