import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Icon, { IconType } from '../Icon/Icon'
import Typography from '../Typography/Typography'
import jss from './style'

const useStyles = makeStyles(jss)

interface PropsInterface extends Partial<IconType> {
    message?: string;
    'data-testid'?: string;
    onClick?(): void;
    overlay?: boolean;
    inline?: boolean;
    inlineBlock?: boolean;
    className?: string
}

/**
 *
 */
const Loader = ({
    message,
    children,
    'data-testid': dataTestId = 'loader',
    overlay = false,
    inline = false,
    inlineBlock = false,
    className,
    ...props
}: PropsWithChildren<PropsInterface>): JSX.Element => {
    const styles = useStyles()
    const loaderRef = useRef(null)
    const [top, setTop] = useState(0)
    const [isPartOfDialog, setIsPartOfDialog] = useState(false)
    useEffect(() => {
        let tempIsPartOfDiloag = false
        if (loaderRef?.current) {
            tempIsPartOfDiloag = Boolean(loaderRef.current.closest('[data-testid="dialog"]'))
            setIsPartOfDialog(tempIsPartOfDiloag)
        }

        if (loaderRef?.current) {
            !overlay &&
            !inline &&
            !inlineBlock &&
            !tempIsPartOfDiloag &&
            setTop(
                (window.innerHeight - loaderRef.current.offsetTop) / 2 - 105
            )
        }
    }, [])

    const classes = [
        overlay && styles.overlay,
        inline && styles.inline,
        !overlay && !inline && styles.loader,
        isPartOfDialog && styles.dialog
    ].filter((x) => x).join(' ')

    return <div onClick={props.onClick} className={`${classes} ${className || ''}`} ref={loaderRef} data-testid={dataTestId}>
        <div className='loader--inner'>
            <Icon
                name='spinner'
                viewBox='0 0 80 80'
                color={props.color || 'neutral-100'}
                spin
                style={{ marginTop: top }}
                {...props}
                data-testid={`${dataTestId}-icon`}
            />
            {
                (children || message) && <Typography variant='body2' className={styles.message} data-testid={`${dataTestId}-message`}>
                    {children || message}
                </Typography>
            }
        </div>
    </div>
}

export default Loader
