import React, { useContext, useMemo } from 'react'
import svgGenerator from './generator'
import iconValues from './iconValues'
import IconNameType from '../../helpers/types/IconName.interface'
import { ThemeContext } from 'providers'
import { getVerbalColors } from 'helpers'
import { AnimationType } from './animations/AnimationType'
interface PropsInterface extends Omit<React.SVGProps<SVGSVGElement>, 'name'> {
    name: IconNameType;
    size?: number | 'small' | 'medium' | 'large';
    color?: string;
    colors?: string[];
    onClick?(): any;
    animation?: AnimationType;
    spin?: boolean;
    viewBox?: string;
    'data-testid'?: string;
}

const defaultSizes = {
    small: 16,
    medium: 24,
    large: 36
}

const checkSize = (s) => {
    if (defaultSizes[s]) return defaultSizes[s]
    if (Number.isNaN(s)) return defaultSizes.medium
    return s
}

const getIconBody = (name, colors = []) => {
    if (!iconValues[name]) {
        console.error('Icon not found')
        return ''
    }
    return iconValues[name](colors)
}

/**
 *
 */
const Icon = ({
    name,
    size = 'medium',
    color = 'primary',
    colors,
    spin,
    'data-testid': dataTestId = 'icon-button',
    onClick,
    ...props
}: PropsInterface): JSX.Element => {
    if (!name) return <></>

    const themeContext = useContext(ThemeContext)
    const verbalColors = useMemo(() => {
        const { colors } = themeContext?.['foundations-library'] ?? {}
        return getVerbalColors(colors)
    }, [])

    const computedColors = useMemo(() => {
        let tempColors:any = []
        if (colors && colors.length) {
            tempColors = colors.map((x) => verbalColors[x] || x)
        } else if (color) {
            const parts = color.split(' ')
            tempColors = parts.map((x) => verbalColors[x] || x)
        }
        return tempColors
    }, [colors, color, verbalColors])

    const computedSize = checkSize(size)
    const Component = svgGenerator(getIconBody(name, computedColors))

    const animation = props.animation || (spin ? 'spin-forward' : false)

    return <Component {...props} size={computedSize} onClick={onClick} animation={animation} data-testid={dataTestId}/>
}

/**
 *
 */
export type IconType = PropsInterface

export default Icon
