/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useEffect, useState } from 'react'
import { UserManagement } from '../../../integrations/src/UserManagement'

interface ZohoUserContextProps {
    loading: boolean,
    currentUser: any,
    requestUpgradeFromAdmin: (user: any) => Promise<any>,
}

/**
 *
 */
export const ZohoUserContext = createContext<ZohoUserContextProps>({
    loading: false,
    currentUser: {},
    requestUpgradeFromAdmin: async (user: any) => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

/**
 *
 */
export const ZohoUserProvider: React.FC = (props) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [currentUser, setCurrentUser] = useState<any>({})

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const user = await UserManagement.buildCurrentUser('zoho_crm')
            setCurrentUser(user)
            setLoading(false)
        }
        fetchData()
            .catch(console.error)
    }, [])

    const requestUpgradeFromAdmin = async (user) => {
        setLoading(true)
        try {
            await UserManagement.requestUpgradeFromAdmin(1, user.id, 'Pro')
        } catch (error) {
            setLoading(false)
            throw error
        }
        setLoading(false)
    }

    const context: ZohoUserContextProps = {
        loading,
        currentUser,
        requestUpgradeFromAdmin
    }

    return (
        <ZohoUserContext.Provider value={context}>
            {props.children}
        </ZohoUserContext.Provider>
    )
}
