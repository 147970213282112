import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Alert } from 'foundations-library/components'
import { getVerbalColors } from 'helpers'
import { EsimContext } from 'providers'
import Button from './../Button'
import { useEsimStepperContext } from '../../../../../../contexts/EsimStepperContext'

const useStyles = makeStyles((theme) => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 40px',
            gap: '15px',
            flexWrap: 'wrap',
            borderRadius: '20px',
            background: '#FFF',
            boxShadow: '0px 10px 14px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        eSIMCountContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px'
        },
        selectEsimCount: {
            display: 'flex',
            height: '36px',
            padding: '0px 10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            borderRadius: '25px',
            border: `1px solid ${verbalColors['neutral-200']}`,
            '& .count': {
                width: '40px',
                textAlign: 'center'
            },
            '& .divider': {
                width: '1px',
                height: '100%',
                backgroundColor: verbalColors['neutral-200']
            }
        },
        priceContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '6px',
            flex: '1 0 0'
        },
        price: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: '2px'
        }
    })
})

/**
 *
 */
const SelectEsimCount = (): JSX.Element => {
    const classes = useStyles()
    const { processOrderEsims, orderEsims } = useContext(EsimContext)
    const { selectedPlan, card, count, setCount, goToNextStep } = useEsimStepperContext()

    const isPlanSelected = Boolean(selectedPlan.title)

    const handleNextStep = async () => {
        const order = { esimPlanId: selectedPlan.id, count, paymentCard: card! }
        const orderedEsims = await processOrderEsims({ ...order, preview: true })
        if (orderedEsims) goToNextStep()
    }

    return (
        <>
            {orderEsims.error && <Alert level='error' icon={true}>{orderEsims.error}</Alert>}
            <div className={classes.root}>
                <div className={classes.eSIMCountContainer}>
                    <Typography variant='subtitle2' color='neutral-900'>Number of eSIM</Typography>
                    <div className={classes.selectEsimCount}>
                        <IconButton data-testid='substract' name='substract' size='small' variant='icon' color={isPlanSelected ? 'primary' : 'neutral'} disabled={!(count > 0 && isPlanSelected && !orderEsims.isLoading)} onClick={() => (count > 0 && isPlanSelected && !orderEsims.isLoading) && setCount(count => count - 1)} />
                        <div className='divider' />
                        <Typography data-testid='esim-count' variant='body1' color='neutral-200' className='count'>{String(count)}</Typography>
                        <div className='divider' />
                        <IconButton data-testid='add' name='add' size='small' variant='icon' color={isPlanSelected ? 'primary' : 'neutral'} disabled={!(isPlanSelected && !orderEsims.isLoading)} onClick={() => (isPlanSelected && !orderEsims.isLoading) && setCount(count => count + 1)} />
                    </div>
                </div>
                <div className={classes.priceContainer}>
                    <div className={classes.price}>
                        <Typography variant='h4' color='neutral-900'>$</Typography>
                        <Typography variant='h2' color='neutral-900'>{((Number(selectedPlan.price) || 0) * count).toFixed(2)}</Typography>
                    </div>
                    <Typography variant='subtitle2' color='neutral-900'>
                        Per month, plus applicable fees
                    </Typography>
                    <Typography variant='subtitle2' color='neutral-500'>
                        Excluded all taxes*
                    </Typography>
                </div>
                <Button size='large' variant='contained' color='primary' iconLeft={false} icon={!orderEsims.isLoading ? 'go' : undefined} disabled={!isPlanSelected || !count || orderEsims.isLoading} isLoading={orderEsims.isLoading} minWidth={'120px'} onClick={handleNextStep}>Continue</Button>
            </div>
        </>
    )
}

export default SelectEsimCount
