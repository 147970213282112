import React, { useEffect } from 'react'
import { useAppsContext } from '../../../contexts/AppsContext'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getValue } from 'remote-config-value'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Loader from './../../../components/Loader'
import { handleGTM, GTM_APP_LIST_NAME } from '../../../utils/utils'
import App from '../../../components/App'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
    contentBox: {
        padding: '3rem',
        [theme.breakpoints.up('lg')]: {
            padding: '3rem 3rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 1.5rem'
        }
    },
    contentHeading: {
        fontWeight: '600!important',
        marginBottom: '1.25rem'
    },
    appsContainer: {
        maxWidth: '1100px',
        margin: 'auto',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            rowGap: '2.5rem'
        }
    },
    loadingDiv: theme.loadingDiv
}))

/**
 *
 */
const Content = (): JSX.Element => {
    const classes = useStyles()
    const theme = useTheme()
    const appsContext = useAppsContext()
    const belowXS = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(() => {
        if (!appsContext.isLoadingApps) {
            const appsTitle = appsContext.apps.map(app => ({ item_name: app.title })) // eslint-disable-line @typescript-eslint/naming-convention

            handleGTM({
                action: GTM_APP_LIST_NAME,
                label: 'viewed-marketplace-page',
                event: 'PDC_viewed_marketplace_list',
                other: {
                    items: appsTitle
                }
            })
        }
    }, [appsContext.isLoadingApps])

    const renderLoader = () => <Loader />

    const renderApps = () => (
        <Grid container spacing={belowXS ? 0 : 5} className={classes.appsContainer}>
            {
                appsContext.apps.map(app => (
                    <Grid key={app.id} item xs={12} sm={6} lg={4} data-testid={getValue('apps_marketplace_app_container_test_id')}>
                        <App app={app} />
                    </Grid>
                ))
            }
        </Grid>
    )

    return (
        <Box className={classes.contentBox}>
            {
                appsContext.isLoadingApps
                    ? renderLoader()
                    : renderApps()
            }
        </Box>
    )
}

export default Content
