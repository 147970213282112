/* eslint-disable @typescript-eslint/naming-convention */
import { ObservableState } from 'class-helpers'
import api from 'api'
import { getPhoneCom } from 'phonecom'

const USER_PLAN_ID = 'user_plan_id'
interface User {
    id: number
    firstName: string
    lastName: string
    plan: string
    integrationActivated: boolean
    isAdmin: boolean
}
interface UserManagementState {
    users: User[]
    rawUsers: any[]
    integration: string
    numberOfUsers: number
    order: string
    proUsersCount: number
    plusBasicUsersCount: number
    totalUserCount: number
}
interface UserManagementConfig {
  integration: string
  numberOfUsers: number
  order?: string
  proUsersCount: number
  plusBasicUsersCount: number
  listener: (state: UserManagementState) => void
}
interface RawUser {
    id: number
    first_name: string
    last_name: string
    is_admin: boolean
    relationships: {
        extension: {
            data: {
                id: number
                extension_number: number
                extension_name: string
                unlimited: boolean
            }
        }
        plan: {
            data: {
                id: number
                plan_description: string
                extension_billing_code: any
            }
        }
    }
    user_plan_id: number
    auth_center_id: number
    avatar_url: any
    email: string
    personal_phone_number: string
    voicemail_password: string
    timezone: string
    status: string

}

const INTEGRATION_NAME_TO_ID_MAP = {
    zoho_crm: 1
}

class UserManagement extends ObservableState<UserManagementState> {
    /**
     *
     */
    constructor (config: UserManagementConfig) {
        super({ users: [], rawUsers: [], integration: config.integration, numberOfUsers: config.numberOfUsers, order: config.order, totalUserCount: 0, proUsersCount: 0, plusBasicUsersCount: 0 })
        // set integration, number of users and order in the state
        this.setState({ integration: config.integration, numberOfUsers: config.numberOfUsers, order: config.order })
        const { listener } = config
        this.subscribe(listener)
    }

    /**
     *
     */
    public setUserCount (obj: Record<string, any>): void {
        const totalUserCount = obj.total_user_count
        // if plan_counts keys are not available, set associated values to 0
        const proUsersCount = obj.plan_counts?.['3'] || 0
        const plusBasicUsersCount = (obj.plan_counts?.['1'] || 0) + (obj.plan_counts?.['2'] || 0)
        this.setState({ totalUserCount, proUsersCount, plusBasicUsersCount })
    }

    /**
     *
     */
    public async initialize (): Promise<void> {
        let rawUsers
        try {
            const { numberOfUsers, order } = this.getState()
            console.log('order:', order)
            console.log('numberOfUsers:', numberOfUsers)
            const response = await api.getUserCount()
            console.log('response:', response)
            this.setUserCount(response)
            rawUsers = await api.loadUsers(numberOfUsers, 0, 'user_plan_id', order)
        } catch (error) {
            console.error('Failed to initialize UserManagement:', error)
            throw new Error('Failed to initialize UserManagement')
        }
        const promises = []
        for (const user of rawUsers) {
            promises.push(this.populateWithIntegrationStatus(user))
        }
        await Promise.all(promises)
    }

    // write a private method to iterate over the users and get integration status for each user
    /**
     *
     */
    private async populateWithIntegrationStatus (rawUser: RawUser) {
        const integration = this.getState().integration
        // if rawUser is plus or basic , dont get integration status
        let integrationStatus
        if (rawUser.user_plan_id === 1 || rawUser.user_plan_id === 2) {
            integrationStatus = { details: { active: false } }
        } else {
            integrationStatus = await api.getIntegrationDetails(rawUser.relationships.extension.data.id, integration)
        }
        const id = rawUser.id
        const firstName = rawUser.first_name
        const lastName = rawUser.last_name
        const email = rawUser.email
        const plan = rawUser.relationships.plan.data.plan_description
        const user = { id, firstName, lastName, email, plan, integrationActivated: integrationStatus?.details?.active, isAdmin: rawUser.is_admin }
        if (!this.getState().users.find(user => user.id === id)) {
            this.setState({ users: this.getState().users.concat(user) })
        } else {
            const users = this.getState().users
            const userIndex = users.findIndex(user => user.id === id)
            users[userIndex] = user
            this.setState({ users })
        }
        if (!this.getState().rawUsers.find(user => user.id === id)) {
            this.setState({ rawUsers: this.getState().rawUsers.concat(rawUser) })
        } else {
            const rawUsers = this.getState().rawUsers
            const userIndex = rawUsers.findIndex(user => user.id === id)
            rawUsers[userIndex] = rawUser
            this.setState({ rawUsers })
        }
    }

    /**
     * Load more users.
     *
     * @param numberOfUsers - The number of users to load.
     * @param offset - The offset for pagination.
     * @returns {Promise<void>} - A promise that resolves when the users are loaded.
     */
    public async loadMoreUsers (numberOfUsers: number, offset: number): Promise<void> {
        const rawUsers = await api.loadUsers(numberOfUsers, offset, USER_PLAN_ID, this.getState().order)
        const promises = []
        for (const user of rawUsers) {
            promises.push(this.populateWithIntegrationStatus(user))
        }
        await Promise.all(promises)
    }

    /**
     *
     */
    public static async requestUpgradeFromAdmin (integrationId: number, requestingUser: string, plan: string) {
        return await api.sendIntegrationEmail(integrationId, 'request_to_upgrade', [], requestingUser, plan)
    }

    /**
     *
     */
    public static async inviteUserToIntegration (integrationId: number, userId: number) {
        try {
            return await api.sendIntegrationEmail(integrationId, 'invite', userId)
        } catch (error) {
            console.error('Failed to invite user to integration:', error)
            throw new Error(error)
        }
    }

    /**
     *
     */
    public async deactivateIntegrationForUser (userId: number) {
        // get extension from rawUsers
        const user = this.getState().rawUsers.find(user => user.id === userId)
        if (!user) {
            throw new Error(`User with ID ${userId} not found.`)
        }
        const extensionId = user.relationships.extension.data.id
        await api.deleteIntegrationForExtension(extensionId, this.getState().integration)
            .then(async () => {
                await this.populateWithIntegrationStatus(user)
            })
    }

    /**
     *
     */
    public async upgradeUsersToPro (userIds: number[]) {
        const usersToUpgrade = this.getState().rawUsers.filter(user => userIds.includes(user.id)).map(user => {
            user.user_plan_id = 3
            return user
        })
        const userUpdatePatchValues = usersToUpgrade.map(user => {
            return { id: user.id, user_plan_id: 3 }
        })
        await api.updateUsers(userUpdatePatchValues)
            // invite the users to the integration
            .then(async () => {
                const promises = []
                for (const user of usersToUpgrade) {
                    // check if user has an email address
                    if (user.email) {
                        promises.push(UserManagement.inviteUserToIntegration(INTEGRATION_NAME_TO_ID_MAP[this.getState().integration], user.id))
                    }
                }
                await Promise.all(promises)
                    .catch(error => {
                        console.error('Failed to invite users to integration:', error)
                        throw new Error('Failed to invite users to integration')
                    })
            })
    }

    /**
     *
     */
    public async refreshUsers (order: string) {
        this.setState({ order, users: [], rawUsers: [] })
        const rawUsers = await api.loadUsers(this.getState().numberOfUsers, 0, 'user_plan_id', order)
        const promises = []
        for (const user of rawUsers) {
            promises.push(this.populateWithIntegrationStatus(user))
        }
        await Promise.all(promises)
    }

    /**
     *
     */
    public static async buildCurrentUser (integration: string) {
        const phoneCom = await getPhoneCom()
        let plan = phoneCom.user_account_type
        plan = plan.charAt(0).toUpperCase() + plan.slice(1)
        const integrationStatus = await api.getIntegrationDetails(phoneCom.id, integration)
        const user = { id: phoneCom.user_id, firstName: phoneCom.first_name, lastName: phoneCom.last_name, email: phoneCom.email, plan, integrationActivated: integrationStatus?.details?.active, isAdmin: phoneCom.role === 'account' } // TODO: get user plan
        return user
    }
}

/**
 *
 */
export { UserManagement }
/**
 *
 */
export type { User, UserManagementState, UserManagementConfig }
