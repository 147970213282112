import React, { useState, useEffect } from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, IconButton, Icon, TextField } from 'foundations-library/components'
import { getVerbalColors } from 'helpers'
import * as rules from 'validation-rules'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import { details } from '../../../utils/objects'
import { checkCompatibilityStatusType } from './../Index'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            position: 'relative',
            padding: '40px',
            '& .close-icon': {
                position: 'absolute',
                top: '12px',
                right: '12px'
            }
        },
        dialogContent: {
            padding: '30px 0 20px',
            '& .device, & .user': {
                padding: 0
            },
            '& .email': {
                color: verbalColors['secondary-600']
            }
        },
        checkCompability: {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px'
        },
        headingsContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        checksContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            '& .spinner': {
                width: '24px',
                height: '24px'
            }
        },
        checkContainer: {
            display: 'flex',
            gap: '10px',
            alignItems: 'flex-start'
        },
        checkTexts: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0'
        },
        errorTextsContainer: {
            display: 'flex',
            gap: '4px',
            alignItems: 'center'
        },
        dialogButtons: {
            display: 'flex',
            justifyContent: 'center',
            columnGap: '20px'
        }
    }
})

/**
 *
 */
const CheckCompabilityDialog = () => {
    const classes = useStyles()
    const { openCompabilityDialog, handleCloseCompabilityDialog } = useEsimOperationsContext()

    const [shouldIgnoreCheckCompatibility, setShouldIgnoreCheckCompatibility] = useState(false)
    const [checkCompatibilityStatus, setCheckCompatibilityStatus] = useState<checkCompatibilityStatusType>('pending')
    const [disableCloseDialog, setDisableCloseDialog] = useState(false)

    const [IMEInumber, setIMEInumber] = useState('')
    const [isIMEIvalid, setIsIMEIvalid] = useState(false)

    const handleIgnoreCompability = () => {
        setShouldIgnoreCheckCompatibility(true)
        handleCloseCompabilityDialog()
    }

    const handleCheckCompability = () => {
        setCheckCompatibilityStatus('in progress')
    }

    useEffect(() => {
        if (checkCompatibilityStatus === 'in progress') {
            setTimeout(() => {
                setCheckCompatibilityStatus('completed')
                setDisableCloseDialog(false)
            }, 4000)
            setDisableCloseDialog(true)
        }
    }, [checkCompatibilityStatus])

    const getContents = () => {
        let content = <></>
        let footer = <></>

        if (!shouldIgnoreCheckCompatibility && checkCompatibilityStatus !== 'pending') {
            content = (
                <div className={classes.checkCompability}>
                    <div className={classes.headingsContainer}>
                        <Typography variant='h6' color='neutral-900' align='center'>{details.assignEsimDialog.checkCompability.title}</Typography>
                    </div>
                    <div className={classes.checksContainer}>
                        <div className={classes.checkContainer}>
                            <div className={classes.checkTexts}>
                                <Typography variant='subtitle1' color='neutral-700'>{details.assignEsimDialog.checkCompability.check.compatiblityCheck.title}</Typography>
                                {checkCompatibilityStatus === 'completed' && <Typography variant='subtitle2' color='neutral-500'>{details.assignEsimDialog.checkCompability.check.compatiblityCheck.subTitle}</Typography>}
                            </div>
                            {checkCompatibilityStatus === 'in progress' ? <Icon name='spinner' color='neutral' spin /> : <Icon name={'check'} color='primary' />}
                        </div>
                        <div className={classes.checkContainer}>
                            <div className={classes.checkTexts}>
                                <Typography variant='subtitle1' color='neutral-700'>{details.assignEsimDialog.checkCompability.check.networkCheck.title}</Typography>
                                {checkCompatibilityStatus === 'completed' && <Typography variant='subtitle2' color='neutral-500'>{details.assignEsimDialog.checkCompability.check.networkCheck.subTitle}</Typography>}
                                {/* <div className={classes.errorTextsContainer}>
                                    <Typography variant='subtitle2' color='destructive-500' display='inline'>{details.assignEsimDialog.checkCompability.check.networkCheck.error}</Typography>
                                    <Button variant='text' color='primary' size='medium'>{details.assignEsimDialog.checkCompability.check.learnMore}</Button>
                                </div> */}
                            </div>
                            {checkCompatibilityStatus === 'in progress' ? <Icon name='spinner' color='neutral' spin /> : <Icon name={'check'} color='primary' />}
                            {/* <Icon name='infotip-sm' color='destructive' /> */}
                        </div>
                    </div>
                </div>
            )

            footer = (
                <>
                    <Button size='large' onClick={!disableCloseDialog ? handleCloseCompabilityDialog : undefined} color="primary" disabled={checkCompatibilityStatus !== 'completed'}>
                        {details.assignEsimDialog.checkCompability.check.continueBtn}
                    </Button>
                </>
            )

            return [content, footer]
        }

        if (shouldIgnoreCheckCompatibility) {
            content = (
                <div className={classes.checkCompability}>
                    <div className={classes.headingsContainer}>
                        <Typography variant='h6' color='neutral-900' align='center'>{details.assignEsimDialog.checkCompability.ignore.title}</Typography>
                        <Typography variant='body2' color='neutral-700' align='center'><div dangerouslySetInnerHTML={{ __html: details.assignEsimDialog.checkCompability.ignore.subTitle }} /></Typography> {/* eslint-disable-line @typescript-eslint/naming-convention */}
                    </div>
                </div>
            )

            footer = (
                <>
                    <Button size='large' onClick={() => setShouldIgnoreCheckCompatibility(false)} color="neutral">
                        {details.assignEsimDialog.checkCompability.ignore.goBackBtn}
                    </Button>
                    <Button size='large' onClick={handleIgnoreCompability} color='destructive'>
                        {details.assignEsimDialog.checkCompability.ignore.ignoreBtn}
                    </Button>
                </>
            )

            return [content, footer]
        }

        content = (
            <div className={classes.checkCompability}>
                <div className={classes.headingsContainer}>
                    <Typography variant='h6' color='neutral-900' align='center'>{details.assignEsimDialog.checkCompability.title}</Typography>
                    <Typography variant='body2' color='neutral-700' align='center'><div dangerouslySetInnerHTML={{ __html: details.assignEsimDialog.checkCompability.subTitle }} /></Typography> {/* eslint-disable-line @typescript-eslint/naming-convention */}
                </div>
                <TextField label={details.assignEsimDialog.checkCompability.IMEItext} value={IMEInumber} onChange={val => setIMEInumber(val)} type='number' autoFocus rules={[rules.regex(/^\d{15,17}$/, 'IMEI number', 'invalid IMEI number')]} isValid={(isValid) => setIsIMEIvalid(isValid)} />
            </div>
        )

        footer = (
            <>
                <Button size='large' onClick={() => setShouldIgnoreCheckCompatibility(true)} color="neutral">
                    {details.assignEsimDialog.checkCompability.ignoreBtn}
                </Button>
                <Button size='large' onClick={handleCheckCompability} color="primary" disabled={!isIMEIvalid}>
                    {details.assignEsimDialog.checkCompability.checkBtn}
                </Button>
            </>
        )

        return [content, footer]
    }

    const [content, footer] = getContents()

    return (
        <MuiDialog
            open={openCompabilityDialog}
            onClose={!disableCloseDialog ? handleCloseCompabilityDialog : undefined}
            aria-labelledby="check-compatibility-dialog"
            className={classes.root}
        >
            {!disableCloseDialog && <IconButton size='medium' className='close-icon' name='close' variant='icon' color='neutral' onMouseUp={handleCloseCompabilityDialog} />}

            <div className={classes.dialogContent}>
                {content}
            </div>
            <div className={classes.dialogButtons}>
                {footer}
            </div>
        </MuiDialog>
    )
}

export default CheckCompabilityDialog
