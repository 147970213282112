import React, { useEffect, useRef } from 'react'
import CommonInput from '../CommonInput/CommonInput'
import CommonInputType from '../../helpers/types/CommonInput.interface'
import style from './style'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(style)

type HtmlInputProps = Omit<React.HTMLProps<HTMLTextAreaElement>, 'children' | 'onChange' | 'size'>

/**
 *
 */
export type TextareaType = {
    onChange?(v): any;
} & HtmlInputProps & CommonInputType

/**
 *
 */
const Textarea = ({
    size = 'large',
    rules,
    isValid,
    helperText,
    onChange,
    onClear,
    style,
    className,
    maxLength,
    fullWidth = false,
    'data-testid': dataTestId = 'textare',
    ...props
}: TextareaType): JSX.Element => {
    const styles = useStyles({ maxLength })

    const textareaRef = useRef(null)

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 5}px`
        }
    }

    const handleOnChange = (e) => {
        adjustHeight()
        if (onChange && e.target) {
            return onChange(e.target.value)
        }
    }

    const renderMaxLengthLabel = () => {
        if (maxLength) {
            return <label className={styles.maxLength}>
                <span className={styles.maxLengthValue}>{props.value?.length || 0}</span>
                <span className={styles.maxLengthLimit}>/{maxLength}</span>
            </label>
        }
        return ''
    }

    useEffect(() => {
        setTimeout(adjustHeight, 1000)
    }, [])

    return (
        <CommonInput
            value={props.value}
            rules={rules}
            isValid={isValid}
            size={size}
            helperText={helperText}
            style={style}
            onClear={onClear}
            fullWidth={fullWidth}
            clearable={true}
            className={`${className} ${styles.wrapper}`}
            label={props.label}
            maxLength={maxLength}
            data-testid={dataTestId}
            leadingIcon={props.leadingIcon}
            onClickLeadingIcon={props.onClickLeadingIcon}
            trailingIcon={props.trailingIcon}
            onClickTrailingIcon={props.onClickTrailingIcon}
            disabled={props.disabled}
        >
            {renderMaxLengthLabel()}
            <textarea
                {...props}
                className={`${styles.textarea} textarea input`}
                onChange={handleOnChange}
                maxLength={maxLength}
                ref={textareaRef}
                data-testid={dataTestId}
            />
        </CommonInput>
    )
}

/**
 *
 */
export default Textarea
