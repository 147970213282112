import React from 'react'
import { Stepper } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core/styles'
import { useEsimStepperContext } from './../../../../../contexts/EsimStepperContext'
import SelectPlan from './selectPlan/Index'
import ConfirmOrder from './confirmOrder/Index'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '900px',
        margin: 'auto',
        '& .stepper--horizontal': {
            width: '550px',
            margin: 'auto',
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        '& .alert--error': {
            width: '50%',
            margin: '1rem auto'
        }
    },
    content: {
        padding: '20px 0'
    }
}))

/**
 *
 */
const Index = (): JSX.Element => {
    const classes = useStyles()
    const { items, step, handleChangeStep } = useEsimStepperContext()

    return (
        <div className={classes.root} data-testid='stepper-container'>
            <Stepper {...{ items }} variant='horizontal' value={step} onChange={handleChangeStep} />
            <div className={classes.content}>
                {step === 0 && <SelectPlan />}
                {step === 1 && <ConfirmOrder />}
                {step === 1 && <Footer />}
            </div>
        </div>
    )
}

export default Index
