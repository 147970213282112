import { AlertType } from 'foundations-library/types'

type ReturnType = {
    message: string,
    level: AlertType
}

/**
 *
 */
const generateResultAlertDownloadAllPdf = (results): ReturnType => {
    if (!Array.isArray(results)) {
        throw new Error('Invalid results parameter: expected an array')
    }
    const successes = results.filter((x) => x.level === 'success')
    const errors = results.filter((x) => x.level === 'error')
    if (!successes.length) {
        return {
            level: 'error' as AlertType,
            message: 'Something went wrong'
        }
    }
    if (!errors.length) {
        return {
            level: 'success' as AlertType,
            message: 'All documents downloaded successfully'
        }
    }
    return {
        level: 'success' as AlertType,
        message: `${successes.length} files downloaded successfully. ${errors.length} files download failed`
    }
}

/**
 *
 */
export {
    generateResultAlertDownloadAllPdf
}
