import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useParams, useHistory } from 'react-router-dom'
import { Typography, Button } from 'foundations-library/components'
import PDCMarkdown from 'pdc-markdown'
import { HowToArticleData, App } from './../../../../../utils/objects'
import { Picture, Video } from '../../../../../components/Media'
import { useAppContext } from '../../../../../contexts/AppContext'
import article01 from './../../../../../images/article01.jpg'
import article02 from './../../../../../images/article02.jpg'
import article03 from './../../../../../images/article03.jpg'
import article04 from './../../../../../images/article04.jpg'
import article05 from './../../../../../images/article05.jpg'
import article06 from './../../../../../images/article06.jpg'
import article07 from './../../../../../images/article07.jpg'

const items: HowToArticleData[] = [
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article01,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'Check you email',
            description: 'Once your eSIM is created an email will be sent to you with a link to activate your Cellular Service and installation details.'
        }
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article02,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'In your settings tap, the ‘Cellular tab’'
        }
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article03,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'Click on ‘add eSIM’'
        }
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article04,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'Select ‘Use QR code’'
        }
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article05,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'Scan the QR code provided in your email'
        }
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article06,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'Activate your eSIM',
            description: 'Once the QR code is scanned the following screen will appear. (This may take a few minutes)'
        }
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: article07,
                    type: 'image/jpg'
                }
            ]
        },
        info: {
            title: 'You’re all set!',
            description: 'Once your setup is complete you will receive this confirmation page on your screen.'
        }
    }
]

const useStyles = makeStyles(theme => ({
    root: {
        rowGap: '.5rem'
    },
    title: { margin: 'auto', padding: '1.5rem 0 3rem' },
    textContainer: {
        columnGap: '6px',
        '& .info': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '.5rem'
        }
    },
    imgContainer: {
        '& img': {
            display: 'block',
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            objectPosition: 'top left'
        }
    },
    goBackBtn: { margin: '2rem auto 0' }
}))

const Step = ({ step, index, app }: { step: HowToArticleData, index: number, app: App }) => {
    const classes = useStyles()

    return (
        <Grid container item spacing={3} direction='row-reverse'>
            <Grid item xs={12} sm={5} className={classes.imgContainer}>
                {
                    step.media.type === 'picture'
                        ? <Picture sources={step.media.sources} thumbnail={step.media.thumbnail} />
                        : <Video sources={step.media.sources} attributes={{ poster: step.media.thumbnail }} app={app} />
                }
            </Grid>
            <Grid item xs={12} sm={7} container wrap='nowrap' className={classes.textContainer}>
                <Typography variant='h6'>{index + 1}.</Typography>
                <Box className={'info'}>
                    <Typography variant='h6'>{step.info.title}</Typography>
                    {step.info.description && <PDCMarkdown>
                        {step.info.description}
                    </PDCMarkdown>}
                </Box>
            </Grid>
        </Grid>
    )
}

/**
 *
 */
const Article = () => {
    const classes = useStyles()
    let { articleTitle } = useParams<{ articleTitle: string }>()
    articleTitle = decodeURI(articleTitle)
    const history = useHistory()
    const { app } = useAppContext()

    return (
        <Grid container className={classes.root}>
            <Typography variant='h2' className={classes.title}>{articleTitle}</Typography>
            <Grid container item direction='column' spacing={5}>
                {items.map((item, index) => <Step key={index} step={item} index={index} app={app} />)}
            </Grid>
            <Button variant='contained' size='large' iconLeft={true} icon='back' color='primary' className={classes.goBackBtn} onClick={() => history.goBack()}>Go back</Button>
        </Grid>
    )
}

export default Article
