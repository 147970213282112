import React, { useContext, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Icon from '../Icon/Icon'
import IconNameType from '../../helpers/types/IconName.interface'
import Loader from '../Loader/Loader'
import { ScreenSizeContext } from 'providers'

interface PropsInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element | IconNameType; // can be passed as JSX or icon prop
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'destructive' | 'white';
  variant?: 'contained' | 'outlined' | 'text';
  iconLeft?: boolean;
  loading?: boolean;
  'data-testid'?: string;
}

const useStyles = makeStyles(style)

const iconSizes = {
    small: 18,
    medium: 22,
    large: 22
}
const renderIcon = (icon, btnSize, iconLeft) => {
    if (!icon) return ''
    if (typeof icon === 'string') {
        return <Icon name={icon} size={iconSizes[btnSize]} color='inherit' className={`button__icon ${iconLeft && 'button__icon-left'}`} data-testid='button-icon'/>
    }

    return icon
}

/**
 *
 */
const Button: React.FC<PropsInterface> = ({
    size = 'medium',
    variant = 'contained',
    color = 'primary',
    icon,
    iconLeft = false,
    loading = false,
    children,
    style: inlineStyle = {},
    ...props
}): JSX.Element => {
    const screenSize = useContext(ScreenSizeContext)
    const smallScreen = screenSize.mobile || screenSize.tablet
    const styles = useStyles({ screenSize })
    const [btnWidth, setBtnWidth] = useState(0)

    const onMouseDown = (e) => e.target.classList.add('button--active')
    const onMouseUp = (e) => e.target.classList.remove('button--active')

    const iconElement = renderIcon(icon, size, iconLeft)
    let iconClass = ''
    if (icon && iconLeft) {
        iconClass = 'has-icon-left'
    } else if (icon) {
        iconClass = 'has-icon-right'
    }

    const btn = useRef(null)

    useEffect(() => {
        setBtnWidth(btn?.current?.offsetWidth || 0)
    }, [])

    let classes = [color, size, iconClass, variant, iconClass, loading && 'loading', smallScreen && 'mobile'].filter((x) => x).map((x) => `button--${x}`).join(' ')
    if (props.className) classes = `${classes} ${props.className}`
    const inlineStyles = { ...inlineStyle, width: loading && btnWidth ? btnWidth : inlineStyle.width }

    return <button
        {...props}
        className={`${styles.button} ${classes}`}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        ref={btn}
        style={inlineStyles}
    >
        { !loading && iconLeft && iconElement }
        { loading ? <Loader className={styles.loader} inline data-testid='button-loader'/> : children }
        { !loading && !iconLeft && iconElement }
    </button>
}

/**
 *
 */
export type ButtonType = PropsInterface

export default Button
