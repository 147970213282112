import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PDCMarkdown from 'pdc-markdown'
import { Picture, Video } from './../../../components/Media'
import { App } from '../../../utils/objects'

const useStyles = makeStyles(theme => ({
    root: {
        rowGap: '40px'
    },
    generalInfoMarkdown: {
        paddingTop: '10px',
        gap: '12px',
        flex: '1 0 0',
        alignSelf: 'stretch',
        '&.right-padding': {
            [theme.breakpoints.up('sm')]: {
                paddingRight: '4rem'
            }
        },
        '&.left-padding': {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '4rem'
            }
        }
    },
    generalGrid: {
        '& img': {
            display: 'block',
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            objectPosition: 'top left',
            borderRadius: '10px'
        },
        '& video': {
            display: 'block',
            width: '100%',
            height: 'auto',
            objectFit: 'fill',
            borderRadius: '10px'
        }
    }
}))

const generalHeading = '##### **Phone.com eSIMs: Unmatched Flexibility with Seamless Integration!**\n\nNo more compromises between traditional and digital communication! With **Phone.com eSIMs**, experience the best of both worlds—traditional phone calls and texts without relying solely on data, all while integrating perfectly with your existing **SIP phone service** and extensions on Phone.com.'
const items = [
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: 'https://s3.us-west-2.amazonaws.com/assets.phone.com/esim-store/iStock-2004890419+1.png',
                    type: 'image/png'
                }
            ]
        },
        info: '##### **General Information**\n\nPhone.com’s eSIMs provide a modern, flexible communication solution that works seamlessly with your current Phone.com SIP phone service. Enjoy the convenience of making and receiving calls and texts without being entirely dependent on data plans.\n\nWith eSIM technology, there is no need for physical SIM cards—activate instantly and manage all your mobile and VoIP needs from a single, unified platform.'
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: 'https://s3.us-west-2.amazonaws.com/assets.phone.com/esim-store/Frame+1410125952.png',
                    type: 'image/jpg'
                }
            ]
        },
        info: '##### **Easy to Set Up and Use**\n\nActivate your eSIM by scanning a QR code—no tech skills required! Combine it with your existing SIP service and extensions for a unified communication experience.'
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: 'https://s3.us-west-2.amazonaws.com/assets.phone.com/esim-store/Frame+1410125951.png',
                    type: 'image/jpg'
                }
            ]
        },
        info: '##### **Maintain Your Phone Number and Extensions**\n\nKeep your business and personal communication separate while using the same device.\nSeamlessly manage both under one Phone.com account.'
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: 'https://s3.us-west-2.amazonaws.com/assets.phone.com/esim-store/Frame+1410125953.png',
                    type: 'image/jpg'
                }
            ]
        },
        info: '##### **Works Across Multiple Devices**\n\nCompatible with all major eSIM-enabled smartphones and tablets.'
    },
    {
        media: {
            type: 'picture',
            sources: [
                {
                    src: 'https://s3.us-west-2.amazonaws.com/assets.phone.com/esim-store/Frame+1410125951.png',
                    type: 'image/jpg'
                }
            ]
        },
        info: '##### **Flexible Plan Changes**\n\nChange, pause, or upgrade your plans directly from your Phone.com dashboard—no hassle!'
    }
]

/**
 *
 */
const OverviewTab = ({ app }: { app: App }) => {
    const classes = useStyles()

    const heading = app.details.overview?.heading || generalHeading
    const general = Array.isArray(app.details.overview?.general) ? app.details.overview?.general : items

    const render = ({ info, media }, even: boolean) => (
        <>
            <Grid item xs={12} sm={6} md={4}>{media.type === 'picture' ? <Picture sources={media.sources} thumbnail={media.thumbnail} /> : <Video sources={media.sources} attributes={{ poster: media.thumbnail }} app={app} />}</Grid>
            <Grid item xs={12} sm={6} md={8} className={`${classes.generalInfoMarkdown} ${even ? 'right-padding' : 'left-padding'}`}>
                <PDCMarkdown>
                    {info}
                </PDCMarkdown>
            </Grid>
        </>
    )

    return (
        <Grid container className={classes.root}>
            {
                heading &&
                <Grid item xs={12}>
                    <PDCMarkdown>
                        {heading}
                    </PDCMarkdown>
                </Grid>
            }
            {
                general &&
                general.map((gen, index) => (
                    <Grid key={index} item xs={12} container className={classes.generalGrid} direction={index % 2 === 0 ? 'row-reverse' : 'row'}>
                        {
                            render(gen, index % 2 === 0)
                        }
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default OverviewTab
