import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
class API {
    static loadCalls = async (filters, limit, cursor = [], sort = []) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/calls/list-calls`
        console.log(sort)
        const response = await ajax.postAsGet(requestUrl, { filters, limit, cursor, sort, no_recordings: true })
        if (response.message === 'Network Error') return 'network-error'
        return response.data
    }

    static initCallTranscriptSummary = async (call_mongo_id) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/calls/generate-call-summary`
        const response = await ajax.postAsGet(requestUrl, { mongo_id: call_mongo_id })
        if (response.message === 'Network Error') return 'network-error'
        return response.data
    }

    static getCall = async (callId, voipId) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/calls/get-call`
        const response = await ajax.postAsGet(requestUrl, { call_id: callId, voip_id: voipId })
        if (response.message === 'Network Error') return 'network-error'
        return response.data
    }

    static getCallRecordings = async (recording_ids, voicemail_ids) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/calls/get-call-recordings`
        const response = await ajax.postAsGet(requestUrl, { recording_ids, voicemail_ids })
        return response.data
    }

    static makeCall = (callerExtension, calleePhoneNumber, callerCallerId, calleeCallerId) => {
        const body = {
            voip_id: parseInt(PhoneComUser.getAPIAccountId()),
            caller_extension: callerExtension,
            callee_phone_number: calleePhoneNumber,
            caller_caller_id: callerCallerId,
            callee_caller_id: calleeCallerId
        }
        return ajax.post(`${PhoneComUser.getv5ApiRoot()}/calls/make-click-to-call`, body)
            .then(response => response.data)
    }

    static configureCallListeners = async (voip_id) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/configure-default-listeners`
        // let requestUrl = 'https://app.phone.com/communicator/configure-default-listeners'
        try {
            const response = await ajax.post(requestUrl, { voip_id })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    static updateCallFromNumber = (fromCallerId) => {
        const body = {
            voip_phone_id: parseInt(PhoneComUser.getExtensionId()),
            caller_id: fromCallerId
        }
        return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/calls/set-caller-id`, body)
            .then(response => response.data)
    }

    static fetchActiveCallerId = async () => {
        const body = {
            voip_phone_id: parseInt(PhoneComUser.getExtensionId())
        }
        return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/calls/get-caller-id`, body)
            .then(response => response.data)
    }
}

export default API
