import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Btn from 'button'
import { getPhoneCom } from 'phonecom'
import { Button as ButtonInterface } from './../utils/objects'
import { isUserAllowed } from './../utils/utils'

type ButtonType = ButtonInterface & { handleClick: () => void, [key: string]: any }

const useStyles = makeStyles(theme => ({
    primary: {
        fontSize: '14px',
        fontWeight: 600,
        padding: '9px 16px',
        backgroundColor: `${theme.palette.grey[900]}!important`
    },
    secondary: {
        fontSize: '14px',
        fontWeight: 600,
        padding: '9px 16px'
    }
}))

/**
 *
 */
const Button = ({ text, style, visibility, handleClick, ...rest }: ButtonType) => {
    const classes = useStyles()
    const [showBtn, setShowBtn] = useState(false)

    useEffect(() => {
        getPhoneCom().then(phoneCom => {
            const isAllowed = isUserAllowed(phoneCom, visibility)
            setShowBtn(isAllowed)
        })
    }, [])

    return (
        showBtn
            ? <Btn
                variant={style === 'primary' ? 'filled' : 'outlined'}
                color={style === 'secondary' ? 'secondary' : undefined}
                className={style === 'primary' ? classes.primary : classes.secondary}
                onClick={handleClick} {...rest}
                data-testid={style === 'primary' ? 'PrimaryBtn' : 'SecondaryBtn'}
            >
                {text}
            </Btn>
            : <></>
    )
}

export default Button
