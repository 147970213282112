import React, { useContext, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from 'foundations-library/components'
import s from '../style'
import { ScreenSizeContext } from 'providers'

const originalStyle = { ...s() }
const buttonHeight = '36px'

const style = () => ({
    ...originalStyle,
    downloadAllButtonWrapper: {
        ...originalStyle.downloadAllButtonWrapper,
        height: buttonHeight
    },
    downloadAllButton: { float: 'right', borderRadius: '20px' },
    table: {
        width: '100%',
        '& th': {
            height: '34px',
            paddingRight: '20px'
        },
        '& td': {
            height: '62px'
        }
    },
    invoice: {
        width: '75%'
    },
    tdInvoice: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        height: '20px',
        width: '20px',
        marginRight: '8px'
    }
})

const useStyles = makeStyles(style)

/**
 *
 */
const ListLoader = (): JSX.Element => {
    const screenSize = useContext(ScreenSizeContext)
    const styles = useStyles({ screenSize })
    const tableRef = useRef(null)
    const [times, setTimes] = useState(0)
    useEffect(() => {
        if (tableRef?.current) {
            try {
                setTimes(Math.abs(Math.floor(
                    (window.innerHeight - tableRef.current.offsetTop - 34) / 62
                ) - 1))
            } catch (err) {
                console.log('Error calculating height', err)
                setTimes(1)
            }
        }
    }, [])

    return <div data-testid='statements-loader'>
        <div className={styles.downloadAllButtonWrapper}>
            <Skeleton
                variant='rect'
                className={styles.downloadAllButton}
                height={buttonHeight}
                width={127}
                data-testid='statements-loader-button'
            />
        </div>
        <table className={styles.table} data-testid='statements-loader-table' ref={tableRef}>
            <thead>
                <tr>
                    <th className={`${styles.invoice} ${styles.th}`}>
                        <Skeleton
                            variant='rect'
                            height={12}
                            width={50}
                            data-testid='statements-loader-table-header-1'
                        />
                    </th>
                    <th style={{ minWidth: '121px' }}>
                        <Skeleton
                            variant='rect'
                            height={12}
                            width={50}
                            data-testid='statements-loader-table-header-2'
                        />
                    </th>
                    <th style={{ minWidth: '107px' }}>
                        <Skeleton
                            variant='rect'
                            height={12}
                            width={50}
                            data-testid='statements-loader-table-header-3'
                        />
                    </th>
                    <th style={{ minWidth: '100px' }} data-testid='statements-loader-table-header-4'></th>
                </tr>
            </thead>
            <tbody>
                {times && ([...Array(times)].map((x, i) => i + 1)).map((x) =>
                    <tr data-testid={`statements-loader-table-tr-${x}`} key={x}>
                        <td>
                            <div className={styles.tdInvoice}>
                                <Skeleton
                                    variant='rect'
                                    className={styles.icon}
                                    data-testid={`statements-loader-table-tr-${x}-td-1-icon`}
                                />
                                <Skeleton
                                    variant='rect'
                                    height={15}
                                    width={72}
                                    data-testid={`statements-loader-table-tr-${x}-td-1-text`}
                                />
                            </div>
                        </td>
                        <td>
                            <Skeleton
                                variant='rect'
                                height={15}
                                width={72}
                                data-testid={`statements-loader-table-tr-${x}-td-2`}
                            />
                        </td>
                        <td>
                            <Skeleton
                                variant='rect'
                                height={15}
                                width={54}
                                data-testid={`statements-loader-table-tr-${x}-td-3`}
                            />
                        </td>
                        <td>
                            <Skeleton
                                variant='rect'
                                height={20}
                                width={10}
                                style={{ marginLeft: '26px' }}
                                data-testid={`statements-loader-table-tr-${x}-td-4`}
                            />
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </div>
}

export default ListLoader
