/* eslint-disable indent */
import React, { useState, createContext, useContext, useReducer, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import api from 'api'
import { TeammateContext } from 'providers'
import { getValue } from 'remote-config-value'
import { App } from 'apps-marketplace/src/utils/objects'
import PdcOpenConnection from 'pdc-open-connection'
import { planReducer, orderEsimsReducer, esimsReducer, assignUserReducer, updateEsimReducer, terminateEsimReducer, refreshQRCodeReducer } from './Reducers'

const eventTopic = 'TANGO_ESIM'

enum events {
    ordered = 'esim-ordered',
    provisioned = 'esim-provisioned',
    userAssigned = 'esim-user-assigned',
    userUpdated = 'esim-user-updated',
    codeRefreshed = 'esim-code-refreshed',
    terminated = 'esim-deleted'
}

interface eventData {
    data: Record<string, any>
    event_name: events
    timestamp: string
}

interface Order { esimPlanId: number, count: number, paymentCard: number, preview: boolean }

interface CommonStateInterface {
    isLoading: boolean
    error: string
}

/**
 *
 */
export interface PlansInterface extends CommonStateInterface {
    plans: Record<string, any>[]
}

interface OrderEsimsInterface extends CommonStateInterface {
    data: Record<string, any>
}

interface EsimsInterface extends CommonStateInterface {
    esims: Record<string, any>[]
}

/**
 *
 */
export interface AssignUserInterface extends CommonStateInterface {
    data: Record<string, any>
}

/**
 *
 */
export interface updateEsimInterface extends CommonStateInterface {
    esim: Record<string, any>
}

interface terminateEsimInterface extends CommonStateInterface {
    esim: Record<string, any>
}

interface refreshQRCodeInterface extends CommonStateInterface {
    data: Record<string, any>
}

type ResetTypes = 'FETCH_PLANS' | 'ORDER_ESIMS' | 'FETCH_ESIMS' | 'ASSIGN_USER' | 'UPDATE_ESIM' | 'TERMINATE_ESIM' | 'REFRESH_QR_CODE'

interface EsimContextInterface {
    app: App | null
    plans: PlansInterface
    fetchPlans: () => Promise<any>
    orderEsims: OrderEsimsInterface
    processOrderEsims: (order: Order) => Promise<any> // eslint-disable-line @typescript-eslint/naming-convention
    esims: EsimsInterface
    fetchEsims: (myEsims?: boolean, revalidate?: boolean) => Promise<any>
    assignUser: AssignUserInterface
    assignUserHandler: (service_id: string, extension_id: number, did: string) => Promise<any>
    updatedEsim: updateEsimInterface
    updateEsimHandler: (service_id: string, did: string) => Promise<any>
    terminateEsim: terminateEsimInterface
    terminateEsimHandler: (service_id: string, terminate_reasons: string[], terminate_feedback: string) => Promise<any>
    refreshQRCode: refreshQRCodeInterface
    refreshQRCodeHandler: (service_id: string) => Promise<any>
    reset: (type: ResetTypes) => void
}

/**
 *
 */
export const EsimContext = createContext<EsimContextInterface>({
    app: null,
    plans: { plans: [], isLoading: false, error: '' },
    fetchPlans: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    orderEsims: { data: {}, isLoading: false, error: '' },
    processOrderEsims: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function, @typescript-eslint/naming-convention

    esims: { esims: [], isLoading: false, error: '' },
    fetchEsims: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    assignUser: { data: {}, isLoading: false, error: '' },
    assignUserHandler: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    updatedEsim: { esim: {}, isLoading: false, error: '' },
    updateEsimHandler: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    terminateEsim: { esim: {}, isLoading: false, error: '' },
    terminateEsimHandler: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    refreshQRCode: { data: {}, isLoading: false, error: '' },
    refreshQRCodeHandler: async () => { }, // eslint-disable-line @typescript-eslint/no-empty-function

    reset: () => { } // eslint-disable-line @typescript-eslint/no-empty-function
})

/**
 *
 */
export const EsimProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [app, setApp] = useState<App | null>(null)
    const [plans, dispatchPlans] = useReducer(planReducer, { plans: [], isLoading: false, error: '' })
    const [orderEsims, dispatchOrderEsims] = useReducer(orderEsimsReducer, { data: {}, isLoading: false, error: '' })
    const [esims, dispatchEsims] = useReducer(esimsReducer, { esims: [], isLoading: false, error: '' })
    const [assignUser, dispatchAssignUser] = useReducer(assignUserReducer, { data: {}, isLoading: false, error: '' })
    const [updatedEsim, dispatchUpdateEsim] = useReducer(updateEsimReducer, { esim: {}, isLoading: false, error: '' })
    const [terminateEsim, dispatchTerminateEsim] = useReducer(terminateEsimReducer, { esim: {}, isLoading: false, error: '' })
    const [refreshQRCode, dispatchRefreshQRCode] = useReducer(refreshQRCodeReducer, { data: {}, isLoading: false, error: '' })

    const { teammates } = useContext(TeammateContext)
    const location = useLocation()

    const fetchPlans = async (revalidate = false) => {
        if (!revalidate && plans.plans.length) return plans.plans

        let fetchedPlans

        try {
            dispatchPlans({ type: 'SET_LOADING', payload: true })

            const response = await api.getEsimPlans()
            fetchedPlans = response.data

            dispatchPlans({ type: 'SET_PLANS', payload: fetchedPlans })
        } catch (error) {
            console.error('Failed to fetch eSIM plans:', error)
            dispatchPlans({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchPlans({ type: 'SET_LOADING', payload: false })
        }

        return fetchedPlans
    }

    const processOrderEsims = async (order: Order) => { // eslint-disable-line @typescript-eslint/naming-convention
        let orderedEsims

        try {
            dispatchOrderEsims({ type: 'SET_LOADING', payload: true })

            const response = await api.orderEsims(order)
            orderedEsims = response.data

            dispatchOrderEsims({ type: 'SET_ORDER', payload: orderedEsims })
        } catch (error) {
            console.error('Failed to order eSIMs:', error)
            dispatchOrderEsims({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchOrderEsims({ type: 'SET_LOADING', payload: false })
        }

        return orderedEsims
    }

    const fetchEsims = async (myEsims = false, revalidate = false) => {
        if (!revalidate && esims.esims.length) return esims.esims

        let fetchedEsims

        try {
            dispatchEsims({ type: 'SET_LOADING', payload: true })

            const response = await api.fetchEsims(myEsims)
            fetchedEsims = response.data

            fetchedEsims = fetchedEsims.map(esim => {
                if (esim.user) {
                    const user = teammates?.find(esim.user.voip_phone_id) || {}
                    esim.user = { ...esim.user, ...user }
                }
                return esim
            })

            dispatchEsims({ type: 'SET_ESIMS', payload: fetchedEsims })
        } catch (error) {
            console.error('Failed to fetch eSIMs:', error)
            dispatchEsims({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchEsims({ type: 'SET_LOADING', payload: false })
        }

        return fetchedEsims
    }

    const assignUserHandler = async (service_id: string, extension_id: number, did: string) => {
        let data

        try {
            dispatchAssignUser({ type: 'SET_LOADING', payload: true })

            const response = await api.assignUserToEsim(service_id, extension_id, did)
            data = response.data

            dispatchAssignUser({ type: 'SET_ASSIGN_USER', payload: data })
        } catch (error) {
            console.error('Failed to Assign User to eSIM:', error)
            dispatchAssignUser({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchAssignUser({ type: 'SET_LOADING', payload: false })
        }

        return data
    }

    const updateEsimHandler = async (service_id: string, did: string) => {
        let esim

        try {
            dispatchUpdateEsim({ type: 'SET_LOADING', payload: true })

            const response = await api.updateEsim(service_id, did)
            esim = response.data

            dispatchUpdateEsim({ type: 'SET_UPDATE_ESIM', payload: esim })
        } catch (error) {
            console.error('Failed to update eSIM:', error)
            dispatchUpdateEsim({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchUpdateEsim({ type: 'SET_LOADING', payload: false })
        }

        return esim
    }

    const terminateEsimHandler = async (service_id: string, terminate_reasons: string[], terminate_feedback: string) => {
        let esim

        try {
            dispatchTerminateEsim({ type: 'SET_LOADING', payload: true })

            const response = await api.terminateEsim(service_id, terminate_reasons, terminate_feedback)
            esim = response?.data || { service_id, status: 'deleted' }

            dispatchTerminateEsim({ type: 'SET_TERMINATE_ESIM', payload: esim })
        } catch (error) {
            console.error('Failed to terminate eSIM:', error)
            dispatchTerminateEsim({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchTerminateEsim({ type: 'SET_LOADING', payload: false })
        }

        return esim
    }

    const refreshQRCodeHandler = async (service_id: string) => {
        let data

        try {
            dispatchRefreshQRCode({ type: 'SET_LOADING', payload: true })

            const response = await api.refreshQRCode(service_id)
            data = response.data

            dispatchRefreshQRCode({ type: 'SET_QR_CODE', payload: data })
        } catch (error) {
            console.error('Failed to refresh QR Code:', error)
            dispatchRefreshQRCode({ type: 'SET_ERROR', payload: error.message })
        } finally {
            dispatchRefreshQRCode({ type: 'SET_LOADING', payload: false })
        }

        return data
    }

    const reset = (type: ResetTypes) => {
        switch (type) {
            case 'FETCH_PLANS':
                dispatchPlans({ type: 'RESET', payload: '' })
                break
            case 'ORDER_ESIMS':
                dispatchOrderEsims({ type: 'RESET', payload: '' })
                break
            case 'FETCH_ESIMS':
                dispatchEsims({ type: 'RESET', payload: '' })
                break
            case 'ASSIGN_USER':
                dispatchAssignUser({ type: 'RESET', payload: '' })
                break
            case 'UPDATE_ESIM':
                dispatchUpdateEsim({ type: 'RESET', payload: '' })
                break
            case 'TERMINATE_ESIM':
                dispatchTerminateEsim({ type: 'RESET', payload: '' })
                break
            case 'REFRESH_QR_CODE':
                dispatchRefreshQRCode({ type: 'RESET', payload: '' })
                break
            default:
                console.warn('Invalid Esim Handler Type')
                break
        }
    }

    const eventHandler = (id: number, eventdata: eventData) => {
        console.log('esim eventHandler triggered:', id, eventdata)
        switch (eventdata.event_name) {
            case events.provisioned: {
                const provisionedEsim = eventdata.data
                const updatedEsims = esims.esims.map(esim => {
                    if (esim.service_id === provisionedEsim.service_id) {
                        return provisionedEsim
                    }
                    return esim
                })
                if (updatedEsims.length) dispatchEsims({ type: 'SET_ESIMS', payload: updatedEsims })
                break
            }
            default:
                break
        }
    }

    useEffect(() => {
        PdcOpenConnection.onAccount(eventTopic, eventHandler)

        return () => {
            PdcOpenConnection.removeCallback(eventTopic, eventHandler)
        }
    }, [JSON.stringify(esims.esims)])

    useEffect(() => {
        if (plans.error) dispatchPlans({ type: 'SET_ERROR', payload: '' })
        if (esims.error) dispatchEsims({ type: 'SET_ERROR', payload: '' })
        // reset('ORDER_ESIMS')
        reset('ASSIGN_USER')
        reset('UPDATE_ESIM')
        reset('TERMINATE_ESIM')
        reset('REFRESH_QR_CODE')
    }, [location])

    useEffect(() => {
        const getEsimApp = (appsJson: string) => {
            if (typeof appsJson === 'string') {
                const apps: App[] = JSON.parse(appsJson)
                if (apps instanceof Array) {
                    const esimAppIdentifier = getValue('esim_app_identifier').toLowerCase()
                    const esimApp = apps.find(app => app.identifier?.toLowerCase() === esimAppIdentifier)
                    setApp(esimApp)
                }
            }
        }

        const appsJson: string | undefined = getValue('apps_marketplace_json', getEsimApp, false)
        if (appsJson) getEsimApp(appsJson)
    }, [])

    return (
        <EsimContext.Provider value={{ app, plans, fetchPlans, orderEsims, processOrderEsims, esims, fetchEsims, assignUser, assignUserHandler, updatedEsim, updateEsimHandler, terminateEsim, terminateEsimHandler, refreshQRCode, refreshQRCodeHandler, reset }}>
            {children}
        </EsimContext.Provider>
    )
}
