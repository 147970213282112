import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Icon } from 'foundations-library/components'
import { useHistory } from 'react-router-dom'
import { EsimContext } from 'providers'
import PhoneComUser from 'phone-com-user'

const useStyles = makeStyles(theme => ({
    articleContainer: {
        '& .article': {
            columnGap: '10px',
            padding: '1.25rem',
            borderRadius: '10px',
            border: '1px solid #8C8C8C',
            '& .cellular-icon': {
                flexShrink: 0
            }
        }
    }
}))

type HowToArticle = { title: string }

/**
 *
 */
export const Help = ({ article }: { article: HowToArticle }): JSX.Element => {
    const classes = useStyles()
    const history = useHistory()
    const { app } = useContext(EsimContext)

    /**
     *
     */
    const processLink = () => {
        if (!app) return

        const extensionId = PhoneComUser.getExtensionId()
        const appURI = encodeURI(app.title.toLowerCase())
        const link = `/e${extensionId}/apps-marketplace?app=${appURI}&redirectTo=how-to-articles&item=${encodeURI(article.title)}`
        history.push(link)
    }

    return (
        <Grid key={article.title} item xs={12} sm={6} md={4} container direction='row' wrap='nowrap' className={classes.articleContainer}>
            <Grid container wrap='nowrap' alignItems='center' className='article'>
                <Icon name={'cellular'} size='large' color='neutral-900' className='cellular-icon' data-testid='eSIM-icon' />
                <Grid direction='column'>
                    <Typography variant='h8'>{article.title}</Typography>
                    <Button color='secondary' variant='text' iconLeft={false} icon='go' onClick={processLink}>Learn more</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
