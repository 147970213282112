/* eslint-disable @typescript-eslint/naming-convention */
import LiveReceptionistIcon from '../icons/LiveReceptionist.svg'
import IntelligentAnswerBotIcon from '../icons/IntelligentAnswerBot.svg'
import ZohoIcon from '../icons/Zoho.svg'
import DevicesIcon from '../icons/Devices.svg'
import NumberManagerIcon from '../icons/NumberManager.svg'
import { log } from './utils'

interface Media {
    type: 'picture'|'video',
    sources: {
        src: string,
        [key:string]: any
    }[],
    thumbnail?: string
}

type OverviewGeneral = { media: Media, info: string }
type FAQ = { question: string, answer: string }
type HowToVideo = (Required<Omit<Media, 'type'>> & { info: string })
type HowToArticle = { title: string }
type HowToArticleData = { media: Media, info: { title: string, description?: string } }

interface AppDetails {
    averageRating: number,
    totalRatings: number,
    media: Media[],
    overview: {
        heading?: string,
        general?: OverviewGeneral[]
    },
    faq?: FAQ[],
    howTo?: {
        videos: HowToVideo[],
        articles: HowToArticle[]
    },
    description?: string
}

enum AppType {
    Modal = 'MODAL',
    Integrated = 'INTEGRATED',
    Internal = 'INTERNAL',
    External = 'EXTERNAL'
}

interface UserTypes {
    nxt: {
        admin: boolean,
        nonAdmin: boolean
    },
    classic: {
        admin: boolean,
        nonAdmin: boolean
    }
}

interface Button {
    text: string,
    style: 'primary' | 'secondary',
    visibility: UserTypes,
    action: {
        type: AppType,
        modal?: string
        url?: string
    }
}

interface App {
    id: number,
    title: string,
    company: string,
    icon: string,
    inlineIcon?: string,
    hasInstalled: boolean,
    learnMoreText: string,
    learnMoreLink: string,
    details: AppDetails,
    userTypes: UserTypes,
    url?: string,
    appType?: AppType
    buttons?: Button[],
    identifier?: string
}

interface RouterProps {
    history: Record<string, any>
    location: Record<string, any>
    match: Record<string, any>
    staticContext?: any
}

const media: Media[] = [
    {
        type: 'picture',
        sources: [
            {
                src: 'https://www.ringcentral.com/us/en/blog/wp-content/uploads/2021/07/Phone-blog.png',
                type: 'image/png'
            }
        ]
    },
    {
        type: 'picture',
        sources: [
            {
                src: 'https://www.linguahouse.com/linguafiles/md5/032c7282f877b86c74e0a97d0b8bb7e8',
                type: 'image/jpg'
            }
        ]
    },
    {
        type: 'video',
        sources: [
            {
                src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                type: 'video/mp4'
            }
        ]
    },
    {
        type: 'video',
        sources: [
            {
                src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
                type: 'video/mp4'
            }
        ]
    }
]

const LiveReceptionist: App = {
    id: 1,
    title: 'Live Receptionist',
    company: 'Phone.com',
    icon: LiveReceptionistIcon,
    hasInstalled: true,
    learnMoreText: 'Learn more',
    learnMoreLink: '/live-receptionist/create/8',
    details: {
        averageRating: 4.5,
        totalRatings: 15,
        media,
        overview: {
            heading: 'This is heading content.',
            general: [
                {
                    media: media[0],
                    info: 'Live Receptionist is an excellent app.'
                }
            ]
        },
        faq: [{ question: 'This is a question1?', answer: 'This is an answer1.' }, { question: 'This is a question2?', answer: 'This is an answer2.' }],
        version: 'This is Version'
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/live-receptionist/create/8',
    identifier: 'Receptionist'
}

const IntelligentAnswerBot = {
    id: 2,
    title: 'AI-Connect',
    company: 'Phone.com',
    icon: IntelligentAnswerBotIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: 'https://www.phone.com/splash-pages/add-intelligent-answer-bot/',
    details: {
        averageRating: 4.6,
        totalRatings: 20,
        media,
        overview: {
            heading: 'Phone.com\'s AI-powered Intelligent Answer Bot routes callers according to your instructions 24*7 so that you can connect your callers with the right person. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/live-receptionist/create/4'
}

const ZohoCRM = {
    id: 3,
    title: 'Zoho CRM',
    company: 'Zoho, Inc.',
    icon: ZohoIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: '',
    details: {
        averageRating: 4.7,
        totalRatings: 25,
        media,
        overview: {
            heading: 'Zoho CRM for Phone.com brings integrated cloud business communications and CRM together, for users to manage all the call related activities inside CRM. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: 'https://marketplace.zoho.com/app/crm/phone-com-for-zoho-crm',
    appType: AppType.Integrated
}

const Devices = {
    id: 4,
    title: 'Devices',
    company: 'Phone.com',
    icon: DevicesIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: '/phones/add-phones',
    details: {
        averageRating: 4.8,
        totalRatings: 25,
        media,
        overview: {
            heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/phones/add-phones'
}

const NumberManager = {
    id: 5,
    title: 'Number Manager',
    company: 'Phone.com',
    icon: NumberManagerIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: '/phone-numbers/add-numbers/vanity',
    details: {
        averageRating: 4.9,
        totalRatings: 30,
        media,
        overview: {
            heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/phone-numbers/add-numbers/vanity'
}

const apps: App[] = [
    LiveReceptionist,
    IntelligentAnswerBot,
    ZohoCRM,
    Devices,
    NumberManager
]

const installedAppOptions = [
    {
        text: 'Uninstall',
        onClick: () => { log('Uninstall') },
        disabled: false,
        tooltipText: null
    },
    {
        text: 'Open',
        onClick: () => { log('Open') },
        disabled: false,
        tooltipText: null
    }
]
const installedAppStatus = [
    {
        content: 'Active',
        value: 1
    },
    {
        content: 'Inactive',
        value: 0
    }
]

/**
 *
 */
export const User = {
    user_id: 1,
    first_name: 'Pro',
    last_name: 'User',
    email: 'proUser@phone.com',
    role: 'account',
    user_account_type: 'pro',
    stage: 'PROD',
    v4ApiRoot: 'https://api.phone.com/v4',
    v5ApiRoot: 'https://preprod.my.phone.com/api/services',
    v5ToolsRoot: 'https://preprod.my.phone.com/api/tools',
    cp_token: 'abcdef',
    ac_token: 'abcdefgh',
    company: 'United People',
    extension: 601,
    features: new Set([]),
    extensions: [],
    true_account_id: 1857254,
    user_default_extension_id: 4027332,
    voip_id: '5464474',
    voip_phone_id: 4027553,
    user_tiered: true
}

/**
 *
 */
export { AppType, apps, installedAppOptions, installedAppStatus, media }
/**
 *
 */
export type { App, OverviewGeneral, FAQ, HowToVideo, HowToArticle, HowToArticleData, RouterProps, Button, UserTypes, Media }
