import React from 'react'
// import { SpinnerIcon } from 'pdc-svg-icons'
import { SpinnerIcon } from '../pdc-svg-icons/SvgIcons'
import { makeStyles } from '@material-ui/styles'
import './spinner.css'

const useStyles = makeStyles(() => ({
    image: {
        width: '30px',
        height: '30px',
        animation: 'rotate-spinner-3298fadkj 1s infinite steps(12)'
    },
    bigImage: {
        width: '50px !important',
        height: '50px !important'
    },
    biggerImage: {
        width: '60px !important',
        height: '60px !important'
    },
    smallImage: {
        width: '15px !important',
        height: '15px !important'
    }
}))

interface SpinnerProps {
    color?: string,
    className?: string,
    size?: string
}

/**
 *
 */
const Spinner = (props: SpinnerProps) : JSX.Element => {
    const classes = useStyles()
    const colorProp = props.color ? { color: props.color } : {}

    const classNames = [classes.image]
    if (props.className) {
        classNames.push(props.className)
    }
    if (props.size === 'big') {
        classNames.push(classes.bigImage)
    } else if (props.size === 'bigger') {
        classNames.push(classes.biggerImage)
    } else if (props.size === 'small') {
        classNames.push(classes.smallImage)
    }

    return <SpinnerIcon {...props} {...colorProp} className={classNames.join(' ')}/>
}

export default Spinner
