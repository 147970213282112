import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Plan from './Plan'
import eSIM from './../../../../../../icons/eSIM.png'
import { useEsimStepperContext } from '../../../../../../contexts/EsimStepperContext'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        marginBottom: '2rem'
    }
}))

/**
 *
 */
const Plans = ({ plans }: { plans: Record<string, any>[] }) => {
    const classes = useStyles()
    const { selectedPlan } = useEsimStepperContext()

    return (
        <div className={classes.root} data-testid='plans'>
            {
                plans.map((plan: Record<string, any>) => (
                    <Plan key={plan.billing_code} icon={eSIM} title={plan.name} subTitle={plan.description}
                        price={plan.price} priceText={'Per user/month'} id={plan.billing_code} isSelected={selectedPlan.id === plan.billing_code} />
                ))
            }
        </div>
    )
}

export default Plans
