import React, { useState, createContext, useContext, useEffect } from 'react'

/**
 *
 */
export interface StepInterface {
    title: string
    subtitle?: string
    value: string | number
    completed: boolean
    error: boolean
}

/**
 *
 */
export const steps = ['Select your plan', 'Confirm your order']
/**
 *
 */
export const stepItems: StepInterface[] = steps.map((step, i) => ({
    title: step,
    subtitle: undefined,
    value: i,
    completed: false,
    error: false
}))

/**
 *
 */
export interface SelectedPlanInterface {
    id: number
    price: number
    title: string
}

/**
 *
 */
export interface EsimStepperInterface {
    items: StepInterface[]
    setItems: React.Dispatch<React.SetStateAction<StepInterface[]>>
    step: number
    setStep: React.Dispatch<React.SetStateAction<number>>
    count: number
    setCount: React.Dispatch<React.SetStateAction<number>>
    selectedPlan: SelectedPlanInterface
    setSelectedPlan: React.Dispatch<React.SetStateAction<SelectedPlanInterface>>
    card: number | undefined
    setCard: React.Dispatch<React.SetStateAction<number | undefined>>
    handleChangeStep: (stepIndex: number) => void
    currentCardTab: 'list' | 'create'
    setCurrentCardTab: React.Dispatch<React.SetStateAction<'list' | 'create'>>
    goBackToPrevStep: () => void
    goToNextStep: () => void
}

/**
 *
 */
export const EsimStepperContext = createContext<EsimStepperInterface>({
    items: stepItems,
    /**
     *
     */
    setItems: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    step: 0,
    /**
     *
     */
    setStep: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    count: 0,
    /**
     *
     */
    setCount: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    selectedPlan: { id: 0, price: 0, title: '' },
    /**
     *
     */
    setSelectedPlan: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    card: undefined,
    /**
     *
     */
    setCard: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    /**
     *
     */
    handleChangeStep: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    currentCardTab: 'list',
    /**
     *
     */
    setCurrentCardTab: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    /**
     *
     */
    goBackToPrevStep: () => { }, // eslint-disable-line @typescript-eslint/no-empty-function
    /**
     *
     */
    goToNextStep: () => { } // eslint-disable-line @typescript-eslint/no-empty-function
})

/**
 *
 */
export const useEsimStepperContext = () => useContext(EsimStepperContext)

/**
 *
 */
const EsimStepperProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [items, setItems] = useState<StepInterface[]>(stepItems)
    const [step, setStep] = useState(0)
    const [count, setCount] = useState(0)
    const [selectedPlan, setSelectedPlan] = useState<SelectedPlanInterface>({ id: 0, price: 0, title: '' })
    const [card, setCard] = useState<number>()
    const [currentCardTab, setCurrentCardTab] = useState<'list' | 'create'>('list')

    const handleChangeStep = (stepIndex: number) => {
        let isAllowed = true
        if (step < stepIndex) { // foFwd
            isAllowed = items.some((item, index) => index === stepIndex && items[step].completed)
        }
        if (!isAllowed) return
        setStep(stepIndex)
    }

    const goBackToPrevStep = () => setStep(step => step - 1)

    const goToNextStep = () => {
        setStep(step => step + 1)
        setItems(items => (
            items.map((item, index) => {
                const newItem = { ...item }
                if (index === step) newItem.completed = true
                return newItem
            })
        ))
    }

    useEffect(() => {
        if (selectedPlan.title) setCount(1)
    }, [selectedPlan.title])

    console.log('Esim', items, step, count, selectedPlan, card, currentCardTab)

    return (
        <EsimStepperContext.Provider value={{ items, setItems, step, setStep, handleChangeStep, count, setCount, selectedPlan, setSelectedPlan, card, setCard, currentCardTab, setCurrentCardTab, goBackToPrevStep, goToNextStep }}>
            {children}
        </EsimStepperContext.Provider>
    )
}

export default EsimStepperProvider
