import React from 'react'
import style from './style'
import { makeStyles } from '@material-ui/core/styles'
import VerbalColorsType from '../../../helpers/types/VerbalColor.interface'

const useStyles = makeStyles(style)

interface PropsInterface {
    children: string | number | JSX.Element | JSX.Element[];
    color?: VerbalColorsType,
    className?: string;
    'data-testid'?: string;
}
/**
 *
 */
const Notification = ({
    children,
    color = 'primary',
    className,
    'data-testid': dataTestId = 'notification'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()

    return (
        <span className={`${styles.notification} ${className} notification--bg-${color}`} data-testid={dataTestId}>{children}</span>
    )
}

export default Notification
