/**
 *
 */
const style = (t) => {
    return {
        checkbox: {
            height: '24px',
            '& .checkbox--icon': {
                minWidth: '24px'
            },
            '& .checkbox--icon-right': {
                marginLeft: '5px'
            },
            '& .checkbox--icon-left': {
                marginRight: '5px'
            },
            '& .checkbox--icon-wrapper': {
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center'
            },
            '& input[type="checkbox"]': {
                display: 'none'
            }
        },
        label: {
            cursor: 'pointer',
            alignItems: 'start'
        }
    }
}

export default style
