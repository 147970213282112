import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'foundations-library/components'
import Grid from '@material-ui/core/Grid'
import { App } from './../../../utils/objects'
import HowToVideos from './HowToVideos'
import HowToArticles from './HowToArticles'

const useStyles = makeStyles(theme => ({
    root: {
        rowGap: '2.5rem'
    },
    searchContainer: {
        margin: 'auto'
    }
}))

/**
 *
 */
const HowTo = ({ app }: { app: App }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={8} lg={6} className={classes.searchContainer}>
                <TextField leadingIcon='search' placeholder='How do I activate my eSIM?' className='search-input' />
            </Grid>
            <HowToVideos app={app} />
            <HowToArticles app={app} />
        </Grid>
    )
}

export default HowTo
