import React, { useEffect, useState } from 'react'
import TabsSubmenu from './TabsSubmenu'
import PMLoader from './PMLoader'
import ItemsList from './List'
import { makeStyles } from '@material-ui/core'
import PaymentMethodsCreate, { ButtonsConfType } from '../screens/Create/Create'
import { usePaymentMethodContext } from 'providers'

const jss = (t) => {
    const { colors } = t['foundations-library']
    return {
        paymentMethodsSelector: {
            '& .payment-method--selected': {
                border: `2px solid ${colors.neutral[600]}`
            },
            '& .payment-methods--list-item': {
                cursor: 'pointer'
            }
        }
    }
}

const useStyle = makeStyles(jss)

interface PropsInterface {
    value?: number;
    onChange(v): any;
    onCancel(): any;
    onConfirm?(v): any;
    disabled?: boolean;
    afterCreateCb?(newPMethod): any;
    activeTab?: string;
    onTabChange?(v): any;
    selectPrimaryByDefault?: boolean;
    buttonsConf?: ButtonsConfType;
    'data-testid'?: string;
}

/**
 *
 */
const index = ({
    value,
    onChange,
    onCancel,
    disabled = false,
    afterCreateCb,
    activeTab = 'list',
    onTabChange,
    selectPrimaryByDefault = true,
    buttonsConf,
    'data-testid': dataTestId = 'payment-methods-selector'

}: PropsInterface): JSX.Element => {
    const { items, getData, primary } = usePaymentMethodContext()

    const styles = useStyle()

    const [loading, setLoading] = useState(false)
    const [selectorActiveTab, setSelectorActiveTab] = useState(activeTab)
    const [selected, setSelected] = useState(value)

    useEffect(() => !disabled && onChange && onChange(selected), [selected])
    useEffect(() => setSelected(value), [value])
    useEffect(() => setSelectorActiveTab(activeTab), [activeTab])
    useEffect(() => onTabChange && onTabChange(selectorActiveTab), [selectorActiveTab])

    useEffect(() => {
        setLoading(true)
        getData().then(() => {
            if (
                selectPrimaryByDefault &&
                !disabled &&
                primary &&
                !selected
            ) setSelected(primary.id)

            setLoading(false)
        }).finally(() => setLoading(false))
    }, [])

    return (
        <div className={`${styles.paymentMethodsSelector}`} data-testid={dataTestId}>
            {loading && <PMLoader />}
            {!loading && (
                <div className='payment-methods--content'>
                    <TabsSubmenu value={selectorActiveTab} onChange={(v) => setSelectorActiveTab(v)} />
                    {
                        selectorActiveTab === 'list' &&
                        items &&
                        <ItemsList
                            value={selected}
                            onChange={(id) => !disabled && setSelected(id)}
                            items={items}
                            disabled={disabled}
                        />
                    }
                    {selectorActiveTab === 'create' &&
                        <PaymentMethodsCreate
                            afterCreateCb={(v) => afterCreateCb && afterCreateCb(v)}
                            onCancel={() => onCancel && onCancel()}
                            buttonsConf={buttonsConf}
                        />
                    }
                </div>
            )}
        </div>
    )
}

/**
 *
 */
export type SelectorType = PropsInterface

export default index
