import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography, Button } from 'foundations-library/components'
import { useHistory } from 'react-router-dom'
import { EsimHelp } from 'widgets'
import PhoneComUser from 'phone-com-user'
import { EsimContext } from 'providers'
import { details } from './../../utils/objects'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px'
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        gap: '10px'
    }
})

/**
 *
 */
const Help = () => {
    const classes = useStyles()
    const history = useHistory()
    const { app } = useContext(EsimContext)
    const { help } = details

    const processLink = () => {
        if (!app) return

        const extensionId = PhoneComUser.getExtensionId()
        const appURI = encodeURI(app.title.toLowerCase())
        const link = `/e${extensionId}/apps-marketplace?app=${appURI}&redirectTo=how-to-articles`

        history.push(link)
    }

    return (
        <div className={`${classes.root} help-widget`}>
            <div className={classes.top}>
                <Typography variant='subtitle1'>{help.title}</Typography>
                <Button color='primary' iconLeft={false} icon='go' variant='text' onClick={processLink}>View all how-to-articles</Button>
            </div>
            <Grid container spacing={3}>
                {
                    help.articles.map(article => <EsimHelp key={article.title} article={article} />)
                }
            </Grid>
        </div>
    )
}

export default Help
