/* eslint-disable indent */

/**
 *
 */
const planReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PLANS':
            return { ...state, plans: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, plans: [], error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { plans: [], isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
const orderEsimsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ORDER':
            return { ...state, data: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, data: {}, error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { data: {}, isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
const esimsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ESIMS':
            return { ...state, esims: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, esims: [], error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { esims: [], isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
const assignUserReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ASSIGN_USER':
            return { ...state, data: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, data: {}, error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { data: {}, isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
const updateEsimReducer = (state, action) => {
    switch (action.type) {
        case 'SET_UPDATE_ESIM':
            return { ...state, esim: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, esim: {}, error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { esim: {}, isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
const terminateEsimReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TERMINATE_ESIM':
            return { ...state, esim: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, esim: {}, error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { esim: {}, isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
const refreshQRCodeReducer = (state, action) => {
    switch (action.type) {
        case 'SET_QR_CODE':
            return { ...state, data: action.payload }
        case 'SET_LOADING':
            if (action.payload === true) return { ...state, isLoading: true, data: {}, error: '' }
            return { ...state, isLoading: false }
        case 'SET_ERROR':
            return { ...state, error: action.payload }
        case 'RESET':
            return { data: {}, isLoading: false, error: '' }
        default:
            return state
    }
}

/**
 *
 */
export { planReducer, orderEsimsReducer, esimsReducer, assignUserReducer, updateEsimReducer, terminateEsimReducer, refreshQRCodeReducer }
