/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect, useState } from 'react'
import CurrentBalance from '../components/CurrentBalance.tsx'
import PreviusStatement from '../components/PreviusStatement.tsx'
import PaymentMethodCard from '../components/PaymentMethodCard.tsx'
// import PlanDetails from '../components/PlanDetails.tsx'
import { makeStyles } from '@material-ui/core'
import Loader from '../components/Loader'
import { useBillingContext } from 'providers'
import { Alert, Typography } from 'foundations-library/components'

const style = () => ({
    alert: {
        textAlign: 'left',
        alignItems: 'stretch'
    },
    container: {
        width: '900px',
        display: 'flex',
        margin: 'auto',
        '& .column': {
            width: '50%'
        },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            width: '100%',
            '& .column': {
                width: '100%',
                padding: '0px !important'
            }
        }
    }
})
const useStyles = makeStyles(style)

interface PropsInterface {
    routeProps?: any
    setActiveTab(v): any;
}

/**
 *
 */
const App = ({
    routeProps,
    setActiveTab
}: PropsInterface): JSX.Element => {
    const { paymentMethodsItems, paymentMethodsPrimary, getData, reloadInvoiceDetails, invoiceDetails } = useBillingContext()

    const classes = useStyles()
    const [loading, setLoading] = useState(false)

    const loadData = async () => {
        setLoading(true)
        await getData()
        setLoading(false)
    }

    const onSuccessfullPaymentCb = async () => {
        await reloadInvoiceDetails()
    }

    useEffect(() => {
        loadData()
    }, [])
    return (
        <>
            {loading && <Loader />}
            {!loading &&
                invoiceDetails &&
                invoiceDetails.cb_is_past_due &&
                <Alert marginY={20} className={classes.alert}>
                    <Typography color='white' remoteConfigID='exceeded_due_date_alert' variant='subtitle2'/>
                </Alert>
            }
            {!loading && <div data-testid="index-container" className={classes.container}>
                {invoiceDetails && (<>
                    <div data-testid="index-column-1" className={'column'} style={{ paddingRight: '10px' }}>
                        <CurrentBalance
                            invoiceDetails={invoiceDetails}
                            primaryCard={paymentMethodsPrimary}
                            allCards={paymentMethodsItems}
                            onSuccessfullPaymentCb={onSuccessfullPaymentCb}
                        />
                        <PreviusStatement routeProps={routeProps} setActiveTab={setActiveTab} invoiceDetails={invoiceDetails} />
                    </div>
                    <div data-testid="index-column-2" className={'column'} style={{ paddingLeft: '10px' }}>
                        <PaymentMethodCard routeProps={routeProps} setActiveTab={setActiveTab} primaryCard={paymentMethodsPrimary} />
                        {/* <PlanDetails /> */}
                    </div>
                </>)}
            </div>}
        </>
    )
}

/**
 *
 */
export type BillingOverviewPropsType = PropsInterface

export default App
