import React from 'react'
import { Button as Btn } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core/styles'
import Spinner from 'spinner'

const useStyles = makeStyles(theme => ({
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: (props: { minWidth: string }) => props.minWidth
    }
}))

/**
 *
 */
const Button = (props: Record<string, any>) => {
    const classes = useStyles({ minWidth: props.minWidth })

    return (
        <Btn {...props} className={classes.button}>
            {props.isLoading ? <Spinner data-testid='spinner' color='#fff' size='small' /> : props.children}
        </Btn>
    )
}

export default Button
