import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'
import { Icon, Typography } from 'foundations-library/components'
import { Dialog } from 'functional-foundations'
import { DialogType } from 'functional-foundations/types'

const jss = () => ({
    content: {
        textAlign: 'center'
    },
    icon: {
        marginBottom: '20px'
    },
    genericText: {
        marginBottom: ({ children }) => children && '20px'
    }
})

const useStyles = makeStyles(jss)

/**
 *
 */
const ErrorDialog = ({ children, ...props }: PropsWithChildren<DialogType>): JSX.Element => {
    const styles = useStyles({ children })
    return <Dialog width={600} {...props}>
        <div className={styles.content}>
            <Icon size={80} color='destructive-600' name='cancel' className={styles.icon} data-testid={`${props['data-testid']}-icon`}/>
            <div className={styles.genericText} data-testid={`${props['data-testid']}-generic-content`}>
                <Typography variant='h6' data-testid={`${props['data-testid']}-title`}>
                    Oops something went wrong!
                </Typography>
                <Typography variant='body1' data-testid={`${props['data-testid']}-subtitle`}>
                    There was issues processing your request
                </Typography>
            </div>
            {children}
        </div>
    </Dialog>
}

export default ErrorDialog
