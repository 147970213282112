import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import { Typography, IconButton, Alert, TextField } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { useHistory, useParams } from 'react-router-dom'
import { details } from '../../../utils/objects'
import Button from './../../../components/Button'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        padding: '40px',
        '& .close-icon': {
            position: 'absolute',
            top: '12px',
            right: '12px'
        }
    },
    dialogContent: {
        padding: '30px 0 20px'
    },
    dialogButtons: {
        display: 'flex',
        justifyContent: 'center',
        columnGap: '20px'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    }
})

/**
 *
 */
const TransitionSimDialog = ({ openDialog, handleCloseDialog, terminate }: { openDialog: boolean, handleCloseDialog: () => void, terminate: boolean }) => {
    const classes = useStyles()
    const [terminateInputText, setTerminateInputText] = useState('')
    const { fetchEsims, terminateEsim, terminateEsimHandler } = useContext(EsimContext)
    const { esimId } = useParams<{ esimId: string }>()
    const history = useHistory()
    const { transferEsimDialog, terminateEsimDialog } = details

    const handleTransition = async () => {
        if (terminate) {
            const terminateEsim = await terminateEsimHandler(esimId)
            if (terminateEsim) {
                fetchEsims(false, true)
                setTimeout(() => {
                    history.goBack()
                }, 2000)
            }
        }
    }

    const getContents = () => {
        let content = <></>
        let footer = <></>

        content = (
            <div className={classes.textContainer}>
                <Typography variant='h6' color='neutral-900'>{terminate ? terminateEsimDialog.title : transferEsimDialog.title}</Typography>
                <Typography data-testid={terminate ? 'terminate-esim-dialog-subtitle' : 'transfer-esim-dialog-subtitle'} variant='body2' color='neutral-700'><div dangerouslySetInnerHTML={{ __html: terminate ? terminateEsimDialog.subTitle : transferEsimDialog.subTitle }} /></Typography> {/* eslint-disable-line @typescript-eslint/naming-convention */}
                <TextField size={'large'} fullWidth={true} onChange={setTerminateInputText} value={terminateInputText} />
                {terminateEsim.error && <Alert level='error' icon={true}>{terminateEsim.error}</Alert>}
                {!terminateEsim.error && Boolean(Object.keys(terminateEsim.esim).length) && <Alert level='success' icon={true}>{terminateEsimDialog.updateSuccessMessage}</Alert>}
            </div>
        )

        footer = (
            <>
                <Button size='large' onClick={handleCloseDialog} color="neutral">
                    {terminate ? terminateEsimDialog.cancelBtn : transferEsimDialog.cancelBtn}
                </Button>
                <Button size='large' color='destructive' onClick={handleTransition} disabled={terminateEsim.isLoading || terminateInputText !== terminateEsimDialog.code} isLoading={terminateEsim.isLoading} minWidth={145}>
                    {terminate ? terminateEsimDialog.terminateBtn : transferEsimDialog.transferBtn}
                </Button>
            </>
        )

        return [content, footer]
    }

    const [content, footer] = getContents()

    return (
        <MuiDialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="transfer-esim-dialog"
            className={classes.root}
            data-testid='transfer-esim-dialog'
        >
            <IconButton size='medium' className='close-icon' name='close' variant='icon' color='neutral' onMouseUp={handleCloseDialog} />

            <div className={classes.dialogContent}>
                {content}
            </div>
            <div className={classes.dialogButtons}>
                {footer}
            </div>
        </MuiDialog>
    )
}

export default TransitionSimDialog
