import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'foundations-library/components'

const useStyles = makeStyles(() => ({
    root: {
        padding: '20px 0px 30px 0px',
        textAlign: 'center'
    }
}))

/**
 *
 */
const Title = ({ title }: { title: string }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant='h7' color='neutral-900'>{title}</Typography>
        </div>
    )
}

export default Title
