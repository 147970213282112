import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from './../../../components/Button'
// import TransitionSimDialog from './TransitionSimDialog'
import { details } from '../../../utils/objects'
import TerminateSimProcess from './terminate/index'

const useStyles = makeStyles({
    root: {

    }
})

/**
 *
 */
const TerminateSimBtn = ({ size }: { size?: 'medium' | 'large' | 'small' }): JSX.Element => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenDialog = () => setOpenDialog(true)
    const handleCloseDialog = () => setOpenDialog(false)

    return (
        <div className={classes.root}>
            <Button variant='outlined' color='destructive' size={size} onClick={handleOpenDialog}>{details.terminateBtn}</Button>
            <TerminateSimProcess start={openDialog} forceQuit={false} onClose={handleCloseDialog}/>
            {/* <TransitionSimDialog terminate={true} {...{ openDialog, handleCloseDialog }} /> */}
        </div>
    )
}

export default TerminateSimBtn
