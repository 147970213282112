import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { EsimProvider } from 'providers'
import EsimOperationsProvider from './pages/contexts/EsimOperationsContext'
import List from './pages/list/Index'
import Details from './pages/details/Index'

/**
 *
 */
const Index = ({ myEsims = false }: { myEsims?: boolean }) => {
    let { path } = useRouteMatch()
    path = path.replace(/\/$/, '')

    return (
        <EsimProvider>
            <Switch>
                <Route path={`${path}/:esimId`} render={() => <EsimOperationsProvider myEsims={myEsims}><Details /></EsimOperationsProvider>} />
                <Route path={`${path}`} render={() => <List myEsims={myEsims} />} />
            </Switch>
        </EsimProvider>
    )
}

export default Index
