import { getPhoneCom } from 'phonecom'
import { has_scope } from 'phoenix-session-helpers'

/**
 *
 */
const userMayAccessBilling = async (session): Promise<boolean> => {
    const phoneCom = await getPhoneCom()
    if (!session?.user?.id || phoneCom.role !== 'account') return false

    const hasBillingApiScope = await has_scope(session, 'billing-api')
    if (!hasBillingApiScope) return false

    return true
}

/**
 *
 */
export {
    userMayAccessBilling
}
