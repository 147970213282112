import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { App } from './../../../utils/objects'
import { processLink } from './../../../utils/utils'
import { items, Videos } from './howTo/videos/Index'

const useStyles = makeStyles(theme => ({
    videosRoot: {
        rowGap: '.5rem'
    }
}))

/**
 *
 */
const HowToVideos = ({ app }: { app: App }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Grid container xs={12} direction='column' className={classes.videosRoot}>
            <Typography variant='h6'>Top How-to-videos</Typography>
            <Videos videos={items.videos.slice(0, 3)} />
            <Button color='secondary' iconLeft={false} icon='go' variant='text' onClick={() => processLink('/how-to-videos', history, true)}>View all how-to-videos</Button>
        </Grid>
    )
}

export default HowToVideos
