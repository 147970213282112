import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from './Avatar'
import Texts from './Texts'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        padding: '10px 10px 10px 0px !important',
        alignItems: 'center',
        gap: '8px'
    }
})

/**
 *
 */
const User = ({ user, phone }: { user: Record<string, any>, phone: string }) => {
    const classes = useStyles()

    return (
        <div className={`${classes.root} user`}>
            <Avatar text={`${user.extension.name}`} />
            <Texts primary={`Ext ${user.extension.number}: ${user.extension.name}`} secondary={phone} />
        </div>
    )
}

export default User
