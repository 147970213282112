import { theme } from 'get-theme'
const mainTheme = theme

/**
 * These are the styles for the Menu component
 *
 * @param {object} contextTheme - passed by the theme provider
 */
const styles = contextTheme => {
    const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme
    return {
        autocomplete: {
            '& [class*="MuiInputBase-root"]': {
                padding: '0 !important',
                '& > input': {
                    padding: '15.5px 8px 15.5px 12px !important'
                },
                '&.has-label > input': {
                    padding: '24px 8px 7px 12px !important'
                }
            }
        },
        fullWidth: {
            width: '100%'
        },
        listWrapper: {
            padding: 0,
            maxHeight: 350,
            '& .autocomplate-list-option': {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '22px',
                letterSpacing: 0,
                height: 46,
                paddingLeft: 12,
                paddingRight: 12,
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.paper.gray,
                '&:hover': {
                    backgroundColor: theme.palette.action.primaryFocus
                },
                '&[data-focus=true]': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)'
                },
                '&[aria-disabled=true]': {
                    height: 20,
                    padding: 0
                },
                '& .divider-item': {
                    width: '100%',
                    backgroundColor: theme.palette.secondary.border
                }
            }
        }
    }
}
export default styles
