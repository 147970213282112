import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import { useHistory } from 'react-router-dom'
import { processLink } from './../../../../../utils/utils'
import OrderPlaced from './../../../../../icons/OrderPlaced.jpg'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '25px',
        padding: '20px 0'
    }
}))

/**
 *
 */
const Index = () => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className={classes.root} data-testid='order-placed'>
            <Typography align='center' variant='h4' color='neutral-900'>Congratulations!</Typography>
            <Button variant='contained' size='large' iconLeft={false} icon='go' onClick={() => processLink('/esim', history)}>Get Started</Button>
            <img src={OrderPlaced} alt='order-placed' />
        </div>
    )
}

export default Index
