/* react-linkify but I modified the decorators */
import React from 'react'
import { NetworkInterceptor } from 'network-interceptor'
import { logout } from 'phonecom'
import PropTypes from 'prop-types'

class LoginRedirector extends React.Component {
    /**
     * @param {object} props
     */
    constructor (props) {
        super(props)
        this.state = {
            forbidden: false
        }
    }

    /**
     *
     */
    componentDidMount () {
        NetworkInterceptor((resp) => {
            if (resp && resp.responseURL && resp.responseURL.includes('phone.com') && (resp.status === 403 || resp.response === '{"error": {"message": "Forbidden", "http_code": 403, "code": 403}}')) {
                this.setState({ forbidden: true })
                console.log(resp.responseURL)
                console.log('failed auth')
                console.log('redirect')
                logout()
            }
        })
    }

    /**
     *
     */
    render () {
        return this.props.children
    }
}

LoginRedirector.propTypes = {
    children: PropTypes.node
}

export default LoginRedirector
