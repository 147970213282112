import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import Button from 'button'
import { App } from '../../utils/objects'
import { openExternalApp } from '../../utils/utils'
import { useHistory } from 'react-router-dom'
import { initializePhoneCom } from 'phonecom'
import { arrowIcon } from '../../utils/inlineIcons'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff'
    },
    appdetailsRoot: {
        margin: '1rem 0 1.5rem 0'
    },
    appIcon: {
        display: 'block',
        margin: 'auto'
    },
    appDetailsContainer: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        rowGap: '0.5rem'
    },
    appTitle: {
        fontWeight: '600!important'
    },
    appdetails: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        overflow: 'hidden'
    },
    forwardBtn: {
        color: '#30991C!important',
        border: 0,
        padding: 0,
        '& > [class*="MuiTypography-root"]': {
            fontWeight: '500!important'
        }
    },
    forwardIcon: {
        fontSize: '18px',
        marginLeft: '4px'
    },
    arrowIcon: {
        marginLeft: '5px'
    }
}))

/**
 *
 */
const Content = ({ app, closePopup }: {app: App, closePopup: () => void}): JSX.Element => {
    const classes = useStyles()
    const history = useHistory()

    const openExternalAppHandler = () => {
        openExternalApp(app, history)
        closePopup()
    }

    const goToAppsMarketplace = () => {
        initializePhoneCom().then((res: Record<string, any>) => {
            const currentExtensionId = res.extension_id
            history.push(`/e${currentExtensionId}/apps-marketplace/`)
            closePopup()
        })
    }

    const renderAppDetails = () => (
        <>
            <Typography variant='h5' className={classes.appTitle}>{app.title}</Typography>
            <Typography variant='body1' className={classes.appdetails}>{app.details.overview.general}</Typography>
            <Button color='primary' variant='outlined' className={classes.forwardBtn} onClick={openExternalAppHandler}>
                <Typography variant='body1' align='left'>Learn more about {app.title}</Typography>
                <img src={arrowIcon} alt='arrow icon' className={classes.arrowIcon} />
            </Button>
            <Button color='primary' variant='outlined' className={classes.forwardBtn} onClick={goToAppsMarketplace}>
                <Typography variant='body1' align='left' remoteConfigID='apps_marketplace_addon_popup_visit_app_btn' />
                <img src={arrowIcon} alt='arrow icon' className={classes.arrowIcon} />
            </Button>
        </>
    )

    return (
        <Box className={classes.root}>
            <Grid container className={classes.appdetailsRoot}>
                <Grid item sm={4}>
                    <img src={app.icon} alt={`${app.title} Icon`} className={classes.appIcon} />
                </Grid>
                <Grid item sm={8} className={classes.appDetailsContainer}>
                    {renderAppDetails()}
                </Grid>
            </Grid>
        </Box>
    )
}

export default Content
