/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useEffect, useState } from 'react'
import { UserManagement, UserManagementState, UserManagementConfig } from '../../../integrations/src/UserManagement'

interface ZohoUsersContextProps {
    loading: boolean,
    users: any[],
    client: UserManagement,
    order: 'asc'|'desc',
    isUpgradingUsers: boolean,
    hasMoreUsers: boolean,
    proUsersCount: number,
    plusBasicUsersCount: number
    loadMoreUsers: (numberOfUsers: number) => Promise<void>,
    refreshUsers: (order: 'asc'|'desc') => Promise<void>,
    upgradeUsersToProAndRefresh: (userIds: number[]) => Promise<void>,
    inviteUserToIntegration: (user: Record<string, any>) => Promise<any>
    deactivateIntegrationForUser: (user: Record<string, any>) => Promise<any>
}

/**
 *
 */
export const ZohoUsersContext = createContext<ZohoUsersContextProps>({
    loading: false,
    users: [],
    client: new UserManagement({ integration: 'zoho_crm', numberOfUsers: 5, listener: () => {} }), // eslint-disable-line @typescript-eslint/no-empty-function,
    order: 'desc',
    isUpgradingUsers: false,
    hasMoreUsers: true,
    loadMoreUsers: async (numberOfUsers: number) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    refreshUsers: async (order: 'asc'|'desc') => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    upgradeUsersToProAndRefresh: async (userIds: number[]) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    inviteUserToIntegration: async (user: Record<string, any>) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    deactivateIntegrationForUser: async (user: Record<string, any>) => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

/**
 *
 */
export const ZohoUsersProvider: React.FC = (props) => {
    const [userRows, setUsers] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [proUsersCount, setProUsersCount] = useState<number>(0)
    const [plusBasicUsersCount, setPlusBasicUsersCount] = useState<number>(0)
    const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true)
    const [isUpgradingUsers, setIsUpgradingUsers] = useState<boolean>(false)
    const [order, setOrder] = useState<'asc'|'desc'>('desc') // eslint-disable-line no-unused-vars
    const appListener = (state: UserManagementState) => {
        setUsers(state.users)
        setHasMoreUsers(state.users.length < state.totalUserCount)
        setProUsersCount(state.proUsersCount)
        setPlusBasicUsersCount(state.plusBasicUsersCount)
    }
    const appConfig: UserManagementConfig = {
        integration: 'zoho_crm',
        numberOfUsers: 5,
        order,
        proUsersCount: 0,
        plusBasicUsersCount: 0,
        listener: appListener
    }
    const [client, setClient] = useState(new UserManagement(appConfig)) // eslint-disable-line no-unused-vars

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await client.initialize()
            setLoading(false)
        }
        fetchData()
            .catch(console.error)
    }, [])

    const loadMoreUsers = async (numberOfUsers: number) => {
        setLoading(true)
        const offset = userRows.length
        await client.loadMoreUsers(numberOfUsers, offset)
        setLoading(false)
    }

    const refreshUsers = async (order: 'asc'|'desc') => {
        setLoading(true)
        setOrder(order)
        await client.refreshUsers(order)
        setLoading(false)
    }

    const upgradeUsersToProAndRefresh = async (userIds: number[]) => {
        setIsUpgradingUsers(true)
        await client.upgradeUsersToPro(userIds)
        setIsUpgradingUsers(false)
        await refreshUsers(order)
    }

    const inviteUserToIntegration = async (user: Record<string, any>) => {
        return await UserManagement.inviteUserToIntegration(1, user.id)
    }

    const deactivateIntegrationForUser = async (user: Record<string, any>) => {
        setLoading(true)
        await client.deactivateIntegrationForUser(user.id)
        setLoading(false)
    }

    const context: ZohoUsersContextProps = {
        loading: loading,
        users: userRows,
        order,
        client,
        isUpgradingUsers,
        hasMoreUsers,
        proUsersCount,
        plusBasicUsersCount,
        loadMoreUsers,
        refreshUsers,
        upgradeUsersToProAndRefresh,
        inviteUserToIntegration,
        deactivateIntegrationForUser
    }
    return (
        <ZohoUsersContext.Provider value={context}>
            {props.children}
        </ZohoUsersContext.Provider>
    )
}
