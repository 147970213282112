import React, { useContext, useEffect, useState } from 'react'
import { Tabs } from 'foundations-library/components'
import style from './style'
import { makeStyles } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import PaymentMethods from 'payment-methods'
import Statements from 'statements'
import BillingOverview from 'billing-overview'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(style)

interface PropsInterface {
    routeProps: any;
    smallView: boolean;
}

/**
 *
 */
const PayNow = ({
    routeProps,
    smallView
}: PropsInterface): JSX.Element => {
    const screenSize = useContext(ScreenSizeContext)
    const styles = useStyles({ screenSize })
    const basePath = routeProps?.match?.path
    const [activeTab, setActiveTab] = useState('')

    useEffect(() => pushRoute(activeTab), [activeTab])
    useEffect(() => { // when we are on ex. statements and we click on billing/overview from sidebar
        const pathnameParts = routeProps?.location?.pathname?.split('/')
        if (pathnameParts && pathnameParts.length) {
            const pathActiveTab = pathnameParts[3] || ''
            if (
                !pathActiveTab &&
                ['statements', 'payment-methods'].includes(activeTab)
            ) {
                setActiveTab(pathActiveTab)
            }
        }
    }, [routeProps])
    const pushRoute = (newTab) => {
        const slug = (newTab === '' || newTab.startsWith('/')) ? newTab : `/${newTab}`
        routeProps.history.push(`${routeProps.match.path}${slug}`)
    }

    return <div className={styles.payNow} data-testid="pay-now">
        <Tabs
            value={activeTab}
            items={[
                { value: '', text: 'Overview' },
                { value: 'statements', text: 'Statements' },
                { value: 'payment-methods', text: 'Payment methods' }
            ]}
            onChange={setActiveTab}
            className={styles.tabs}
            data-testid='pay-now-tabs'
        />
        <div className="" data-testid="pay-now-content">
            <Switch>
                <Route path={`${basePath}/statements`} render={() => <Statements />} />
                <Route path={`${basePath}/payment-methods`} render={() => <PaymentMethods routeProps={routeProps} smallView={smallView} />}/>
                <Route path={`${basePath}`} render={() => <BillingOverview routeProps={routeProps} setActiveTab={setActiveTab} />} />
                <Route path='' render={() => <Redirect to={`${basePath}`}/>} />
            </Switch>
        </div>
    </div>
}

export default PayNow
