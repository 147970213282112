import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { EsimContext } from 'providers'
import { Alert } from 'foundations-library/components'
import Skeleton from './Skeleton'
import { useEsimStepperContext } from '../../../../../../contexts/EsimStepperContext'
import Plans from './Plans'
import SelectEsimCount from './SelectEsimCount'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        rowGap: '100px'
    }
}))

/**
 *
 */
const Index = (): JSX.Element => {
    const classes = useStyles()
    const { plans, fetchPlans } = useContext(EsimContext)
    const { setSelectedPlan } = useEsimStepperContext()

    useEffect(() => {
        (async () => {
            const plans = await fetchPlans()
            if (plans?.length === 1) {
                const plan = plans[0]
                setSelectedPlan({ title: plan.name, price: plan.price, id: plan.billing_code })
            }
        })()
    }, [])

    if (plans.isLoading || (!plans.isLoading && !plans.plans.length && !plans.error)) return <Skeleton />
    if (plans.error) return <Alert level='error' icon={true}>{plans.error}</Alert>

    return (
        <div className={classes.root}>
            <Plans plans={plans.plans} />
            <SelectEsimCount />
        </div>
    )
}

export default Index
