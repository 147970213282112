import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Radio, Alert } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import { details } from './../../../utils/objects'
import QRInstallation from './QRInstallation'
import ManualInstallation from './ManualInstallation'

const useStyles = makeStyles({
    root: {

    },
    titleContainer: {
        padding: '14px 20px 20px'
    },
    installationStepsContainer: {

    },
    installationTypes: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        padding: '0 20px 20px'
    }
})

/**
 *
 */
const InstallationInstructions = () => {
    const classes = useStyles()
    const { refreshQRCode } = useContext(EsimContext)
    const [installationType, setInstallationType] = useState<'QR' | 'Manual'>('QR')
    const { data } = useEsimOperationsContext(); if (!data) return null
    const { installation } = details

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <Typography variant='subtitle1' color='neutral-900'>{installation.title}</Typography>
                <Typography variant='body3' color='neutral-700'>{installation.subtitle}</Typography>
            </div>
            <div className={classes.installationStepsContainer}>
                {refreshQRCode.error && <Alert marginY={8} level='error' icon={true}>{refreshQRCode.error}</Alert>}
                {!refreshQRCode.error && Boolean(Object.keys(refreshQRCode.data).length) && <Alert marginY={8} level='success' icon={true}>{details.installation.QRCode.refreshSuccessMessage}</Alert>}
                <div className={classes.installationTypes}>
                    <Radio label={installation.QRCode.title} color='neutral-500' checked={installationType === 'QR'} onChange={e => setInstallationType('QR')} />
                    <Radio label={installation.manual.title} color='neutral-500' checked={installationType === 'Manual'} onChange={e => setInstallationType('Manual')} />
                </div>
                {installationType === 'QR' && <QRInstallation data={data} />}
                {installationType === 'Manual' && <ManualInstallation data={data} />}
            </div>
        </div>
    )
}

export default InstallationInstructions
