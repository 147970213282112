import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { Skeleton } from 'foundations-library/components'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        simStatus: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '10px 10px 0px 0px',
            border: `1px solid ${verbalColors['neutral-200']}`,
            padding: '16px 20px 12px 20px',
            height: '80px'
        },
        selectAssignee: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: '1rem',
            border: `1px solid ${verbalColors['neutral-200']}`,
            borderTop: 'none',
            borderRadius: '0 0 10px 10px',
            padding: '30px 30px 52px 20px'
        }
    }
})

/**
 *
 */
const SimAssigneeSkeleton = () => {
    const classes = useStyles()

    return (
        <>
            <div className={classes.simStatus}>
                <Skeleton variant='rect' width={'50%'} height={'100%'} />
                <Skeleton variant='rect' width={'25%'} height={'30px'} />
            </div>
            <div className={classes.selectAssignee}>
                <Skeleton variant='text' width={'70px'} />
                <Skeleton variant='rect' width={'75%'} height={'45px'} />
                <Skeleton variant='rect' width={'75%'} height={'45px'} />
                <Skeleton variant='rect' width={'70px'} height={'32px'} />
            </div>
        </>
    )
}

export default SimAssigneeSkeleton
