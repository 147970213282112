import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'foundations-library/components'
import { VerbalColorType } from 'foundations-library/types'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '3px'
    }
})

/**
 *
 */
const Texts = ({ primary, secondary, primaryColor }: { primary: string, secondary: string, primaryColor?: VerbalColorType}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant='subtitle2' color={primaryColor || 'neutral-900'}>{primary}</Typography>
            <Typography variant='serial' color='neutral-500'>{secondary}</Typography>
        </div>
    )
}

export default Texts
