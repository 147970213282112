import React, { Component } from 'react'
import ProgressBar from 'progress-bar'
import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'
import PropTypes from 'prop-types'

const styles = theme => ({
    loadingBar: {
        width: 192,
        marginLeft: 'calc(50% - 96px)',
        marginTop: 25
    },
    loadingBarDesktop: {
        width: 850,
        marginTop: 25
    },
    appLoader: {
        transition: 'opacity .5s ease-out',
        overflow: 'hidden',
        opacity: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
        backgroundColor: theme.appLoader.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    appLoader_appLoaderHidden: {
        opacity: 0,
        pointerEvents: 'none'
    },
    appLoaderIcon: theme.appLoader.logo,
    loader: {
        width: '100%',
        height: '100%'
    },
    progressBar: {
        width: 200,
        height: 8,
        borderRadius: 6.5,
        marginTop: 25,
        background: theme.appLoader.progressBar.backgroundColor,
        '& .app-loader-progress-fill': {
            height: '100%',
            width: 0,
            background: theme.appLoader.progressBar.fillColor,
            borderRadius: 6.5
        }
    },
    loadingText: theme.appLoader.loadingText
})

class AppLoader extends Component {
    state = { progressStep: 'middle' }

    componentDidUpdate (prevProps) {
        if (!prevProps.hidden && this.props.hidden) {
            this.setState({ progressStep: 'end' })
        }
    }

    render () {
        const { classes, hidden } = this.props
        const isWebCom = theme.name === 'WebCom'
        const onProgressChange = width => {
            document.getElementsByClassName('app-loader-progress-fill')[0].style.width = `${width}%`
        }
        return (
            <div className={`${classes.appLoader} ${hidden ? classes.appLoader_appLoaderHidden : ''}`}>
                <div className={classes.appLoaderIcon}></div>
                <span className={classes.progressBar}>
                    <ProgressBar
                        progressThresholds={{ start: { min: 0, max: 55 }, middle: { min: 55, max: 88 }, end: { min: 88, max: 100 } }}
                        intervalSpeeds={{ SLOW: 80 }}
                        currentStep={this.state.progressStep}
                        lastStep='end'
                        onProgress={onProgressChange}
                        showSlider={true}
                    >
                        <div className='app-loader-progress-fill'></div>
                    </ProgressBar>
                </span>
                <span className={classes.loadingText}>Loading <span className='name'>{isWebCom ? 'web.phone' : 'My.Phone'}.com</span></span>
            </div>
        )
    }
}

AppLoader.propTypes = {
    hidden: PropTypes.bool,
    classes: PropTypes.object
}

export default withStyles(styles)(AppLoader)
