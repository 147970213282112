import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Picture, Video } from './Media'
import { App } from '../utils/objects'

const useStyles = makeStyles(theme => ({
    carouseContainer: {
        width: '100%',
        margin: 'auto',
        '& .slick-prev, & .slick-next': {
            position: 'absolute',
            backgroundColor: '#F2F1F1',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            top: '50%',
            zIndex: 1,
            '&:before': {
                content: '""'
            },
            '& svg': {
                fontSize: '40px',
                color: '#8C8C8C'
            },
            '&:hover': {
                backgroundColor: '#e7e7e7'
            }
        },
        '& .slick-prev': {
            left: '-45px!important'
        },
        '& .slick-next': {
            right: '-45px!important'
        },
        '& .slick-dots li button:before': {
            color: theme.palette.primary.light
        },
        '& .slick-dots li.slick-active button:before': {
            color: theme.palette.primary.dark
        }
    },
    carouseItemContainer: {
        padding: '8px',
        '& > *': {
            height: '300px!important'
        },
        '& img': {
            width: '100%!important',
            height: '300px!important',
            objectFit: 'cover',
            objectPosition: 'top left',
            margin: 'auto',
            borderRadius: '1rem'
        },
        '& video': {
            width: '100%!important',
            height: '300px!important',
            objectFit: 'fill',
            margin: 'auto',
            borderRadius: '1rem'
        }
    }
}))

/**
 *
 */
const Carousel = ({ app }: { app: App }): JSX.Element => {
    const classes = useStyles()

    const SampleNextArrow = (props: Record<string, any>) => {
        const { className, style, onClick } = props
        return (
            <IconButton
                className={className}
                style={{ ...style, opacity: onClick ? 1 : 0.2 }}
                onClick={onClick}
                disabled={!onClick}
            >
                <ChevronRightIcon />
            </IconButton>
        )
    }

    const SamplePrevArrow = (props: Record<string, any>) => {
        const { className, style, onClick } = props
        return (
            <IconButton
                className={className}
                style={{ ...style, opacity: onClick ? 1 : 0.2 }}
                onClick={onClick}
                disabled={!onClick}
            >
                <ChevronLeftIcon />
            </IconButton>
        )
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ],
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }

    return (
        <Box className={classes.carouseContainer}>
            <Slider {...settings}>
                {
                    app.details.media?.map((thisMedia, index) => (
                        <Box className={classes.carouseItemContainer} key={index} p={2} >
                            {
                                thisMedia.type === 'picture'
                                    ? <Picture sources={thisMedia.sources} thumbnail={thisMedia.thumbnail} />
                                    : <Video sources={thisMedia.sources} attributes={{ poster: thisMedia.thumbnail }} app={app} />
                            }
                        </Box>
                    ))
                }
            </Slider>
        </Box>
    )
}

export default Carousel
