import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import { useHistory } from 'react-router-dom'
import Button from './../../../components/Button'
import { Button as ButtonInterface } from './../../../utils/objects'
import { modalCondition, urlCondition } from './../../../utils/utils'
import ZohoUserModal from 'zoho-user-modal'
import { ZohoUserProvider, ZohoUsersProvider } from 'providers'
import { getPhoneCom } from 'phonecom'
import { handleGTM, GTM_APP_DETAILS_NAME, processLink } from '../../../utils/utils'
import { useAppContext } from '../../../contexts/AppContext'

const useStyles = makeStyles(theme => ({
    headerBox: {

    },
    OutlinedBtnContainer: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            marginBottom: '1rem'
        }
    },
    supportBtn: {
        minWidth: '80px',
        padding: '8px 8px 8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2px',
        color: '#3FAD2A',
        border: '1px solid #3FAD2A'
    },
    openInNewTabIcon: {
        padding: '2px'
    },
    appTitle: {
        fontSize: '38px!important',
        fontWeight: '600!important',
        color: `${theme.palette.grey[900]}!important`
    },
    companyTitle: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#737373'
    },
    appBasicDetailsContainer: {
        display: 'flex',
        gap: '2rem',
        alignItems: 'stretch',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '1rem'
        }
    },
    appBasicDetails: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            gap: '.5rem'
        }
    },
    starredIcon: {
        color: theme.palette.primary.main,
        fontSize: '1.3rem'
    },
    ratingsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        columnGap: '0.25rem',
        "& [class*='MuiTypography-root']": {
            display: 'inline',
            fontWeight: '600!important'
        }
    },
    installedBadge: {
        marginTop: 'auto'
    },
    headerBtnsContainer: {
        display: 'flex',
        columnGap: '20px'
    },
    installBtn: {
        fontSize: '14px',
        fontWeight: 600,
        padding: '9px 16px',
        backgroundColor: `${theme.palette.grey[900]}!important`
    },
    manageUserAccessBtn: {
        fontSize: '14px',
        fontWeight: 600,
        padding: '9px 16px'
    },
    manageUserAccessBtnContainer: {
        position: 'relative'
    },
    infoIcon: {
        position: 'absolute',
        top: 0,
        right: '-28px'
    }
}))

/**
 *
 */
const Header = (): JSX.Element => {
    const classes = useStyles()
    const { app } = useAppContext()
    const [openModal, setOpenModal] = useState(false)
    const [manageUsers, setManageUsers] = useState(false)
    const [userRole, setUserRole] = useState('')
    const history = useHistory()
    const isAdmin = userRole === 'account'

    const handleCloseModal = () => setOpenModal(false)

    useEffect(() => {
        getPhoneCom().then((phoneCom: Record<string, any>) => {
            setUserRole(phoneCom?.role)
        })
    }, [])

    const renderModalComponent = () => {
        if (!userRole) return <></>

        const userModal = (
            <ZohoUserProvider>
                {openModal && <ZohoUserModal open={openModal} handleClose={handleCloseModal} manageUsers={manageUsers} app={app} />}
            </ZohoUserProvider>
        )

        return (
            isAdmin
                ? <ZohoUsersProvider>
                    {userModal}
                </ZohoUsersProvider>
                : userModal
        )
    }

    const handleBtnClick = (button: ButtonInterface) => {
        const { action: { type, modal, url }, text } = button
        const appTitle = app.title.toLowerCase().replace(/\s+/g, '_')
        const btn = text.toLowerCase().replace(/\s+/g, '_')

        switch (true) {
                case modalCondition(type): {
                    const isManageUserAccessModal = modal?.toLowerCase() === 'manage-user-access'
                    setManageUsers(isManageUserAccessModal)
                    setOpenModal(true)
                    break
                }
                case urlCondition(type): {
                    if (url) processLink(url, history, true)

                    break
                }
                default:
                    break
        }

        handleGTM({
            action: GTM_APP_DETAILS_NAME,
            label: `clicked-${btn}`,
            event: `PDC_${appTitle}_clicked_${btn}`,
            other: {
                items: [{ item_name: app.title }]
            }
        })
    }

    const doesAnyButtonOpensModal = app.buttons?.some(button => button.action.type.toLowerCase() === 'modal')

    const renderAppBasicDetails = () => {
        return (
            <Box className={classes.appBasicDetails}>
                <Typography variant='h4' className={classes.appTitle}>{app.title}</Typography>
                <Typography className={classes.companyTitle}>{app.company}</Typography>
                {/* <Box className={classes.ratingsContainer}>
                    <Typography variant='subtitle1'>{app.details.averageRating}</Typography>
                    <StarredIcon className={classes.starredIcon} data-testid='icon' />
                    <Typography color='primary' variant='subtitle1'>{app.details.totalRatings} Ratings</Typography>
                </Box> */}
                {/* {app.hasInstalled
                    ? <InstalledBadge className={classes.installedBadge} />
                    : <Button variant='filled' className={classes.installBtn} onClick={() => setOpenModal(true)} >Activate</Button>
                } */}
                <Box className={classes.headerBtnsContainer}>
                    {
                        app.buttons && app.buttons.map((button, index) => <Button key={index} handleClick={handleBtnClick.bind(this, button)} {...button} />)
                    }
                </Box>
                {doesAnyButtonOpensModal && renderModalComponent()}
            </Box>
        )
    }

    return (
        <Box className={classes.headerBox}>
            {/* <Box className={classes.OutlinedBtnContainer}>
                <Button className={classes.supportBtn} variant='outlined'>
                    <Typography variant='subtitle2' remoteConfigID={'apps_marketplace_get_support'} />
                    <img src={openInNewTabIcon} className={classes.openInNewTabIcon} />
                </Button>
            </Box> */}
            <Box className={classes.appBasicDetailsContainer}>
                {/* <AppIcon icon={app.icon} size='large' /> */}
                <img src={app.icon} alt={`${app.title} icon`} width='150px' />
                {renderAppBasicDetails()}
            </Box>
        </Box>
    )
}

export default Header
