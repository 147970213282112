import React, { useState, createContext, useEffect } from 'react'
import { arraysEqual } from './helpers'

interface SelectUsersToUpgradeContextInterface {
    addUserId:(id: number) => void,
    removeUserId: (id: number) => void,
    addAllUserIds: () => void,
    removeAllUserIds: () => void,
    allUserIdsSelected: boolean,
    selectedUsersToUpgrade: number[],
}

/**
 *
 */
const SelectUsersToUpgradeContext = createContext<SelectUsersToUpgradeContextInterface>({ addUserId: (id: number) => {}, removeUserId: (id: number) => {}, addAllUserIds: () => {}, removeAllUserIds: () => {}, allUserIdsSelected: false, selectedUsersToUpgrade: [] }) // eslint-disable-line @typescript-eslint/no-empty-function

/**
 *
 */
const SelectUsersToUpgradeProvider = ({ children, allUserIds }: { children: JSX.Element, allUserIds: number[]|undefined }) => {
    const [selectedUsersToUpgrade, setSelectedUsersToUpgrade] = useState<number[]>([])
    const [allUserIdsState, setAllUserIdsState] = useState<number[]>([])
    const [allUserIdsSelected, setAllUserIdsSelected] = useState<boolean>(false)
    /**
     *
     */
    const addUserId = (id: number) => {
        setSelectedUsersToUpgrade((ids) => {
            const userIds = new Set(ids)
            userIds.add(id)
            return Array.from(userIds)
        })
    }

    /**
     *
     */
    const removeUserId = (id: number) => {
        const index = selectedUsersToUpgrade.findIndex(userId => userId === id)
        // remove 0 from the array if it exists
        if (index !== -1) selectedUsersToUpgrade.splice(index, 1); setSelectedUsersToUpgrade([...selectedUsersToUpgrade])
    }

    /**
     *
     */
    const addAllUserIds = () => {
        console.log(allUserIds)
        if (allUserIdsState) setSelectedUsersToUpgrade([...allUserIdsState])
        setAllUserIdsSelected(true)
        console.log(selectedUsersToUpgrade, allUserIdsState)
    }

    /**
     *
     */
    const removeAllUserIds = () => setSelectedUsersToUpgrade([])

    useEffect(() => {
        console.log(selectedUsersToUpgrade)
        if (!arraysEqual(selectedUsersToUpgrade, selectedUsersToUpgrade)) setSelectedUsersToUpgrade(selectedUsersToUpgrade)
        setAllUserIdsSelected(arraysEqual(selectedUsersToUpgrade, allUserIdsState))
    }, [selectedUsersToUpgrade])

    useEffect(() => {
        if (allUserIdsState?.length > 0) {
            console.log(selectedUsersToUpgrade, allUserIdsState)
            setAllUserIdsSelected(arraysEqual(selectedUsersToUpgrade, allUserIdsState))
        } else {
            setAllUserIdsSelected(false)
        }
    }, [allUserIdsState])

    useEffect(() => {
        if (allUserIds !== undefined) {
            setAllUserIdsState(allUserIds)
        }
    }, [allUserIds])

    return (
        <SelectUsersToUpgradeContext.Provider value={{ selectedUsersToUpgrade, addUserId, removeUserId, addAllUserIds, removeAllUserIds, allUserIdsSelected }}>
            {children}
        </SelectUsersToUpgradeContext.Provider>
    )
}

/**
 *
 */
// const SelectUsersToUpgradeConsumer = ({ children }: { children: JSX.Element}) => {
//     return (
//         <SelectUsersToUpgradeContext.Consumer>
//             {value => children}
//         </SelectUsersToUpgradeContext.Consumer>
//     )
// }

SelectUsersToUpgradeProvider.displayName = 'SelectUsersToUpgradeProvider'

interface StatusContextInterface {
    deactivatedUsersState: Record<string, any>[],
    setDeactivatedUsersState: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
    sentEmailUsersState: Record<string, any>[],
    setSentEmailUsersState: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
    app: Record<string, any>
}

/**
 *
 */
const StatusContext = createContext<StatusContextInterface>({ deactivatedUsersState: [], setDeactivatedUsersState: (array: (Record<string, any>[]) | ((func: Record<string, any>[]) => Record<string, any>[])): void => {}, sentEmailUsersState: [], setSentEmailUsersState: (array: (Record<string, any>[]) | ((func: Record<string, any>[]) => Record<string, any>[])): void => {}, app: {} }) // eslint-disable-line @typescript-eslint/no-empty-function

/**
 *
 */
const StatusProvider = ({ children, deactivatedUsersState, setDeactivatedUsersState, sentEmailUsersState, setSentEmailUsersState, app }: { children: JSX.Element, deactivatedUsersState: Record<string, any>[], setDeactivatedUsersState: React.Dispatch<React.SetStateAction<Record<string, any>[]>>, sentEmailUsersState: Record<string, any>[], setSentEmailUsersState: React.Dispatch<React.SetStateAction<Record<string, any>[]>>, app: Record<string, any> }) => {
    return (
        <StatusContext.Provider value={{ deactivatedUsersState, setDeactivatedUsersState, sentEmailUsersState, setSentEmailUsersState, app }}>
            {children}
        </StatusContext.Provider>
    )
}

StatusProvider.displayName = 'StatusProvider'

/**
 *
 */
export { SelectUsersToUpgradeContext, SelectUsersToUpgradeProvider, StatusContext, StatusProvider }
