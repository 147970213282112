import React, { useContext, useEffect, useState } from 'react'
import { Typography, Button } from 'foundations-library/components'
import ListItem from './ListItem'
import { PaymentMethodType } from 'interfaces'
import { makeStyles } from '@material-ui/core'
import noItemsImage from '../../images/no-items.png'
import { ScreenSizeContext } from 'providers'

const style = () => ({
    list: {},
    noItemsImageContainer: {
        textAlign: 'center',
        marginTop: '50px'
    },
    addNewCardButton: {
        marginTop: ({ screenSize }) => screenSize.mobile ? '10px' : '15px'
    }
})
const useStyles = makeStyles(style)

interface PropsInterface {
    items: Array<PaymentMethodType>;
    loadingItem?: number | null;
    disabled: boolean;
    onClickSetPrimary?(id): any;
    onClickRemove?(id): any;
    onClickEdit?(id): any;
    withOptions?: boolean;
    onChange?(id): any;
    value?: number;
    onClickAddNew?(): any;
    'data-testid'?: string;
}

/**
 *
 */
const index = ({
    items,
    disabled,
    onClickSetPrimary,
    onClickRemove,
    // onClickEdit,
    onClickAddNew,
    withOptions = false,
    onChange,
    value,
    loadingItem,
    'data-testid': dataTestId = 'payment-methods-items-list'
}: PropsInterface): JSX.Element => {
    const screenSize = useContext(ScreenSizeContext)
    const styles = useStyles({ screenSize })
    const [clicked, setClicked] = useState(value)
    useEffect(() => onChange && onChange(clicked), [clicked])
    useEffect(() => setClicked(value), [value])

    return (
        <div className={styles.list} data-testid={dataTestId}>

            {
                !items?.length &&
                <div className={styles.noItemsImageContainer}>
                    <img
                        src={noItemsImage}
                        alt='You currently have no credit card or debit card on file.'
                        height={screenSize.mobile ? 171 : undefined}
                        data-testid='no-items-image'
                    />
                    <Typography
                        variant="body2"
                        data-testid="no-payment-methods"
                    >You don’t have a saved payment method.
                        {screenSize.mobile ? <br/> : ' '}
                        Add one now.
                    </Typography>
                    {onClickAddNew && <Button
                        variant='outlined'
                        icon='add'
                        className={styles.addNewCardButton}
                        iconLeft
                        size='large'
                        onClick={() => onClickAddNew()}
                        data-testid='add-payment-method-button'
                    >Add new payment method</Button>}
                </div>
            }
            {items && items.length > 0 && items.map((item, i) => {
                const options = []
                if (withOptions) {
                    // options.push({ name: 'Edit', cb: (id) => onClickEdit && onClickEdit(id), value: 'edit' })
                    if (item.status !== 'primary' && items.length > 1) {
                        options.push({ text: 'Delete', cb: (id) => onClickRemove && onClickRemove(id), value: 'delete' })
                    }
                    if (item.status !== 'primary') {
                        options.push({ text: 'Set as primary', cb: (id) => onClickSetPrimary && onClickSetPrimary(id), value: 'set-as-primary' })
                    }
                }
                return <ListItem
                    item={item}
                    disabled={disabled}
                    onClick={(id) => setClicked(id)}
                    className={clicked === item.id ? 'payment-method--selected' : ''}
                    options={options}
                    loading={loadingItem && loadingItem === item.id}
                    data-testid={`${dataTestId}-item-${item.id}`}
                    key={`${dataTestId}-${i}`}
                />
            })}
        </div>
    )
}

export default index
