import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { EsimContext } from 'providers'
import { Alert } from 'foundations-library/components'
import Help from './../components/Help'
import GoBack from '../../components/GoBack'
import { useEsimOperationsContext } from './../contexts/EsimOperationsContext'
import SimAssigneeSkeleton from './components/SimAssigneeSkeleton'
import SelectAssignee from './components/SelectAssignee'
import SimStatus from './components/SimStatus'
import CheckCompabilityDialog from './components/CheckCompabilityDialog'
import AssigneeDialog from './components/AssigneeDialog'
import Assigned from './components/Assigned'

/**
 *
 */
export type checkCompatibilityStatusType = 'pending' | 'in progress' | 'completed'

const useStyles = makeStyles({
    root: {
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        },
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '50px',
        '& .input-wrapper:not(.full-width .input-wrapper)': {
            width: 'auto!important',
            '& .select': {
                margin: '10px 0 18px',
                width: '300px'
            }
        }
    },
    helpWidgetsContainer: {
        maxWidth: '800px',
        margin: '40px auto 0'
    }
})

/**
 *
 */
const Index = () => {
    const classes = useStyles()
    const { app, plans, esims } = useContext(EsimContext)
    const { myEsims, isLoading, data, isSimAssigned, selectedUser, openCompabilityDialog } = useEsimOperationsContext()

    if (esims.error) return <Alert level='error' icon={true}>{esims.error}</Alert>
    if (plans.error) return <Alert level='error' icon={true}>{plans.error}</Alert>

    const goBack = !myEsims && <GoBack title={app?.title ? app.title : 'eSIMs'} />

    if (isLoading || !data) {
        return (
            <>
                {goBack}
                <div className={classes.root}><SimAssigneeSkeleton /></div>
                {/* <div className={classes.root}><AssignedSkeleton /></div> */}
            </>
        )
    }

    return (
        <>
            {goBack}
            <div className={classes.root} data-testid='esim-details'>
                <SimStatus />
                {!isSimAssigned && <SelectAssignee />}
                {selectedUser && openCompabilityDialog && <CheckCompabilityDialog />}
                {selectedUser && <AssigneeDialog />}
                {isSimAssigned && selectedUser && <Assigned />}
            </div>
            <div className={classes.helpWidgetsContainer}>
                <Help />
            </div>
        </>
    )
}

export default Index
