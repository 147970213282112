import React, { useContext } from 'react'
import LoaderFull from 'loader-full'
import { ScreenSizeContext } from 'providers'

/**
 *
 */
const Loader = () => {
    const screenSize = useContext(ScreenSizeContext)
    const sidebarHidden = screenSize.mobile || screenSize.tablet

    return (
        <LoaderFull styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }} size='big' />
    )
}

export default Loader
