import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import AssignedForm from './AssignedForm'
import TransitionSim from './TransitionSim'
import InstallationInstructions from './InstallationInstructions'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            border: `1px solid ${verbalColors['neutral-200']}`,
            borderTop: 'none',
            borderRadius: '0 0 10px 10px'
        }
    }
})

/**
 *
 */
const Assigned = () => {
    const classes = useStyles()
    const { data } = useEsimOperationsContext(); if (!data) return null

    return (
        <div className={classes.root}>
            <AssignedForm />
            {(data.status === 'ready' || data.user) && <InstallationInstructions />}
            {data.status === 'activated' && <TransitionSim />}
        </div>
    )
}

export default Assigned
