import gtmDataPush from 'gtm-events'
import PhoneComUser from 'phone-com-user'
import { App, UserTypes, AppType } from './objects'

/**
 *
 */
export const log = (...params: any[]): void => {
    console.log(...params)
}

/**
 *
 */
export const makeURL = (endpoint: string): RegExp => new RegExp(`^https?://.*/${endpoint}$`, 'i')

/**
 *
 */
export const getBasePath = (routeMatch: Record<string, any>) => {
    const path = routeMatch.path
    const basePath = path.replace(/\/*$/, '').replace(/\/:.+$/, '')
    return basePath
}

/**
 *
 */
export const isObjectEmpty = (obj: any): boolean => {
    if (!(obj instanceof Object)) {
        throw new Error('Parameter must be valid Object.')
    }
    return !Object.keys(obj).length
}

/**
 *
 */
export const isUserAllowed = (user: Record<string, any>, userTypes: UserTypes) => {
    let isAllowed: boolean

    const isNxtUser = user?.user_tiered
    const isClassicUser = !user?.user_tiered
    const isAdmin = user?.role === 'account'
    const isNonAdmin = user?.role === 'extension'
    switch (true) {
            case (isNxtUser && isAdmin) && userTypes?.nxt?.admin:
                isAllowed = true
                break
            case (isNxtUser && isNonAdmin) && userTypes?.nxt?.nonAdmin:
                isAllowed = true
                break
            case (isClassicUser && isAdmin) && userTypes?.classic?.admin:
                isAllowed = true
                break
            case (isClassicUser && isNonAdmin) && userTypes?.classic?.nonAdmin:
                isAllowed = true
                break
            default:
                isAllowed = false
                break
    }

    return isAllowed
}

/**
 *
 */
export const modalCondition = (type: AppType) => type.toLowerCase() === AppType.Modal.toLowerCase()

/**
 *
 */
export const integrationCondition = (type: AppType) => type.toLowerCase() === AppType.Integrated.toLowerCase()

/**
 *
 */
export const urlCondition = (type: AppType) => [AppType.Internal.toLowerCase(), AppType.External.toLowerCase()].includes(type.toLowerCase())

/**
 *
 */
export const escapeRegExp = (text) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

/**
 *
 */
export const processLink = (link: string, history: Record<string, any>, nestedLink = false) => {
    if (!link.startsWith('/')) return window.open(link, '_blank')

    let newLink = link

    if (nestedLink) {
        const path = history.location.pathname.replace(/\/$/, '')
        newLink = `${path}${newLink}`
    } else {
        const extensionId = PhoneComUser.getExtensionId()
        if (extensionId) {
            const hrefExtension = `/e${extensionId}`
            if (!newLink.startsWith(hrefExtension) && window.location.href.includes(`${hrefExtension}/`)) {
                newLink = `${hrefExtension}${newLink}`
            }
        }
    }

    history.push(newLink)
}

/**
 *
 */
export const openExternalApp = (app: App, history: Record<string, any>): void => {
    const { url } = app
    if (!url) return

    processLink(url, history)

    handleGTM({
        action: GTM_APP_LIST_NAME,
        label: 'marketplace-app-click',
        value: app.title,
        event: 'PDC_viewed_marketplace_app',
        other: {
            ecommerce: {
                value: 0,
                items: [{ item_name: app.title }] // eslint-disable-line @typescript-eslint/naming-convention
            }
        }
    })
}

/**
 *
 */
export const handleGTM = (gtmData: {action: string, label: string, value?: string, event?: string, other?: Record<string, any>}): void => {
    const gtmDataObj = {
        PDC_Action: gtmData.action, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_Label: gtmData.label, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_Value: gtmData.value, // eslint-disable-line @typescript-eslint/naming-convention
        event: gtmData.event,
        ...gtmData.other
    }
    gtmDataPush(gtmDataObj)
}

/**
 *
 */
export const GTM_APP_LIST_NAME = 'apps-marketplace-list' // eslint-disable-line @typescript-eslint/naming-convention
/**
 *
 */
export const GTM_APP_DETAILS_NAME = 'apps-marketplace-app-details' // eslint-disable-line @typescript-eslint/naming-convention
