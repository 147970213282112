/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useParams } from 'react-router-dom'
import { EsimContext, TeammateContext } from 'providers'
import api from 'api'

interface EsimOperationsContextInterface {
    isLoading: boolean
    data: Record<string, any> | null
    setData: React.Dispatch<React.SetStateAction<Record<string, any> | null>>
    selectedUser: Record<string, any> | null
    setSelectedUser: React.Dispatch<React.SetStateAction<Record<string, any> | null>>
    selectedPhone: string
    setSelectedPhone: React.Dispatch<React.SetStateAction<string>>
    isSimAssigned: boolean
    setIsSimAssigned: React.Dispatch<boolean>
    openCompabilityDialog: boolean
    openAssigneeDialog: boolean
    handleOpenCompabilityDialog: () => void
    handleCloseCompabilityDialog: () => void
    handleOpenAssigneeDialog: () => void
    handleCloseAssigneeDialog: () => void
    phones: Record<string, any>[]
    setPhones: React.Dispatch<React.SetStateAction<Record<string, any>[]>>
    teammates: any,
    myEsims: boolean
}

/**
 *
 */
export const EsimOperationsContext = createContext<EsimOperationsContextInterface | Record<string, any>>({})
/**
 *
 */
export const useEsimOperationsContext = () => useContext(EsimOperationsContext)

/**
 *
 */
export type checkCompatibilityStatusType = 'pending' | 'in progress' | 'completed'

/**
 *
 */
const EsimOperationsProvider = ({ myEsims = false, children }: { myEsims?: boolean, children: JSX.Element }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<Record<string, any> | null>(null)
    const [selectedUser, setSelectedUser] = useState<Record<string, any> | null>(null)
    const [selectedPhone, setSelectedPhone] = useState('')
    const [isSimAssigned, setIsSimAssigned] = useState(false)
    const [openCompabilityDialog, setOpenCompabilityDialog] = useState(false)
    const [openAssigneeDialog, setOpenAssigneeDialog] = useState(false)
    const [phones, setPhones] = useState<Record<string, any>[]>([])
    const { teammates } = useContext(TeammateContext)
    const { fetchPlans, fetchEsims, reset } = useContext(EsimContext)
    const { esimId } = useParams<{ esimId: string }>()

    const handleOpenCompabilityDialog = () => setOpenCompabilityDialog(true)
    const handleCloseCompabilityDialog = () => setOpenCompabilityDialog(false)
    const handleOpenAssigneeDialog = () => setOpenAssigneeDialog(true)
    const handleCloseAssigneeDialog = () => {
        setOpenAssigneeDialog(false)
        reset('ASSIGN_USER')
    }

    useEffect(() => {
        (async () => {
            const [fetchedEsims, fetchedPlans] = await Promise.all([fetchEsims(myEsims), fetchPlans()])
            const esim = fetchedEsims?.find(eSIM => eSIM.service_id === esimId)
            const plan = fetchedPlans?.find(plan => plan.billing_code === esim?.billing_code)

            if (esim) {
                setData({ ...esim, plan })
                if (esim.user) {
                    const user = esim.user
                    setSelectedUser(user)
                    setSelectedPhone(user.did)
                    setIsSimAssigned(true)

                    const { esim: phoneNumbers } = await api.getUserPhoneNumbersForEsim(user.voip_phone_id)
                    setPhones(phoneNumbers)
                }
            }

            setIsLoading(false)
        })()
    }, [])

    return (
        <EsimOperationsContext.Provider value={{
            isLoading,
            data,
            setData,
            selectedUser,
            setSelectedUser,
            selectedPhone,
            setSelectedPhone,
            isSimAssigned,
            setIsSimAssigned,
            openCompabilityDialog,
            openAssigneeDialog,
            handleOpenCompabilityDialog,
            handleCloseCompabilityDialog,
            handleOpenAssigneeDialog,
            handleCloseAssigneeDialog,
            phones,
            setPhones,
            teammates,
            myEsims
        }}>
            {children}
        </EsimOperationsContext.Provider>
    )
}

export default EsimOperationsProvider
