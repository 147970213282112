import React, { createContext, useContext } from 'react'
import { useRouteMatch, Redirect } from 'react-router-dom'
import { useAppsContext } from './AppsContext'
import { App } from '../utils/objects'
import { getBasePath, isObjectEmpty } from '../utils/utils'
import Loader from '../components/Loader'

/**
 *
 */
export interface AppContextInterface {
    app: App,
    phoneCom: Record<string, any>
}

/**
 *
 */
export const AppContext = createContext<AppContextInterface>({
    app: {},
    phoneCom: {}
})

/**
 *
 */
export const useAppContext = () => useContext(AppContext)

/**
 *
 */
const AppContextProvider = ({ children, appURI }: { children: JSX.Element, appURI: string }): JSX.Element => {
    const routeMatch = useRouteMatch()
    const basePath = getBasePath(routeMatch)

    const { apps, phoneCom, isLoadingApps } = useAppsContext()
    const appTitle = decodeURI(appURI)
    const app = apps.find(app => app.title.toLowerCase() === appTitle) || {}

    if (isLoadingApps) return <Loader />

    return (
        !isObjectEmpty(app)
            ? <AppContext.Provider value={{
                app: app as App,
                phoneCom
            }} >
                {children}
            </AppContext.Provider>
            : <Redirect to={basePath} />
    )
}

export default AppContextProvider
