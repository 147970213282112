import React from 'react'
import Dialog, { DialogType } from './Dialog'
import FormButtons, { FormButtonsType } from './FormButtons'
import { Typography } from 'foundations-library/components'

interface PropsInterface extends DialogType, FormButtonsType {
    destructive?: boolean;
}

/**
 *
 */
const ConfirmationDialog = ({
    title = 'Are you sure?',
    size = 'small',
    children,
    destructive = false,
    'data-testid': dataTestId = 'confirmation-dialog',
    ...props
}: PropsInterface): JSX.Element => {
    return <Dialog {...props} title={title} size={size} data-testid={dataTestId}>
        <Typography variant='body1'>{children}</Typography>
        <FormButtons {...props} confirmProps={ { color: (destructive && 'destructive') || undefined } }/>
    </Dialog>
}

export default ConfirmationDialog
