import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from 'foundations-library/components'
import Button from './Button'
import { useHistory } from 'react-router-dom'
import { EsimContext } from 'providers'
import { useEsimStepperContext } from './../../../../../contexts/EsimStepperContext'
import { processLink } from './../../../../../utils/utils'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        paddingTop: '30px',
        justifyContent: 'center',
        alignItems: 'flex-end',
        gap: '15px'
    }
}))

/**
 *
 */
const Footer = (): JSX.Element => {
    const classes = useStyles()
    const history = useHistory()
    const { items, step, count, selectedPlan, card, goBackToPrevStep } = useEsimStepperContext()
    const { orderEsims, processOrderEsims } = useContext(EsimContext)

    const isFirstStep = step === 0
    const isLastStep = step === (items.length - 1)

    const processGoBack = () => {
        if (isFirstStep) return history.goBack()
        goBackToPrevStep()
    }

    const processGoForward = async () => {
        const order = { esimPlanId: selectedPlan.id, count, paymentCard: card! }
        const orderedEsims = await processOrderEsims({ ...order, preview: false })
        if (orderedEsims) processLink('/order-placed', history, true)
    }

    return (
        <>
            {orderEsims.error && <Alert level='error' icon={true}>{orderEsims.error}</Alert>}
            <div className={classes.root}>
                <Button size='large' color='neutral' variant='contained' icon='back' iconLeft={true} onClick={processGoBack} disabled={orderEsims.isLoading}>Back</Button>
                <Button size='large' color='primary' variant='contained' icon={!orderEsims.isLoading && !isLastStep ? 'go' : undefined} iconLeft={false} onClick={processGoForward} disabled={orderEsims.isLoading} isLoading={orderEsims.isLoading} minWidth={!isLastStep ? '112px' : '116px'}>{!isLastStep ? 'Continue' : 'Place order'}</Button>
            </div>
        </>
    )
}

export default Footer
