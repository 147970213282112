import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Select, Alert } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import TerminateSimBtn from './TerminateSimBtn'
import { formatPhoneNumber } from 'phone-numbers'
import Button from './../../../components/Button'
import { getVerbalColors } from 'helpers'
import { details } from '../../../utils/objects'
import DropdownWithInfoTip from './DropdownWithInfoTip'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            columnGap: '20px',
            padding: '30px 20px',
            borderBottom: `1px solid ${verbalColors['neutral-200']}`,
            '& .input-wrapper': {
                '& .text-field': {
                    width: '300px'
                },
                [theme.breakpoints.down('xs')]: {
                    minWidth: '100% !important',
                    '& .select, & .text-field': {
                        width: '100% !important'
                    }
                }
            }
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            minWidth: 0,
            '& .btn-container': {
                display: 'flex',
                columnGap: '1rem'
            }
        }
    }
})

/**
 *
 */
const AssignedForm = () => {
    const classes = useStyles()
    const { plans, fetchEsims, updatedEsim, updateEsimHandler } = useContext(EsimContext)
    const { data, selectedUser, selectedPhone, setSelectedPhone, phones } = useEsimOperationsContext()
    const [prevSelectedPhone, setPrevSelectedPhone] = useState(selectedPhone)
    const [phoneChanged, setPhoneChanged] = useState(false)
    if (!data) return null

    const handleSelectPhone = (phone: string) => setSelectedPhone(phone)

    const handleEsimUpdate = async () => {
        const updatedEsim = await updateEsimHandler(data.service_id, selectedPhone)
        if (updatedEsim) {
            setPrevSelectedPhone(selectedPhone)
            setPhoneChanged(true)
            fetchEsims(false, true)
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant='subtitle1' color='neutral-500'>Assigned to:</Typography>
            <div className={classes.formContainer}>
                <Typography variant='subtitle1' color='neutral-900'>{selectedUser?.extension ? `Ext ${selectedUser.extension.number}: ${selectedUser.extension.name}` : `${selectedUser?.first_name} ${selectedUser?.last_name}`}</Typography>
                <DropdownWithInfoTip value={selectedPhone} onChange={handleSelectPhone} options={phones.filter((extension: Record<string, any>) => extension.assigned !== null).map((extension: Record<string, any>) => ({ value: extension.number, text: formatPhoneNumber(extension.number) }))} title={details.selectPhone} />
                <Select disabled={true} value={data.billing_code} options={plans.plans.map((plan: Record<string, any>) => ({ value: plan.billing_code, text: plan.name }))} title='Select a plan' />
                <div className='btn-container'>
                    <Button variant='contained' color='primary' size='large' onClick={handleEsimUpdate} className='update-btn' disabled={updatedEsim.isLoading || prevSelectedPhone === selectedPhone} minWidth={125} isLoading={updatedEsim.isLoading}>{details.updateBtn}</Button>
                    <TerminateSimBtn size='large' />
                </div>
                {updatedEsim.error && <Alert level='error' icon={true}>{updatedEsim.error}</Alert>}
                {phoneChanged && !updatedEsim.error && Boolean(Object.keys(updatedEsim.esim).length) && <Alert level='success' icon={true}>{details.updateSuccessMessage}</Alert>}
            </div>
        </div>
    )
}

export default AssignedForm
