/**
 *
 */
export const sendEmailIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADySURBVHgB7ZJvDcIwEMWvZAKQMBQACsABOGAOwAGggKAACcwBoGBzQB0wB+Vd9hqWZV33gY97yS9tr9fX6x+RUX+Tc+7khusNpl0+k0Z/B87g2bNvxRwLthKp0OsIMlbh9eEJtuyrsqGG/kgpDfbsX1o5nYZJwD8FBchBCR6MRZX0zOmlZ43xkrE5mEl9j2G5uB7MW4NFyGfSGlsJy/KrqFnBTbS9g51PSlpmT3AFKx6rYjw3xlSssGTcmyu5NzFdpeirSv0IliFdpHd3oFFbM2xopU8dfzGkW3OdiZimaNZg06jMgpf8vtMyWt1Q6YvLqC+/PCKFKXRpMQAAAABJRU5ErkJggg=='

/**
 *
 */
export const userIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEWSURBVHgBnVM7bsJAEJ1dy5Itf7SlXYV0OUK65Agp0yVtqsAJuILpaCm5AR3QUXIDTOfSSJYsGps3AoMF7IL8pNXu7My+t943FmRAHMf/mPoYPYx1XddJlmWTdo2lOxxF0a8QYoylarYQfwVBsCuKYtXUCYP65qR8jbwsy9cc4EBq1Huawwxl2/Y5J6kDpJQvRgI8VIrvzXUEyO2MBIyqqkaaVAqBxUMCx3ESKK3vpAbtQGuj67pj+D4FyRKzw2SY/6A+o0dgF2BjzQPrn+vczQ3UEd++73OjDKGWNAXt5uHORDwLw5A8z9tiLxfMiM056X03YWCBndU+qRve2YUP6g4lcX1F3ZFaeLg9Fm90+eueBmwdHQBmHVOrHySXDQAAAABJRU5ErkJggg=='

/**
 *
 */
export const userAdminIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIVSURBVHgB1ZQ9SOtQFMdP8kRfKamBt/RjyZve8HjwpA52q7trXRzsoOgmis6tswjZHMSqIA4ual2c1MkKFixaoZ1aUZqCaKVfTm08JyY1anMb6uQfbnJzzzm/e++55wbgu+mHHSfxVUMOh0Os1+tFOzFcJwePxzPDcVxUVVVRH9rD/myxWMx3DXa73WGErrcxpRRFGWDFMlMhCMIuvsR2czqdzlStVstaxfJWBkmSCChZBvL8MDDEQ5fCPPdDN+B8Pv+E+U2BtW6gG7C+qk2GeYNhYx9etVo9wwP8jd3/HyZcxHLbY8Uyyw1reJfS0Ww2advjWgDH7WOpyaZJRH38CewIa1hCcA6b6vV6I2YbXUMEzmDLqW+6wDb+CUTOCAvSTaOVIqxEUKMZcJowHo+XDNp+dkc9L5ya+GqklQpyxq0cA6Nuje2GQiFRlluZgMmDUY2yOrJjdg1rh4cHRJ5B6KyfsVgManwFso/XkFTOYDu9BoXKHfz59Rcenu9B6O2Hvp4+UVsxbdv0k7GUy+WCTCYDS4kobF+ttfWZ8s/CtH8OeEqDHahZC4GoBvio+UBEg5J4/fe3aQdYLpchnU5r/bF/E+9sQq/LPHbSQ0+sy7DP55MbjYaddATxFUkWEtq33xMApXILheod5jwBg/gNHW6lpTB1MpXZ1uVqq8ZWksvqUe6QulH4ihAQxnasc0t6P2jYXwBJnAM91UeZmQAAAABJRU5ErkJggg=='
